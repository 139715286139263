import React from 'react';
import { AiOutlineArrowLeft, AiOutlineClose } from 'react-icons/ai';
import { BorderBottom } from '../../assets/images';
import '../../assets/styles/authStyles.css';

const AuthCard = (props) => {
    const { onDecline, backBtn, onClick } = props
    return (
        <section className={'authCardSection'}>
            {backBtn &&
                <div className={'modalBackButton'} onClick={onClick}>
                    <AiOutlineArrowLeft fontSize="30px" color="#000000" />

                </div>}
            <div className={'vendorCloseBtn'} onClick={onDecline}>
                <AiOutlineClose size={20} />
            </div>
            {props.children}
            <div className={'modalBottomImage'} >
                <img src={BorderBottom} alt='poster' />
            </div>
        </section>
    )
}

export default AuthCard