import { setToken } from "../../utils/Helpers";
import http from "../../utils/Utils";
import { GETTING_ADDRESSES, GETTING_ADDRESSES_FAIL, GETTING_ADDRESSES_SUCCESSFUL, GETTING_OTP, GETTING_OTP_FAIL, GETTING_OTP_SUCCESSFUL, LOADING_USER, LOADING_VENDORS, LOGIN_FAIL, LOGIN_SUCCESS, SIGNOUT_USER, SIGNOUT_USER_FAIL, SIGNOUT_USER_SUCCESSFUL, VENDORS_FAIL, VENDORS_SUCCESSFUL } from "../Types";



export const getOtp = (obj) => {
    return dispatch => {
        dispatch({ type: GETTING_OTP })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("auth/customer/send/otp", obj)
                const data = res
                // console.log('get OTP ', res)
                dispatch({ type: GETTING_OTP_SUCCESSFUL });
                resolve(data);
            } catch (error) {
                // console.log('dd', error)
                dispatch({ type: GETTING_OTP_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


// auth / customer / profile

export const verifyOtp = (obj) => {
    return dispatch => {
        dispatch({ type: LOADING_USER })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("auth/customer/verify/otp", obj)
                const data = res.data
                setToken(data, 'v1')
                console.log('verify OTP', res)
                dispatch({ type: LOGIN_SUCCESS, payload: data });
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: LOGIN_FAIL, payload: error });
                reject(error);
            }
        });
    };

};



export const getCustomer = () => {
    return dispatch => {
        dispatch({ type: LOADING_USER })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get("auth/customer/profile",)
                const data = res.data
                // setToken(data)
                // console.log('profile', res)
                dispatch({ type: LOGIN_SUCCESS, payload: data });
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: LOGIN_FAIL, payload: error });
                reject(error);
            }
        });
    };

};




export const getAddresses = () => {
    return dispatch => {
        dispatch({ type: GETTING_ADDRESSES })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get("/auth/customer/profile/address",)
                const data = res.data
                // setToken(data)
                // console.log('addresses', res)
                dispatch({ type: GETTING_ADDRESSES_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: GETTING_ADDRESSES_FAIL, payload: error });
                reject(error);
            }
        });
    };

};


export const updateNewUser = (obj) => {
    return dispatch => {
        dispatch({ type: LOADING_USER })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("auth/customer/update", obj)
                const data = res
                // console.log('verify OTP', res)
                dispatch({ type: LOGIN_SUCCESS, payload: data });
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: LOGIN_FAIL, payload: error });
                reject(error);
            }
        });
    };

};



export const vendorAuth = (obj) => {
    return dispatch => {
        dispatch({ type: LOADING_VENDORS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("vendor/waitlist/join", obj)
                const data = res
                // console.log('vendor auth', res)
                dispatch({ type: VENDORS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: VENDORS_FAIL, payload: error });
                reject(error);
            }
        });
    };

};


export const ridersAuth = (obj) => {
    return dispatch => {
        dispatch({ type: LOADING_VENDORS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("rider/waitlist/join", obj)
                const data = res
                // console.log('riders auth', res)
                dispatch({ type: VENDORS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: VENDORS_FAIL, payload: error });
                reject(error);
            }
        });
    };

};



export const signOut = () => {
    return dispatch => {
        dispatch({ type: SIGNOUT_USER })
        dispatch({ type: SIGNOUT_USER_SUCCESSFUL });
        sessionStorage.removeItem('accessToken')
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("auth/signout")
                const data = res
                // console.log('riders auth', res)
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: SIGNOUT_USER_FAIL, payload: error });
                reject(error);
            }
        });
    };

};


// /auth/signout
