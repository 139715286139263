import React, { useState } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { LocationBg } from "../../assets/images";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import CustomButton from "../../utils/CustomButton";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackComponent from "../../utils/BackComponent";
import { UPDATE_ADDRESS } from "../../redux/Types";
import mixpanel from "mixpanel-browser";
import http from "../../utils/Utils";



const locations = [
    {
        id: "1",
        name: "Abuja",
    },
    {
        id: "2",
        name: "Enugu",
    },
];
const stateNames = {
    enugu: 'Enugu',
    abuja: 'Abuja',
};

const stateCoordinates = {
    enugu: { lat: 6.459964, lng: 7.548949 },
    abuja: { lat: 9.0765, lng: 7.3986 },
};

const Location = (props) => {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const disable = () => {
        if (userAddress === "") {
            return true;
        }
        return false;
    };
    const [selectedLocation, setSelectedLocation] = useState({});
    const [showInput, setShowInput] = useState(false);

    const selectLocation = (val) => {
        setSelectedLocation(val);
        setShowInput(true);
        // mixpanel.track("location-selected", {
        //     location: val?.name,
        // });
        console.log(selectedLocation, 'hhh')
    };



    // Radius in meters (you can adjust the radius as needed)
    const radius = 50000;

    const renderLocations = () => {
        if (locations) {
            return locations?.map((item) => {
                return (
                    <div className={selectedLocation && selectedLocation?.id === item?.id ? "selectLocationTab selectLocationTabActive" : "selectLocationTab selectLocationTabInactive"} key={item?.id} onClick={() => selectLocation(item)} style={{ cursor: "pointer" }}>
                        <div className={"radioBox"} style={{ marginRight: "5px", borderColor: selectedLocation && selectedLocation?.id === item?.id ? "#FFA024" : "#B1B2B4" }}>
                            <span className={"radioBoxInner"} style={{ backgroundColor: selectedLocation && selectedLocation?.id === item?.id ? "#FFA024" : "#ffffff" }}></span>
                        </div>
                        <p className={"regularText"} style={{ color: selectedLocation && selectedLocation?.id === item?.id ? "#38383A" : "#696A6B", fontSize: "16px" }}>
                            {item?.name}
                        </p>
                    </div>
                );
            });
        }
    };

    const [userAddress, setAddress] = useState(null);

    const getAddressDetails = async () => {
        setLoading(true);
        const address = userAddress?.label;
        // console.log(address)
        const obj = { address };
        try {
            const res = await http.post(`get_address_details`, obj);
            if (res) {
                const address = res?.data?.location?.givenAddress;
                const formatted_address = res?.data?.location?.formattedAddress;
                const lat = res?.data?.location?.latitude;
                const lng = res?.data?.location?.longitude;
                const city = res?.data?.location?.city;
                const country = res?.data?.location?.country;
                const state = res?.data?.location?.state;
                const addressData = { address, formatted_address, lat, lng, city, country, state };
                dispatch({ type: UPDATE_ADDRESS, payload: addressData });
                setLoading(false);
                localStorage.setItem("location", address);
                // console.log(addressData, res)
                navigate("/home", {
                    state: {
                        data: { lng, lat },
                    },
                });
            }
        } catch (error) {
            // console.log(error)
            setLoading(false);
        }
    };

    return (
        <main className={"locationContainer"}>
            <div className={"onGoBack"} style={{ paddingTop: '10px' }}>
                <BackComponent />
            </div>
            <section className={"locationContent"}>
                <div className={"locationGifBg"}>
                    <img src={LocationBg} alt={"mockup"} />
                    <div className={"locationGifOverlay"}>
                        <Player autoplay loop src={require("../../assets/images/lotties/Map.json")} className={"locationLottie"}>
                            <Controls visible={false} buttons={["play", "repeat", "frame", "debug"]} />
                        </Player>
                    </div>
                </div>

                <div className={"locationText"}>
                    <h5 className={"textCenter boldText"}>Choose a city</h5>
                    <p className={"textCenter regularText"}>Select a city to experience the best Dado has to offer you.</p>
                </div>
                <div className={"locationTabs"}>{renderLocations()}</div>

                {showInput && (
                    <>
                        <div className={"animate__animated animate__fadeIn"}>
                            <div className={"locationInput"}>
                                <p className={"regularText "} style={{ fontSize: "16px", marginBottom: "8px" }}>
                                    Address
                                </p>

                                <GooglePlacesAutocomplete
                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                    selectProps={{
                                        userAddress,
                                        onChange: setAddress,
                                        // (value) => setAddress(value ? `${value.label}, ${stateNames[selectedLocation.name.toLowerCase()]}` : ''),
                                        styles: {
                                            control: (provided) => ({
                                                ...provided,
                                                border: "1px solid #EBEBEB",
                                                borderRadius: "8px",
                                                outline: "none",
                                                hover: "none",
                                                padding: "8px 12px",
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: " #999CA0",
                                                fontFamily: "Nunito",
                                                fontSize: "14px",
                                            }),
                                        },
                                        placeholder: `Enter an address in ${stateNames[selectedLocation.name.toLowerCase()]}`,
                                    }}
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: ["ng"],
                                        },
                                        location: stateCoordinates[selectedLocation.name.toLowerCase()],
                                        radius: radius,
                                    }}
                                />
                            </div>
                            <div className={"locationBtn"}>
                                <CustomButton title={"Continue"} onClick={getAddressDetails} loading={loading} disabled={disable()} />
                            </div>
                        </div>
                    </>
                )}
            </section>
        </main>
    );
};

export default Location;
