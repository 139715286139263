import React, { useState } from 'react';

const ImageWithPreloader = ({ src, alt, objectFit }) => {
    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor: 'rgba(0,0,0,0.1)', // Background color for the preloader
                    }}
                >
                    <span className='loader'></span>
                </div>
            )}
            <img
                src={src}
                alt={alt}
                onLoad={handleLoad}
                style={{ opacity: loading ? 0 : 1, transition: 'opacity 0.5s ease-in-out', objectFit: objectFit, width: '100%', height: '100%' }}
            />
        </div>
    );
};

export default ImageWithPreloader;
