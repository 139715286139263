import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { FlutterWave, Verify } from '../../assets/images'
import CustomButton from '../../utils/CustomButton'
import { showSuccess } from '../../utils/Toast'
import http from '../../utils/Utils'
import HomeNavbar from '../../widgets/HomeNavBar';
import '../../assets/styles/customersStyles.css';
import { useDispatch } from 'react-redux'
import { getCart } from '../../redux/actions/CustomerActions'
import mixpanel from 'mixpanel-browser'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const FlutterWavePay = () => {

    const [state, setState] = useState({ showVerify: false })
    const dispatch = useDispatch()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const txRef = searchParams.get('tx_ref');
    const transactionId = searchParams.get('transaction_id');

    const verifyPayment = async (val) => {
        // setState({ ...state, loading: true, })
        const obj = { transaction_ref: txRef, transaction_id: transactionId }
        try {
            const res = await http.post(`customer/order/checkout/verify_payment`, obj)
            // console.log('verify order', res)
            if (res) {
                clearCart()
                mixpanel.track('order-completed')
                setState({ ...state, showVerify: true })
            }
            showSuccess(res.message)
        } catch (error) {
            // console.log('verify order', error)
        }
    }


    useEffect(() => {
        verifyPayment()
        return () => {

        }
    },[])


    const clearCart = async () => {
        try {
            const res = await http.delete(`/customer/cart`)
            if (res) {
                dispatch(getCart())
            }
            // console.log(res)
        } catch (error) {
            // console.log(error)
        }
    }


    return (
        <>
            <HomeNavbar bgColor={'#ffffff'} />
            <main className={'verifyPageContainer'} >
                <section className={'onGoBack flexRow alignCenter'}>
                    <Link to={'/home'}>
                        <div className={'backComponentContainer'}>
                            <AiOutlineArrowLeft fontSize="30px" color="#000000" />
                        </div>
                    </Link>
                    <h3 className={'boldText f24 '} style={{ marginLeft: '24px' }}>Check out</h3>
                </section>
                {!state.showVerify &&
                    <section className={'verifyPaymentContainer'}>
                        <div class="loader-container">
                            <div class="image-container">
                                <img src={FlutterWave} alt="loader" style={{ height: '150px', width: '150px' }} />
                            </div>
                            <div class="spinner"></div>
                        </div>
                        <p className={'boldText f24'} style={{ marginTop: '40px' }}>Processing....</p>
                    </section>
                }
                {state.showVerify &&
                    <section className={'verifyPaymentContainer'}>
                        <img src={Verify} alt='poster' style={{ width: '150px', height: '150px' }} />
                        <div>
                            <h4 className={'boldText textCenter f24'}>Payment successful</h4>
                            <p className={'regularText textCenter'}>Track the progress of your order from your order history as we process it</p>
                        </div>
                        <div style={{ marginTop: '40px' }}>
                            <Link to={'/home'}>
                                <CustomButton title={'Return to home'} />
                            </Link>
                        </div>
                    </section>
                }
            </main>

        </>
    )
}

export default FlutterWavePay