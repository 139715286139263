

import React, { useState, useEffect, useRef } from 'react';
import { NoAddress } from '../../assets/images';
import ProductsCard from './ProductsCard';
import { useDispatch, useSelector } from 'react-redux';
import { getRestaurantsProduct } from '../../redux/actions/CustomerActions';

const ProductsLargeCard = ({ openOrderModal, loading, getSingleProduct, searchValue, searchedProducts, business_id }) => {
    const [toggleState, setToggleState] = useState('All');
    const [currentCategoryId, setCurrentCategoryId] = useState(0); // State to store the current category ID
    const dispatch = useDispatch();
    const { products } = useSelector(state => state.customer);
    const [currentPage, setCurrentPage] = useState(1);
    const topRef = useRef(null);

    useEffect(() => {
        dispatch(getRestaurantsProduct(business_id, currentCategoryId, currentPage));
    }, [dispatch, business_id, currentCategoryId, currentPage]);

    const getByCategories = async (category_id) => {
        setCurrentCategoryId(category_id); // Set the current category ID state
        setCurrentPage(1); // Reset to the first page when category changes
        dispatch(getRestaurantsProduct(business_id, category_id, 1));
    };

    const toggleTab = (plan) => {
        setToggleState(plan.name);
        getByCategories(plan.id);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        dispatch(getRestaurantsProduct(business_id, currentCategoryId, page)); // Use currentCategoryId
        topRef.current.scrollIntoView({ behavior: 'smooth' });
        window.scrollBy(0, -3100);
    };

    const renderPlans = () => {
        if (products?.categories?.length !== 0) {
            let categories = products?.categories;
            return categories?.map((plan, index) => (
                <p
                    className={`${toggleState === plan?.name ? 'activeState' : 'inactiveState'} tabNeutral regularText f12 white capitalize`}
                    onClick={() => toggleTab(plan)}
                    key={index}
                    style={{ cursor: 'pointer' }}
                >
                    {plan.name}
                </p>
            ));
        }
    };

    const openModal = (val) => {
        openOrderModal();
        getSingleProduct(val);
    };

    const renderEmptySearch = () => {
        if (searchedProducts?.length === 0 && searchValue) {
            return (
                <div className="emptySearch">
                    <img src={NoAddress} alt="poster" />
                    <h3 className="boldText f22 textCenter" style={{ color: '#B1B2B4', marginTop: '20px' }}>
                        No Restaurants Found
                    </h3>
                </div>
            );
        }
    };

    const renderProducts = () => {
        let allProducts = products?.products;

        if (loading) {
            return (
                <div className="productsTabGrid">
                    {[...Array(4)].map((_, index) => (
                        <div key={index}>
                            <ProductsCard
                                title="Meal title"
                                description="Brief description of the meal, contents and compositions....."
                                price="2300"
                                onClick={openOrderModal}
                                loading={loading}
                            />
                        </div>
                    ))}
                </div>
            );
        }

        if (allProducts && allProducts?.length !== 0) {
            if (searchValue) {
                allProducts = searchedProducts;
            }
            return allProducts?.map((item, index) => (
                <ProductsCard data={item} onClick={() => openModal(item)} key={index} />
            ));
        }

        if (products?.products?.length === 0 || (allProducts && allProducts?.length === 0)) {
            return (
                <div className="emptySearch">
                    <img src={NoAddress} alt="poster" />
                    <h3 className="boldText f22 textCenter" style={{ color: '#B1B2B4', marginTop: '20px' }}>
                        No Products Available
                    </h3>
                </div>
            );
        }
    };

    const renderPagination = () => {
        const totalPages = products?.meta?.totalPages || 1;

        if (totalPages > 1) {
            return (
                <div className="paginationContainer">
                    {/* Previous Page Button */}
                    <button
                        className={`paginationButton ${currentPage === 1 ? 'disabled' : ''}`}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        {'<'}
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            className={`paginationButton ${currentPage === index + 1 ? 'activePage' : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}

                    {/* Next Page Button */}
                    <button
                        className={`paginationButton ${currentPage === totalPages ? 'disabled' : ''}`}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        {'>'}
                    </button>
                </div>
            );
        }
    };

    return (
        <section className="productsLargeCardContainer">
            <div ref={topRef} className="productsTab">{renderPlans()}</div>
            <div>
                <div className="menuGroup">
                    <h5 className="boldText">Menu group</h5>
                    <p className="regularText pb10">The very best at satisfying you!</p>
                </div>
                <div className="productsTabGrid">
                    {renderProducts()}
                    {renderEmptySearch()}
                </div>
                {renderPagination()}
            </div>
        </section>
    );
};

export default ProductsLargeCard;
