import { GETTING_ALL_RESTAURANTS, GETTING_ALL_RESTAURANTS_FAIL, GETTING_ALL_RESTAURANTS_SUCCESSFUL, GETTING_CART, GETTING_CART_FAIL, GETTING_CART_SUCCESSFUL, GETTING_CATEGORIES, GETTING_CATEGORIES_FAIL, GETTING_CATEGORIES_SUCCESSFUL, GETTING_ORDERS, GETTING_ORDERS_FAIL, GETTING_ORDERS_STATUS, GETTING_ORDERS_STATUS_FAIL, GETTING_ORDERS_STATUS_SUCCESSFUL, GETTING_ORDERS_SUCCESSFUL, GETTING_RESTAURANT, GETTING_RESTAURANTS_BY_CATEGORIES, GETTING_RESTAURANTS_BY_CATEGORIES_FAIL, GETTING_RESTAURANTS_BY_CATEGORIES_SUCCESSFUL, GETTING_RESTAURANTS_PRODUCT, GETTING_RESTAURANTS_PRODUCT_FAIL, GETTING_RESTAURANTS_PRODUCT_SUCCESSFUL, GETTING_RESTAURANT_FAIL, GETTING_RESTAURANT_SUCCESSFUL, GETTING_WALLET, GET_TRENDING, GET_TRENDING_FAIL, GET_TRENDING_SUCCESSFUL, PERSIST_CART, SIGNOUT_USER_SUCCESSFUL, UPDATE_COURIER_FEE, UPDATE_TXF_REF, WALLET, WALLET_FAIL, WORKING_TIME } from '../Types';

const INITIAL_STATE = {
    loading: false,
    gettingRestaurants: false,
    gettingCart: false,
    gettingProducts: false,
    gettingOrders: false,
    gettingOrderStatus: false,
    gettingTrending: false,
    gettingRestaurant: false,
    restaurant: {},
    restaurants: [],
    restaurantError: [],
    restaurantsByCategories: [],
    restaurantByCategoriesError: [],
    categories: [],
    gettingCategories: false,
    categoriesError: [],
    cart: [],
    cartError: [],
    products: [],
    productsError: [],
    courierFee: '',
    txfRef: '',
    orders: [],
    ordersError: [],
    ordersStatus: null,
    orderStatusError: [],
    persistCart: null,
    trending: [],
    trendingError: [],
    workingTime: null,
    wallet: null,
    gettingWallet: false,
    walletErrors: null,

};

const CustomerReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        // #region All Restaurant
        case GETTING_ALL_RESTAURANTS:
            return { ...state, gettingRestaurants: true };
        case GETTING_ALL_RESTAURANTS_SUCCESSFUL:
            return { ...state, gettingRestaurants: false, restaurants: payload };
        case GETTING_ALL_RESTAURANTS_FAIL:
            return { ...state, gettingRestaurants: false, restaurantError: payload };

        // #region Single Restaurant
        case GETTING_RESTAURANT:
            return { ...state, gettingRestaurant: true };
        case GETTING_RESTAURANT_SUCCESSFUL:
            return { ...state, gettingRestaurant: false, restaurant: payload };
        case GETTING_RESTAURANT_FAIL:
            return { ...state, gettingRestaurant: false, restaurantError: payload };

        // #region  Restaurant By Category

        case GETTING_RESTAURANTS_BY_CATEGORIES:
            return { ...state, loading: true };
        case GETTING_RESTAURANTS_BY_CATEGORIES_SUCCESSFUL:
            return { ...state, loading: false, restaurantsByCategories: payload };
        case GETTING_RESTAURANTS_BY_CATEGORIES_FAIL:
            return { ...state, loading: false, restaurantByCategoriesError: payload }


        case GETTING_RESTAURANTS_PRODUCT:
            return { ...state, gettingProducts: true };
        case GETTING_RESTAURANTS_PRODUCT_SUCCESSFUL:
            return { ...state, gettingProducts: false, products: payload };
        case GETTING_RESTAURANTS_PRODUCT_FAIL:
            return { ...state, gettingProducts: false, productsError: payload }
        case GETTING_CATEGORIES:
            return { ...state, gettingCategories: true };
        case GETTING_CATEGORIES_SUCCESSFUL:
            return { ...state, gettingCategories: false, categories: payload };
        case GETTING_CATEGORIES_FAIL:
            return { ...state, gettingCategories: false, categoriesError: payload };
        case GETTING_CART:
            return { ...state, gettingCart: true };
        case GETTING_CART_SUCCESSFUL:
            return { ...state, gettingCart: false, cart: payload };
        case GETTING_CART_FAIL:
            return { ...state, gettingCart: false, cartError: payload };
        case GETTING_ORDERS:
            return { ...state, gettingOrders: true }
        case GETTING_ORDERS_SUCCESSFUL:
            return { ...state, gettingOrders: false, orders: payload }
        case GETTING_ORDERS_FAIL:
            return { ...state, gettingOrders: false, ordersError: payload };
        case GETTING_ORDERS_STATUS:
            return { ...state, gettingOrderStatus: true };
        case GETTING_ORDERS_STATUS_SUCCESSFUL:
            return { ...state, gettingOrderStatus: false, ordersStatus: payload }
        case GETTING_ORDERS_STATUS_FAIL:
            return { ...state, gettingOrderStatus: false, orderStatusError: payload }
        case GET_TRENDING:
            return { ...state, gettingTrending: true }
        case GET_TRENDING_SUCCESSFUL:
            return { ...state, gettingTrending: false, trending: payload }
        case GET_TRENDING_FAIL:
            return { ...state, gettingTrending: false, trendingError: payload }
        case UPDATE_COURIER_FEE:
            return { ...state, courierFee: payload };
        case UPDATE_TXF_REF:
            return { ...state, txfRef: payload }
        case PERSIST_CART:
            return { ...state, persistCart: payload }
        case WORKING_TIME:
            return { ...state, workingTime: payload }
        case GETTING_WALLET:
            return { ...state, gettingWallet: true }
        case WALLET:
            return { ...state, wallet: payload, gettingWallet: false }
        case WALLET_FAIL:
            return { ...state, gettingWallet: false, walletErrors: payload }
        case SIGNOUT_USER_SUCCESSFUL:
            return { ...state, cart: [] }
        default:
            return state;
    }
};

export default CustomerReducer;