import React, { useEffect, useState } from 'react';
import { ActiveCard, AddressIcon, BillsIcon, ComingSoon, Coupon, DeleteOrder, Done, EnvelopeIcon, FlutterWave, NoAddress, PayStack, WalletCardIcon } from '../../assets/images';
import '../../assets/styles/customersStyles.css';
import CustomButton from '../../utils/CustomButton';
import HomeNavbar from '../../widgets/HomeNavBar';
import { ToastContainer } from 'react-toastify';
import BackComponent from '../../utils/BackComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import http from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { showError, showSuccess, showWarning } from '../../utils/Toast';
import { getAllRestaurants, getCart, getCategories, getWallet } from '../../redux/actions/CustomerActions';
import { AiOutlinePlusCircle, AiOutlineMinusCircle, AiOutlineDelete } from 'react-icons/ai';
import CustomerAuth from '../auth/customer/CustomerAuth';
import DeliveryInfoAccordion from '../../components/customers/DeliveryInfoAccordion';
import CustomInput from '../../utils/CustomInput';
import { GET_LOCATIONS_SUCCESSFUL, STORE_ADDRESS, UPDATE_ADDRESS, UPDATE_COORDINATES, UPDATE_COURIER_FEE, UPDATE_TXF_REF } from '../../redux/Types';
import AddAddressModal from '../../components/customers/AddAddressModal';
import { getAddresses } from '../../redux/actions/AuthActions';
import { formatAmount, googleApiKey, shortenXterLength } from '../../utils/Helpers';
import MiniLoader from '../../utils/MiniLoader';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { storeAddress } from '../../redux/actions/SearchActions';
import { MdOutlineAddLocation, MdOutlineDone } from 'react-icons/md';
import mixpanel from 'mixpanel-browser';
import CounterLoader from '../../utils/CounterLoader';
import { trackEvent } from '../../utils/Mixpanel';
import { useMediaQuery } from 'react-responsive';
import SmartBanner from '../../utils/SmartBannerComponent';






const PlaceOrder = (props) => {

    const [state, setState] = useState({
        loading: false, cartId: '', signInModal: false, doorDelivery: false, addAddressModal: false,
        toggleState: 0, plans: [{ id: 1, name: 'Deliver to Self', }, { id: 2, name: 'Deliver to Others', }], address: null,
        addressLoader: false, comment: '', phoneVerificationModal: false, deliveryAddressCheck: false,
        counterLoader: false, PaymentId: null, couponCode: '', payWithWallet: false,
        recieving_customer_name: '', recieving_customer_phone: ''
    })
    const { cart, courierFee, } = useSelector(state => state.customer)
    const { addresses, loggedIn } = useSelector(state => state.auth);
    const { addressDetails } = useSelector(state => state.search)
    const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' })

    const [userAddress, setInputtedAddress] = useState(null);
    const params = useLocation()
    const [selectedAddress, setAddress] = useState({});
    const [deliveryFee, setDeliveryFee] = useState('')
    const dispatch = useDispatch()
    const [saveForLater, setSaveForLater] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const navigate = useNavigate()
    // let paymentMethod = null
    let paymentDetails = ''
    const { wallet } = useSelector(state => state.customer)
    const methods = [
        { id: 0, name: 'Paystack', uri: PayStack, },
        { id: 1, name: 'Wallet', uri: WalletCardIcon, amount: wallet?.wallet?.walletBalance }
    ]
    useEffect(() => {
        dispatch(getWallet())
    }, [])



    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        dispatch(getAddresses())
        if (!userAddress && addressDetails?.address) {
            deliveryPrice(addressDetails?.address)
        }
    }, [])


    const hideScroll = () => {
        document.body.style.overflow = "hidden"
    }
    const unhideScroll = () => {
        document.body.style.overflow = "unset"
    }

    const openSignInModal = () => {
        setState((prevState) => ({ ...prevState, signInModal: true }))
        // closePhoneVerificationModal()
        // closeSignUpModal()
        hideScroll()
    }
    const closeSignInModal = () => {
        setState((prevState) => ({ ...prevState, signInModal: false }))
        unhideScroll()
    }

    const openAddressModal = () => {
        setState((prevState) => ({ ...prevState, addAddressModal: true }))
        // closePhoneVerificationModal()
        // closeSignUpModal()
        hideScroll()
    }
    const closeAddressModal = () => {
        setState((prevState) => ({ ...prevState, addAddressModal: false }))
        unhideScroll()
    }


    const openPhoneVerificationModal = () => {
        setState((prevState) => ({ ...prevState, phoneVerificationModal: true, }))
        closeSignInModal()
        hideScroll()
    }
    const closePhoneVerificationModal = () => {
        setState((prevState) => ({ ...prevState, phoneVerificationModal: false }))
        unhideScroll()
    }


    const setPickUpPref = () => {
        showWarning('Not available')
    }
    const onChange = (e) => {
        setState({ ...state, comment: e.target.value })
    }

    const onChangeReceiversName = (e) => {
        setState({ ...state, recieving_customer_name: e.target.value })
    }
    const onChangeReceiversPhone = (e) => {
        setState({ ...state, recieving_customer_phone: e.target.value })
    }

    const onChangeCode = (e) => {
        setState({ ...state, couponCode: e.target.value })
    }


    const toggleSaveForLater = () => {
        setSaveForLater(prevState => !prevState)
    }


    const removeFromCart = async (val) => {
        setState({ ...state, counterLoader: true })
        const product_id = params?.state?.singleProduct?.id;
        const product_variation_id = val?.productVariationId
        const cart_id = val?.cartId
        const obj = { product_id, cart_id, product_variation_id }
        try {
            const res = await http.post(`/customer/cart/remove`, obj)
            // console.log('remove from cart', res)
            showSuccess(res.message)
            setState({ ...state, counterLoader: false })
            if (res) {
                // showPreOrder();
                dispatch(getCart());
            }
        } catch (error) {
            // console.log('decrease error', error)
            setState({ ...state, counterLoader: false })
        }
    }


    const increaseFromCart = async (val) => {
        setState({ ...state, counterLoader: true, cartId: val?.id })
        const product_id = params?.state?.singleProduct?.id;
        const product_variation_id = val?.productVariationId
        const cart_id = val?.cartId
        const quantity = 1
        const obj = { product_id, cart_id, product_variation_id, quantity }
        // console.log(obj)
        try {
            const res = await http.post(`/customer/cart/increase`, obj)
            // console.log('increase from cart', res)
            showSuccess(res.message)
            setState({ ...state, counterLoader: false })
            if (res) {
                // showPreOrder();
                dispatch(getCart());
            }
        } catch (error) {
            // console.log('increase error', error)
            setState({ ...state, counterLoader: false })
        }
    }




    const togglePaymentMethod = (val, index) => {
        setState(prevState => ({
            ...prevState,
            PaymentId: index,
            payWithWallet: index === 1
        }));
        setPaymentMethod(val);
        console.log(val);
    };

    const renderPaymentMethods = () => {
        const { PaymentId } = state
        if (methods && methods?.length !== 0) {
            return methods.map((item, index) => {
                // index !== PaymentId ? 'selectPrefContainer' :
                return (
                    <div className={index !== PaymentId ? 'selectPrefContainer' : 'selectPrefActive'} onClick={() => togglePaymentMethod(item, index)} style={{ cursor: 'pointer' }}>
                        < div className={'flexRow alignCenter'} >
                            <img src={item.uri} alt={"icon"} style={{ marginRight: '16px', width: '20px', height: '20px' }} />
                            <div>
                                <p className={'boldText f14'}>{item.name}</p>
                                {item?.amount !== undefined && item.amount !== null ? (
                                    <p className={'regularText f12'} style={{ color: "#B1B2B4" }}>₦{formatAmount(item.amount)}</p>
                                ) : (
                                    <p className={'regularText f12'} style={{ color: "#B1B2B4" }}>Bank transfer, card, USSD</p>
                                )}
                            </div>
                        </ div>
                        <div className={'radioBox'} style={{ marginRight: '5px', borderColor: '#999CA0' }} >
                            {/* index === PaymentId ? */}
                            <span className={'radioBoxInner'} style={{ backgroundColor: index === PaymentId ? '#FFA024' : '' }}></span>
                        </div>
                    </div >
                )
            })
        }
    }



    const initializePay = async (val) => {
        setState({ ...state, loading: true, })
        const obj = { order_id: val, couponCode: state.couponCode, payWithWallet: state.payWithWallet }
        console.log(obj, 'data')
        // let url;
        // console.log(paymentMethod)
        // switch (paymentMethod?.name) {
        //     case 'Flutterwave':
        //         url = 'customer/order/checkout/initialize_flw_payment';
        //         break;
        //     default:
        //         url = 'customer/order/checkout/initialize_payment';
        //         break;
        // }
        // console.log(url)
        try {
            const res = await http.post(`customer/order/checkout/initialize_payment`, obj)
            // console.log('initialize order', res)
            showSuccess(res.message)
            // transaction_ref = res?.data?.transaction?.trsRef;
            setState({ ...state, authURL: res?.data?.authorisationUrl, })
            // console.log(res, 'initialize')
            if (res?.data?.authorisationUrl) {
                window.location.href = res?.data?.authorisationUrl
                dispatch({ type: UPDATE_TXF_REF, payload: res?.data?.transaction?.trsRef })
                trackEvent('payment_method', {
                    method: 'paystack',
                })
            }
            if (res?.data?.paymentUrl) {
                window.location.href = res?.data?.paymentUrl
                // dispatch({ type: UPDATE_TXF_REF, payload: res?.data?.transaction?.trsRef })
            }
            if (res?.data?.isPaymentComplete) {
                navigate('/verifyPayments')


                trackEvent('payment_method', {
                    method: 'wallet',
                })

            }
        } catch (error) {
            // console.log(error)
        }
    }

    const deleteFromCart = async (val) => {
        setState({ ...state, loading: true })
        const cart_item_id = val?.id;
        const cart_id = val?.cartId
        const obj = { cart_item_id, cart_id }
        try {
            const res = await http.post(`/customer/cart/delete`, obj)
            // console.log('delete from cart', res)
            showSuccess(res.message)
            setState({ ...state, loading: false })
            if (res) {
                // showPreOrder();
                dispatch(getCart());
            }
        } catch (error) {
            // console.log('delete error', error)
            setState({ ...state, loading: false })
        }
    }


    const selectAddress = (val, i) => {
        dispatch({ type: GET_LOCATIONS_SUCCESSFUL, payload: val })
        deliveryPrice(val?.address)
        setAddress(val)

    }

    const deliveryPrice = async (val) => {
        setState({ ...state, loading: true, })
        const cart_id = cart?.data?.cart?.id;
        if (val) {
            const delivery_address = val
            // console.log(val, 'val')
            const obj = { delivery_preference: 'DOOR_DELIVERY', cart_id, delivery_address }
            // console.log(obj)
            try {
                const res = await http.post(`/customer/cart/deliveryFee`, obj)
                // console.log('delivery fee', res)
                setState({ ...state, loading: false })
                setDeliveryFee(res?.data?.delivery?.deliveryFee)
                dispatch({ type: UPDATE_COURIER_FEE, payload: res?.data?.delivery?.deliveryFee })

                if (res) {
                    dispatch(getCart());
                }
            } catch (error) {
                // console.log('delivery fee error', error)
                setState({ ...state, loading: false })
            }
        }
    }



    const addressUpload = () => {
        const obj = {
            name_of_address: 'address',
            country_of_address: addressDetails?.country,
            state_of_address: addressDetails?.states,
            city_of_address: addressDetails?.city,
            address: addressDetails?.address,
            lat: addressDetails?.lat,
            lang: addressDetails?.lang,
        }
        // console.log(obj)
        dispatch(storeAddress(obj))
        dispatch(getAddresses())
    }



    // comment 
    const placeOrder = async () => {
        const cart_id = cart?.data?.cart?.id;
        let delivery_address;
        const comment = state?.comment
        const recieving_customer_name = state.recieving_customer_name
        const recieving_customer_phone = state.recieving_customer_phone
        let obj;


        trackEvent('initialize_place_order', {
            cartId: cart_id
        })
        if (deliveryFee === '') {
            showWarning('Please click on the use address button or select an existing address')


            trackEvent('failed_to_click_use_address')
        }
        else {

            if (selectedAddress) {
                delivery_address = selectedAddress?.address
            }
            if (userAddress) {
                delivery_address = userAddress?.label
            }
            if (addressDetails) {
                delivery_address = addressDetails?.address
            }
            if (recieving_customer_name !== '' || recieving_customer_phone !== '') {
                obj = { delivery_preference: "DOOR_DELIVERY", cart_id, delivery_address, comment, recieving_customer_name, recieving_customer_phone, device_used: 'WEBSITE' }
            }
            if (recieving_customer_name === '' || recieving_customer_phone === '') {
                obj = { delivery_preference: "DOOR_DELIVERY", cart_id, delivery_address, comment, device_used: 'WEBSITE' }
            }
            console.log(obj, 'dd')
            if (loggedIn) {
                setState({ ...state, loading: true, })
                try {
                    const res = await http.post(`customer/order/create`, obj)
                    // console.log('increase from cart', res)
                    showSuccess(res.message)
                    setState({ ...state, loading: false })
                    if (res) {
                        dispatch(getCart());
                        initializePay(res?.data?.order?.id)
                        setState({ ...state, deliveryAddressCheck: false })
                        if (saveForLater) {
                            addressUpload()
                        }
                    }
                } catch (error) {
                    // console.log('place order error', error)
                    trackEvent('initialize_place_order_fail', {
                        error: error,
                    })
                    showError(`Unfortunately, your order won't be completed.Your address is not close to the restaurant so you will have a late delivery`)
                    setState({ ...state, loading: false })
                }
            } else {
                openSignInModal()
            }
        }
    }


    const disable = () => {
        if (!selectedAddress || userAddress === '') {
            return true
        }
        return false
    }


    const sumPrices = cart && cart?.data?.cart?.totalItemsPrice
    const finalPrice = sumPrices + Number(courierFee ? courierFee : 0);

    const renderCartItems = () => {
        if (cart && cart?.data?.cart?.items?.length !== 0) {
            return cart?.data?.cart?.items?.map((item, i) => {
                // console.log(item)
                return (
                    <div className={'cartOrderListContainer'} key={i} style={{ borderBottom: i !== cart?.data?.cart?.items?.length - 1 ? '0.5px solid #e6e7e9' : '' }}>
                        <div className={'flexRow alignCenter justifyBetween'}>
                            <p className={'semiBoldText f14'} style={{ color: ' #565758' }}>Pack </p>
                            <img src={DeleteOrder} alt={'delete Icon'} onClick={() => deleteFromCart(item)} />
                        </div>
                        <div>
                            <div className={'flexRow alignCenter justifyBetween cartCardItem'} key={i} >
                                <div>
                                    <p className={'boldText f14'} style={{ color: '#696A6B' }}>{item?.productVariation?.name}</p>
                                    <p className={'semiBoldText f14'} style={{ color: ' #919294' }}>₦{formatAmount(item?.price)}</p>
                                </div>
                                <div>
                                    <div className={'counterContainer'} style={{ backgroundColor: '#F9F9FB' }}>
                                        {item?.quantity <= 1 ? <span ><AiOutlineMinusCircle color={'#919294'} /></span>
                                            : <span onClick={() => removeFromCart(item)}  ><AiOutlineMinusCircle color={'#696A6B'} /></span>}
                                        {state.counterLoader ? <CounterLoader /> :
                                            <span className={'boldText'} style={{ color: '#696A6B' }}>{item?.quantity}</span>}
                                        <span onClick={() => increaseFromCart(item)}><AiOutlinePlusCircle color={'#696A6B'} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            })
        }
    }





    const deleteAddress = async (val) => {
        const addressId = val
        const obj = { addressId }
        setState({ ...state, addressLoader: true })
        try {
            const res = await http.delete('/auth/customer/profile/address/delete', obj);
            // console.log('delete address', res)
            dispatch(getAddresses())
            setState({ ...state, addressLoader: false })
            showSuccess(res?.message)

        } catch (error) {
            // console.log('delete address error', error)
            setState({ ...state, addressLoader: false })
        }
    }


    const renderAddresses = () => {
        if (state.addressLoader) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <MiniLoader />
                </div>
            )
        }
        if (addresses && addresses?.addresses?.length !== 0) {
            return addresses?.addresses?.map((item, index) => {
                return (
                    <>
                        <li className={'flexRow alignCenter justifyBetween addressListItem'} key={index} style={{ cursor: 'pointer' }}>
                            <div className={'flexRow alignCenter'} onClick={() => selectAddress(item, index)}>
                                <div className={'radioBox'} style={{ marginRight: '5px', borderColor: selectedAddress?.id === item?.id ? '#FFA024' : '#999CA0' }} >
                                    <span className={'radioBoxInner'} style={{ backgroundColor: selectedAddress?.id === item?.id ? '#FFA024' : '' }}></span>
                                </div>
                                <p className={'regularText f14'}>{shortenXterLength(item?.address, 35)}</p>
                            </div>
                            <div>
                                <AiOutlineDelete color='#F84E4E' onClick={() => deleteAddress(item?.id)} />
                            </div>
                        </li>
                    </>
                )
            })
        }
        if (addresses && addresses?.addresses?.length === 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={NoAddress} alt={'POSTER'} style={{ width: '100px', height: '100px' }} />
                    <div>
                        <h4 className={'boldText f14 textCenter'} style={{ color: '#696A6B' }}>No saved address</h4>
                        <p className={'regularText f12 textCenter'} style={{ color: '#696A6B' }}>When you save an address it will be displayed here</p>
                    </div>
                </div>
            )
        }

    }



    const toggleDeliveryMethod = (index) => {
        setState({ ...state, toggleState: index })
    }

    const renderDeliveryMethod = () => {
        const { toggleState, plans } = state
        if (plans && plans.length !== 0) {
            return plans.map((plan, index) => {
                return (
                    <>
                        <p className={toggleState === index ? 'activeState f12 boldText tabNeutral' : 'inactiveState tabNeutral regularText f12 '}
                            onClick={() => toggleDeliveryMethod(index)} key={index}
                            style={{ cursor: 'pointer', color: '#000000' }}
                        >{plan.name}</p>
                    </>
                )
            })
        }
    }






    const getAddressDetails = async () => {
        const address = userAddress?.label
        const obj = { address }
        try {
            const res = await http.post(`get_address_details`, obj)
            const address = res?.data?.location?.givenAddress
            const formatted_address = res?.data?.location?.formattedAddress
            const lat = res?.data?.location?.latitude
            const lng = res?.data?.location?.longitude
            const city = res?.data?.location?.city
            const country = res?.data?.location?.country
            const state = res?.data?.location?.state
            const addressData = { address, formatted_address, lat, lng, city, country, state, }
            dispatch({ type: UPDATE_ADDRESS, payload: addressData })
            localStorage.setItem('location', address)
            // console.log(addressData, res)
            deliveryPrice(address)
            trackEvent('clicks_on_use_address')

        } catch (error) {
            // console.log(error)
        }
    }



    return (
        <>
            <SmartBanner />
            <HomeNavbar bgColor={'#ffffff'} openAuthModal={openSignInModal} />
            <main className={'landingPageContainer customerLandingPage'}>
                <section className={'onGoBack flexRow alignCenter'}>
                    <BackComponent />
                    <h3 className={'boldText f24 '} style={{ marginLeft: '24px' }}>Place order</h3>
                </section>
                <section className={'flexRow placeOrderContent'}>
                    <div className={'deliveryInfoContainer'}>
                        <DeliveryInfoAccordion title={'Delivery information'} icon={AddressIcon} done={deliveryFee !== '' ? Done : ''}>
                            <span className={'borderBottom'} style={{ marginTop: '16px' }}></span>
                            <div style={{ marginTop: '24px' }} >
                                <p className={'semiBoldText f14'} style={{ marginBottom: '16px' }}>Choose your preferred method </p>
                                <div>
                                    <div className={'infoTabsContainer'}>
                                        {renderDeliveryMethod()}
                                    </div>

                                    {state.toggleState === 0 && <>
                                        <div className={'addressesContainer'}>
                                            <h4 className={'f14 semiBoldText mb10'} style={{ color: '#38383A' }}>Where do you want it to be delivered?</h4>
                                            {/* <div className={'addressContainerInput'}> */}
                                            <div>
                                                <GooglePlacesAutocomplete
                                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                    selectProps={{
                                                        userAddress,
                                                        onChange: setInputtedAddress,
                                                        styles: {
                                                            control: (provided) => ({
                                                                ...provided,
                                                                border: '1px solid #EBEBEB',
                                                                borderRadius: '8px',
                                                                outline: 'none',
                                                                hover: 'none',
                                                                padding: '8px 12px',
                                                            }),
                                                            placeholder: (provided) => ({
                                                                ...provided,
                                                                color: ' #000',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '14px'
                                                            })
                                                        },
                                                        placeholder: addressDetails?.address,
                                                    }}

                                                    autocompletionRequest={{
                                                        componentRestrictions: {
                                                            country: ['ng'],
                                                        }
                                                    }}
                                                />
                                            </div>


                                            {/* </div> */}
                                            <div className={'flexRow alignCenter justifyBetween pt15'}>
                                                <div className={'flexRow alignCenter '} onClick={toggleSaveForLater}>
                                                    <div className={'saveContainer'}
                                                        style={{ borderColor: saveForLater ? '#FFA024' : '#919294', backgroundColor: saveForLater ? '#FFA024' : '', cursor: 'pointer' }}>
                                                        {saveForLater &&
                                                            <span>
                                                                <MdOutlineDone color='#ffff' />
                                                            </span>
                                                        }
                                                    </div>

                                                    <div>
                                                        <p className={'f12 semiBoldText'} style={{ color: '#919294', cursor: 'pointer' }}>Save for next time</p>
                                                    </div>
                                                </div>

                                                <div className={'flexRow alignCenter'} onClick={getAddressDetails} style={{ border: '1px solid #FFA024', padding: '5px', borderRadius: '5px' }}>
                                                    <MdOutlineAddLocation color='#FFA024' />
                                                    <p className={'f12 semiBoldText'} style={{ color: '#FFA024', cursor: 'pointer', }} >Use address</p>
                                                </div>
                                            </div>

                                            <span className={'borderBottom'} style={{ marginTop: '16px' }}></span>
                                            <div className={'pt15'}>
                                                <p className={'boldText f14'} style={{ color: '#919294' }}>Saved Addresses</p>
                                            </div>
                                            <div style={{ padding: '20px 0' }}>
                                                {renderAddresses()}
                                            </div>
                                        </div>
                                    </>}
                                    {state.toggleState === 1 && <>
                                        <div className={isDesktop ? 'flexRow alignCenter pt15 justifyBetween' : 'alignCenter pt15 justifyBetween'}>
                                            <div>
                                                <CustomInput label={'Receivers Name'} placeholder={'Enter receivers name'} onChange={onChangeReceiversName} value={state.recieving_customer_name} />
                                            </div>
                                            <div>
                                                <CustomInput label={'Receivers Phone Number'} placeholder={'Enter receivers phone number'} onChange={onChangeReceiversPhone} value={state.recieving_customer_phone} />
                                            </div>
                                        </div>
                                        <div className={'addressesContainer'}>
                                            <h4 className={'f14 semiBoldText mb10'} style={{ color: '#38383A' }}>Receivers Address</h4>
                                            {/* <div className={'addressContainerInput'}> */}
                                            <div>
                                                <GooglePlacesAutocomplete
                                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                    selectProps={{
                                                        userAddress,
                                                        onChange: setInputtedAddress,
                                                        styles: {
                                                            control: (provided) => ({
                                                                ...provided,
                                                                border: '1px solid #EBEBEB',
                                                                borderRadius: '8px',
                                                                outline: 'none',
                                                                hover: 'none',
                                                                padding: '8px 12px',
                                                            }),
                                                            placeholder: (provided) => ({
                                                                ...provided,
                                                                color: ' #000',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '14px'
                                                            })
                                                        },
                                                        placeholder: addressDetails?.address,
                                                    }}

                                                    autocompletionRequest={{
                                                        componentRestrictions: {
                                                            country: ['ng'],
                                                        }
                                                    }}
                                                />
                                            </div>


                                            {/* </div> */}
                                            <div className={'flexRow alignCenter justifyBetween pt15'}>
                                                <div className={'flexRow alignCenter '} onClick={toggleSaveForLater}>
                                                    <div className={'saveContainer'}
                                                        style={{ borderColor: saveForLater ? '#FFA024' : '#919294', backgroundColor: saveForLater ? '#FFA024' : '', cursor: 'pointer' }}>
                                                        {saveForLater &&
                                                            <span>
                                                                <MdOutlineDone color='#ffff' />
                                                            </span>
                                                        }
                                                    </div>

                                                    <div>
                                                        <p className={'f12 semiBoldText'} style={{ color: '#919294', cursor: 'pointer' }}>Save for next time</p>
                                                    </div>
                                                </div>

                                                <div className={'flexRow alignCenter'} onClick={getAddressDetails} style={{ border: '1px solid #FFA024', padding: '5px', borderRadius: '5px' }}>
                                                    <MdOutlineAddLocation color='#FFA024' />
                                                    <p className={'f12 semiBoldText'} style={{ color: '#FFA024', cursor: 'pointer', }} >Use address</p>
                                                </div>
                                            </div>


                                            <span className={'borderBottom'} style={{ marginTop: '16px' }}></span>
                                            <div className={'pt15'}>
                                                <p className={'boldText f14'} style={{ color: '#919294' }}>Saved Addresses</p>
                                            </div>
                                            <div style={{ padding: '20px 0' }}>
                                                {renderAddresses()}
                                            </div>
                                        </div>

                                    </>}
                                </div>
                            </div>
                        </DeliveryInfoAccordion>
                        <DeliveryInfoAccordion title={'Payment method'} icon={ActiveCard} done={state.PaymentId !== null ? Done : ''}>
                            <span className={'borderBottom'} style={{ marginTop: '16px' }}></span>
                            <div style={{ marginTop: '24px' }} >
                                <p className={'semiBoldText f14'} style={{ marginBottom: '16px' }}>Choose your preferred mode of payment  </p>
                                <div className={'flexRow alignCenter justifyBetween prefContainer'} style={{ gap: '16px' }}>
                                    {renderPaymentMethods()}
                                </div>
                            </div>
                        </DeliveryInfoAccordion>


                        <DeliveryInfoAccordion title={'Message'} icon={EnvelopeIcon}>
                            <span className={'borderBottom'} style={{ marginTop: '16px' }}></span>
                            <div style={{ marginTop: '24px' }} >
                                <p className={'semiBoldText f14'} style={{ marginBottom: '16px' }}>Type a message for the courier</p>

                                <CustomInput placeholder={'Hello awesome person, could you not use salt while preparing my meal.'} onChange={onChange} value={state.comment} />
                            </div>
                        </DeliveryInfoAccordion>


                        <DeliveryInfoAccordion title={'Coupon'} icon={Coupon}>
                            <span className={'borderBottom'} style={{ marginTop: '16px' }}></span>
                            <div style={{ marginTop: '24px' }} >
                                <p className={'semiBoldText f14'} style={{ marginBottom: '16px' }}>Enter your coupon code</p>
                                <CustomInput placeholder={'Enter coupon code'} onChange={onChangeCode} value={state.couponCode} />
                            </div>
                        </DeliveryInfoAccordion>
                    </div>
                    <div className={'cartCardContainer '}>
                        <div className={'dashContainer'}>
                            <div className={'flexRow'} style={{ marginBottom: '24px', borderBottom: '0.5px solid #e6e7e9', paddingBottom: '16px' }}>
                                <img src={BillsIcon} alt='icon' />
                                <h4 className={'boldText f18'} style={{ color: '#38383A', marginLeft: '10px' }}>Order summary</h4>
                            </div>

                            {renderCartItems()}
                        </div>

                        <div className={'cartCardOrderInfo dashContainer'}>
                            <div className={'billingContainer'}>
                                <h5 className={'boldText'}>Billing</h5>
                                {/* < className={'borderBottom'} style={{ marginTop: '16px' }} /> */}
                                <div>
                                    <div className={'pricesContainer'}>
                                        <div className={'flexRow alignCenter justifyBetween'}>
                                            <p className={'boldText'}>Subtotal</p>
                                            <span className={'boldText'}>₦{sumPrices ? formatAmount(sumPrices) : 0}</span>
                                        </div>
                                        <div className={'flexRow alignCenter justifyBetween'}>
                                            <p className={'boldText'}>Delivery</p>
                                            <span className={'boldText'}>₦{deliveryFee ? formatAmount(deliveryFee) : 0}</span>
                                        </div>
                                        <div className={'flexRow alignCenter justifyBetween'} style={{ borderBottom: '0.5px solid #e6e7e9', paddingBottom: '16px' }}>
                                            <p className={'boldText'}>Discount</p>
                                            <span className={'boldText'}>₦0</span>
                                        </div>

                                        <div className={'flexRow alignCenter justifyBetween'}>
                                            <p className={'boldText'}>Total</p>
                                            <span className={'boldText'}>₦{finalPrice && deliveryFee ? formatAmount(finalPrice) : 0}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div>
                            <div className={'placeOrderBtn'} >
                                <CustomButton title={'Proceed to checkout'} onClick={placeOrder} loading={state.loading} disabled={disable()} />
                            </div>
                        </div>
                    </div >
                </section>
                <CustomerAuth signIn={state.signInModal} closeSignIn={closeSignInModal} phoneVerification={state.phoneVerificationModal}
                    closePhoneVerification={closePhoneVerificationModal} openPhoneVerificationModal={openPhoneVerificationModal} />
                <AddAddressModal visible={state.addAddressModal} onDecline={closeAddressModal} />
            </main>

        </>
    )
}

export default PlaceOrder