import React, { useState } from 'react';
import '../../assets/styles/customersStyles.css';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'

const DeliveryInfoAccordion = (props) => {

    const [open, setOpen] = useState(false);
    const toggler = () => {
        setOpen(previousState => !previousState);
    }

    return (
        <div className={'dashContainer'} style={{ marginBottom: "32px" }}>
            <div className={'flexRow alignCenter justifyBetween'} onClick={toggler} style={{ cursor: "pointer" }}>
                <div className={'flexRow alignCenter'}>
                    <img src={props.icon} alt={'icon'} />
                    <p className={'boldText f18'} style={{ color: "#38383A", margin: '0 10px 0 10px' }}>{props.title}</p>
                    {props.done && <img src={props.done} alt={'icon'} />}
                </div>
                <div>
                    {open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                </div>
            </div>
            {open &&
                <div className={'deliveryAccordionContent'}>
                    {props.children}
                </div>
            }
        </div>
    )
}

export default DeliveryInfoAccordion