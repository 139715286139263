import { combineReducers } from 'redux';
import { SIGNOUT_USER_SUCCESSFUL } from '../Types';
import AuthReducer from './AuthReducer';
import CustomerReducer from './CustomerReducer';
import SearchReducer from './SearchReducer';
import RiderAgentReducer from './RiderAgentReducer';


const appReducer = combineReducers({
    auth: AuthReducer,
    search: SearchReducer,
    customer: CustomerReducer,
    riderAgent:RiderAgentReducer,
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === SIGNOUT_USER_SUCCESSFUL) {
        state.auth = null;
    }

    return appReducer(state, action);
};

export default rootReducer;