import mixpanel from "mixpanel-browser";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PhoneVerification from "../../../components/auth/customer/PhoneVerification";
import SignIn from "../../../components/auth/customer/SignIn";
import SignUp from "../../../components/auth/customer/SignUp";
import { getAddresses, getCustomer, getOtp, updateNewUser, verifyOtp } from "../../../redux/actions/AuthActions";
import { getCart } from "../../../redux/actions/CustomerActions";
import { showError, showSuccess } from "../../../utils/Toast";
import { trackEvent } from "../../../utils/Mixpanel";

const CustomerAuth = ({ getOtp, verifyOtp, updateNewUser, signIn, signUp, phoneVerification, closeSignIn, closeSignUp, closePhoneVerification, openPhoneVerificationModal, openSignUpModal, addToCart }) => {
    const [state, setState] = useState({
        loading: false,
        token: "",
        phone: "",
        country_code: "",
    });
    const { persistCart } = useSelector((state) => state.customer);
    const dispatch = useDispatch();

    const openPhoneVerification = () => {
        openPhoneVerificationModal();
    };

    const closePhoneVerificationModal = () => {
        closePhoneVerification();
    };

    const requestOTP = async (country_code, phone) => {
        const obj = { country_code, phone };
        setState({ ...state, loading: true });
        try {
            const res = await getOtp(obj);
            showSuccess(res.message);
            setState({ ...state, loading: false, token: res?.data?.token, phone, country_code });
            openPhoneVerification();
        } catch (error) {
            setState({ ...state, loading: false });
            showError(error[1][0].msg);
        }
    };

    const verifyCode = async (pin, token) => {
        const obj = { pin, token };
        setState({ ...state, loading: true });
        try {
            const res = await verifyOtp(obj);
            showSuccess("Logged In");
            closePhoneVerificationModal();
            closeSignIn()
            dispatch(getCustomer());
            dispatch(getCart());
            dispatch(getAddresses());
            setState({ ...state, loading: false });
            if (res && persistCart) {
                addToCart(persistCart);
                trackEvent("login", {
                    phoneNumber: state.phone
                });
            }
            if (res?.authStatus === "newcustomer") {
                openSignUpModal();
            }
        } catch (error) {
            setState({ ...state, loading: false });
            console.log(error, 'errorvjkvnkjvd')
            showError("Please enter a valid code");
            trackEvent("login-fail", {
                phoneNumber: state.phone
            });
            // Too many signin attempts try again after 1hr  if 403 
        }
    };

    const newUserAuth = async (firstName, lastName, email) => {
        const obj = { firstName, lastName, email };
        setState({ ...state, loading: true });
        try {
            const res = await updateNewUser(obj);
            showSuccess(res.message);
            closeSignUp();
            dispatch(getCustomer());
            trackEvent("sign-up", {
                email: email,
            });
            setState({ ...state, loading: false });
        } catch (error) {
            setState({ ...state, loading: false });
            showError(error[1][0].msg);
            trackEvent("sign-up-fail", {
                email: email,
            });
        }
    };

    return (
        <>
            {signIn && <SignIn visible={signIn} onDecline={closeSignIn} openPhoneVerification={openPhoneVerification} requestOTP={requestOTP} loading={state.loading} />}
            {signUp && <SignUp visible={signUp} onDecline={closeSignUp} openPhoneVerification={openPhoneVerification} newUserAuth={newUserAuth} loading={state.loading} />}
            {phoneVerification && <PhoneVerification visible={phoneVerification} onDecline={closePhoneVerification} verifyCode={verifyCode} loading={state.loading} token={state.token} country_code={state.country_code} phone={state.phone} requestOTP={requestOTP} />}
        </>
    );
};

export default connect(null, { getOtp, verifyOtp, updateNewUser })(CustomerAuth);
