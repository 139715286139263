import React from 'react'
import { useMediaQuery } from 'react-responsive'
import ImageWithPreloader from '../../../utils/CustomImage'
import VideoWithPreloader from '../../../utils/CustomVideo'

const AboutUs3dGrid = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    return (

        <section style={{ position: 'relative' }}>
            <div className={'animatedLogoContainer '}>
                {/* <VideoWithPreloader objectFit={'contain'} Br={'50%'} src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340558437'} alt={'logo_gif'} width={'auto'} /> */}

                <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402428249'} alt={'alt'} className='animate__animated animate__pulse animate__infinite' />

            </div>
            <ul className='threeDGrid'>
                <li style={{ bottom: isDesktopOrLaptop ? '-35px' : '-20px', right: isDesktopOrLaptop ? '-50px' : '-25px' }}>
                    <ImageWithPreloader src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710267674798'} alt='topLeft' />
                    {/* <img /> */}
                </li>
                <li style={{ bottom: isDesktopOrLaptop ? '-35px' : '-20px', left: isDesktopOrLaptop ? '-50px' : '-25px' }}>
                    <ImageWithPreloader src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710267468642'} alt='topRight' />
                    {/* <img /> */}
                </li>
                <li style={{ bottom: isDesktopOrLaptop ? '35px' : '20px', right: isDesktopOrLaptop ? '-50px' : '-25px' }}>
                    <ImageWithPreloader src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285421524'} alt='bottomLeft' />
                    {/* <img /> */}
                </li>
                <li style={{ bottom: isDesktopOrLaptop ? '35px' : '20px', left: isDesktopOrLaptop ? '-50px' : '-25px' }}>
                    <ImageWithPreloader src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285466103'} alt='bottomRight' />
                    {/* <img  /> */}
                </li>
            </ul>

        </section>

    )
}

export default AboutUs3dGrid