import React from 'react'

const FullScreenLoader = () => {
  return (
    <div className='fullScreenLoader'>
        <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402377848'} alt='logo' style={{width:'150px', height:'150px'}} className='animate__animated animate__pulse'/>
    </div>
  )
}

export default FullScreenLoader