import React, { useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import '../../assets/styles/customersStyles.css';
import { getOrdersStatus } from '../../redux/actions/CustomerActions';
import {
    ActiveBike, BillActive, BillInactive,
    CookingActive, CookingInactive, ForkActive, ForkInactive,
    InactiveBike
} from '../../assets/images/Icons';

const OrderAccordion = ({ data }) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const toggleAccordion = () => {
        setOpen(prevOpen => !prevOpen);
        getSingleOrderStatus();
    };

    const getSingleOrderStatus = () => {
        dispatch(getOrdersStatus(data?.id));
    };
    // console.log(data, 'data')

    const renderStatusIcon = (activeCondition, activeIcon, inactiveIcon) => {
        return (
            <li className="orderStatus" style={{ backgroundColor: activeCondition ? '#66BB6A' : '#ffffff' }}>
                <img src={activeCondition ? activeIcon : inactiveIcon} alt="icon" />
            </li>
        );
    };

    const renderStatusBorder = (activeCondition, activeColor, inactiveColor) => {
        return (
            <div style={{ borderColor: activeCondition ? activeColor : inactiveColor }} />
        );
    };

    const renderOrderItems = () => {
        if (data && data?.orderItems?.length) {
            return data.orderItems.map((item, index) => (
                <div className="flexRow justifyBetween alignCenter brBottom" style={{ marginBottom: '16px' }} key={index}>
                    <div>
                        <h3 className="boldText f14 capitalize" style={{ color: '#696A6B' }}>{item?.productVariation?.name}</h3>
                        <p className="semiBoldText f14" style={{ color: '#919294' }}>₦{item?.price}</p>
                    </div>
                    <div>
                        <p className="semiBoldText f12 capitalize" style={{ color: '#919294' }}>x{item?.quantity}</p>
                    </div>
                </div>
            ));
        }
        return null;
    };

    const renderStatusText = (status) => {
        switch (status) {
            case 'ORDER_CREATED':
                return 'PENDING';
            case 'ORDER_CONFIRMED':
                return 'CONFIRMED';
            case 'ORDER_AWAITING_ASSIGNMENT':
                return 'AWAITING ASSIGNMENT';
            case 'ORDER_ASSIGNED':
                return 'ASSIGNED';
            case 'ORDER_ACCEPTED':
                return 'ACCEPTED';
            case 'ORDER_REJECTED':
                return 'REJECTED';
            case 'ORDER_PICKED_UP':
                return 'TRANSIT';
            case 'ORDER_DELIVERED':
                return 'DELIVERED';
            default:
                return 'CANCELLED';
        }
    };

    const renderStatusColor = (status) => {
        switch (status) {
            case 'ORDER_CREATED':
                return '#EBF2FA';
            case 'ORDER_CONFIRMED':
            case 'ORDER_AWAITING_ASSIGNMENT':
            case 'ORDER_ACCEPTED':
                return '#DEFFEE';
            case 'ORDER_ASSIGNED':
                return '#E1E8FF';
            case 'ORDER_REJECTED':
                return '#FFE6E4';
            case 'ORDER_PICKED_UP':
                return '#FFF8E1';
            case 'ORDER_DELIVERED':
                return '#DEFFEE';
            default:
                return '#FFE6E4';
        }
    };

    const renderStatusTextColor = (status) => {
        switch (status) {
            case 'ORDER_CREATED':
                return '#A6B7D4';
            case 'ORDER_CONFIRMED':
            case 'ORDER_AWAITING_ASSIGNMENT':
            case 'ORDER_ACCEPTED':
                return '#66CB9F';
            case 'ORDER_ASSIGNED':
                return '#4C6FFF';
            case 'ORDER_REJECTED':
                return '#F16063';
            case 'ORDER_PICKED_UP':
                return '#FFD54F';
            case 'ORDER_DELIVERED':
                return '#66CB9F';
            default:
                return '#F16063';
        }
    };

    return (
        <div className="orderAccordionContainer" onClick={toggleAccordion}>
            <div className="orderAccordionHeader flexRow alignCenter justifyBetween">
                <div className="flexRow alignCenter">
                    <p className="semiBoldText">{data?.originBusinessName}</p>
                    <p className="regularText accordionStatus" style={{ backgroundColor: renderStatusColor(data?.orderStatus), color: renderStatusTextColor(data?.orderStatus) }}>
                        {renderStatusText(data?.orderStatus)}
                    </p>
                </div>
                <div>
                    <MdKeyboardArrowDown />
                </div>
            </div>
            {open && (
                <>
                    <div className="orderAccordionDetails">
                        {renderOrderItems()}
                    </div>
                    <ul className="orderStatusContainer">
                        {renderStatusIcon(
                            ['ORDER_ASSIGNED', 'ORDER_AWAITING_ASSIGNMENT', 'ORDER_CREATED', 'ORDER_PICKED_UP', 'ORDER_DELIVERED', 'ORDER_ACCEPTED', 'ORDER_CONFIRMED'].includes(data?.orderStatus),
                            BillActive, BillInactive
                        )}

                        {/* {renderStatusBorder(
                            ['ORDER_ASSIGNED','ORDER_AWAITING_ASSIGNMENT', 'ORDER_CREATED', 'ORDER_PICKED_UP', 'ORDER_DELIVERED', 'ORDER_ACCEPTED', 'ORDER_CONFIRMED'].includes(data?.orderStatus),
                            '#66BB6A', '#D6D6D8'
                        )} */}
                        <div style={{ borderColor: '#D6D6D8' }} />
                        {renderStatusIcon(
                            ['ORDER_ASSIGNED', 'ORDER_AWAITING_ASSIGNMENT', 'ORDER_PICKED_UP', 'ORDER_DELIVERED', 'ORDER_ACCEPTED'].includes(data?.orderStatus),
                            CookingActive, CookingInactive
                        )}
                        <div style={{ borderColor: '#D6D6D8' }} />
                        {renderStatusIcon(
                            ['ORDER_ASSIGNED', 'ORDER_AWAITING_ASSIGNMENT', 'ORDER_PICKED_UP', 'ORDER_DELIVERED', 'ORDER_ACCEPTED'].includes(data?.orderStatus),
                            ActiveBike, InactiveBike
                        )}
                        <div style={{ borderColor: '#D6D6D8' }} />
                        {/* {renderStatusBorder(
                            data?.orderStatus === 'ORDER_DELIVERED',
                            '#66BB6A', '#D6D6D8'
                        )} */}
                        {renderStatusIcon(
                            data?.orderStatus === 'ORDER_DELIVERED',
                            ForkActive, ForkInactive
                        )}
                    </ul>
                </>
            )}
        </div>
    );
};

export default OrderAccordion;
