import React, { useState } from 'react';
import CustomButton from '../../utils/CustomButton';
import http from '../../utils/Utils';
import Select from 'react-select';
import { showError, showSuccess } from '../../utils/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { getRiderAgentOrders, getRiderAgentSingleOrder } from '../../redux/actions/RiderAgentActions';
import useKeyboardAwareModal from '../../utils/useKeyboardAwareHook';

const RiderAgentStatusUpdateModal = ({ isOpen, onClose, orderId }) => {

    useKeyboardAwareModal()
    const [state, setState] = useState({
        loading: false,
        orderStatus: null,
    });

    const dispatch = useDispatch();

    const disabled = () => {
        const { loading, orderStatus } = state;
        return !orderStatus || loading;
    };



    const handleChangeOrderStatus = (selectedOption) => {
        setState((prevState) => ({ ...prevState, orderStatus: selectedOption.value }));
    };


    const orderStatusOptions = [
        { value: 'pick_order', label: 'Pick Order' },
        { value: 'deliver_order', label: 'Deliver Order' }
    ];

    const assignRider = async () => {
        const { rider, orderStatus } = state;
        setState((prevState) => ({ ...prevState, loading: true }));
        try {
            const obj = { rider_id: rider?.value, order_status: orderStatus };
            const res = await http.put(`/rider_agency/orders/${orderId}`, obj, true, {}, "v2");
            showSuccess(res?.message);
            dispatch(getRiderAgentSingleOrder(orderId));
            onClose();
        } catch (error) {
            console.error('Failed to update stat', error);
            showError('Failed to Update Order Status')
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    return (
        <div className={`riderAgentModal ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="riderAgentModalContent" onClick={(e) => e.stopPropagation()}>
                <h2 className={'f20 boldText'}>Update Order Status</h2>
                <div style={{ margin: '36px 0' }}>
                    <label className={`f14 grey semiBoldText flex alignCenter`} >
                        Order Status
                    </label>

                    <Select
                        options={orderStatusOptions}
                        onChange={handleChangeOrderStatus}
                        placeholder="Select order status"
                        styles={{
                            option: (base) => ({
                                ...base,
                                color: '#000',
                                padding: '8px !important'
                            }),
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: '#ebebeb',
                                borderRadius: '8px',
                                padding: '6px 16px',
                                marginTop: '8px'
                            }),
                        }}
                    />
                </div>
                <CustomButton
                    onClick={assignRider}
                    disabled={disabled()}
                    loading={state.loading} title={'Update Status'} />
            </div>
        </div>
    );
};

export default RiderAgentStatusUpdateModal;
