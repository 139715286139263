import React, { useEffect, useRef, useState } from 'react';
import '../assets/styles/homeStyles.css';
import '../assets/styles/generalStyles.css';
import '../assets/styles/customersStyles.css';
import { useNavigate } from 'react-router-dom';
import HomeNavbar from '../widgets/HomeNavBar';
import AddressModal from '../components/home/AddressModal';
import { ToastContainer } from 'react-toastify';
import BackComponent from './BackComponent';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import CustomButton from './CustomButton';
import mixpanel, { track } from 'mixpanel-browser';
import { trackEvent } from './Mixpanel';






const NotFoundPage = (props) => {
    const navigate = useNavigate()

    const [state, setState] = useState({
        showAddressModal: false, signInModal: false, signUpModal: false, phoneVerificationModal: false,
    })
    const currentLocationRef = useRef(null);

    useEffect(() => {
        currentLocationRef.current = localStorage.getItem('location');
        trackEvent('visits_404_page')
    },[]); // Empty dependency array means this runs once after the initial render

    // You can use currentLocationRef.current to access the location
    // console.log(currentLocationRef.current);
    const goHome = () => {
        if (currentLocationRef) {
            navigate('/home')
            // mixpanel.track('order-now-clicks')

        } else {
            navigate('/locations')
            // mixpanel.track('order-now-clicks')

        }
    }

    const hideScroll = () => {
        document.body.style.overflow = "hidden"
    }
    const unhideScroll = () => {
        document.body.style.overflow = "unset"
    }

    const openAddressModal = () => {
        setState((prevState) => ({ ...prevState, showAddressModal: true }))
        hideScroll()
    }
    const closeAddressModal = () => {
        setState((prevState) => ({ ...prevState, showAddressModal: false }))
        unhideScroll()
    }


    const openSignInModal = () => {
        setState((prevState) => ({ ...prevState, signInModal: true }))
        closePhoneVerificationModal()
        closeSignUpModal()
        hideScroll()
    }
    const closeSignInModal = () => {
        setState((prevState) => ({ ...prevState, signInModal: false }))
        unhideScroll()
    }


    const openSignUpModal = () => {
        setState((prevState) => ({ ...prevState, signUpModal: true }))
        closePhoneVerificationModal()
        closeSignInModal()
        hideScroll()
    }
    const closeSignUpModal = () => {
        setState((prevState) => ({ ...prevState, signUpModal: false }))
        unhideScroll()
    }


    const openPhoneVerificationModal = () => {
        setState((prevState) => ({ ...prevState, phoneVerificationModal: true, }))
        closeSignInModal()
        closeSignUpModal()
        hideScroll()
    }
    const closePhoneVerificationModal = () => {
        setState((prevState) => ({ ...prevState, phoneVerificationModal: false }))
        unhideScroll()
    }


    return (
        <>
            <HomeNavbar bgColor={'#ffffff'} onClick={openAddressModal} openAuthModal={openSignInModal}
                showSearch={false}
            />
            <main className={'landingPageContainer customerLandingPage'} style={{ background: '#ffffff' }}>
                <section className={'onGoBack'}>
                    <BackComponent />
                </section>
                <section className='notFoundContainer'>
                    <div>
                        <Player
                            autoplay
                            loop
                            src={require('../assets/images/lotties/NotFound.json')}
                            className={'notFoundImage'}

                        >
                            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                    </div>
                    <div>
                        <h3 className={'f24 boldText textCenter'}>You seem to be lost</h3>
                        <p className={'f14 regularText textCenter'} style={{ color: '#919294', margin: '0 0 30px 0' }}>The page you are trying to view is not available.</p>
                        <div style={{ width: '142px', margin: 'auto' }}>
                            <CustomButton title={'Return home'} onClick={goHome} />
                        </div>
                    </div>
                </section>
                <AddressModal visible={state.showAddressModal} onDecline={closeAddressModal} />
            </main>

        </>
    )
}

export default NotFoundPage;


// 

