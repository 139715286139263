import React, { useState } from 'react';
import CustomButton from '../../utils/CustomButton';
import http from '../../utils/Utils';
import Select from 'react-select';
import { showSuccess } from '../../utils/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { getRiderAgentOrders, getRiderAgentSingleOrder } from '../../redux/actions/RiderAgentActions';
import useKeyboardAwareModal from '../../utils/useKeyboardAwareHook';

const RiderAgentAssignRiderModal = ({ isOpen, onClose, orderId }) => {

    useKeyboardAwareModal()
    const [state, setState] = useState({
        rider: null,
        loading: false,
    });

    const dispatch = useDispatch();
    const { riderAgentRiders } = useSelector((state) => state.riderAgent)
    // console.log(riderAgentRiders,'riders')

    const disabled = () => {
        const { rider, loading } = state;
        return !rider || loading;
    };

    const handleChange = (selectedOption) => {
        setState((prevState) => ({ ...prevState, rider: selectedOption }));
        // console.log(selectedOption, 'op')

    };
    const riderOptions = riderAgentRiders.data?.map((rider) => ({
        value: rider.id,
        label: `${rider?.user?.firstName} - ${rider?.user?.lastName}`,
        riderLocation: rider?.riderOperationLocation,
        activeStatus: rider?.isRiderActive,
    }));


    const assignRider = async () => {
        const { rider } = state;
        setState((prevState) => ({ ...prevState, loading: true }));
        try {
            const obj = { rider_id: rider?.value, order_status: 'accept_order' };
            const res = await http.put(`/rider_agency/orders/${orderId}`, obj, true, {}, "v2");
            showSuccess(res?.message);
            dispatch(getRiderAgentSingleOrder(orderId));
            onClose();
        } catch (error) {
            console.error('Failed to assign rider', error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    return (
        <div className={`riderAgentModal ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="riderAgentModalContent" onClick={(e) => e.stopPropagation()}>
                <h2>Assign Rider</h2>
                <div style={{ margin: '36px 0' }}>
                    <label className={`f14 grey semiBoldText flex alignCenter`} >
                        Riders
                    </label>

                    <Select
                        options={riderOptions}
                        onChange={handleChange}
                        isSearchable
                        placeholder="Select rider"
                        styles={{
                            option: (base) => ({
                                ...base,
                                color: '#000',
                                padding: '8px !important'
                            }),
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: '#ebebeb',
                                borderRadius: '8px',
                                padding: '6px 16px',
                                marginTop: '8px'
                            }),
                        }}

                    />
                </div>
                <CustomButton
                    onClick={assignRider}
                    disabled={disabled()}
                    loading={state.loading} title={'Assign rider'} />

            </div>
        </div>
    );
};

export default RiderAgentAssignRiderModal;
