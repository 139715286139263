import React, { useEffect, useState } from 'react';
import CustomInput from '../../utils/CustomInput';
import CustomButton from '../../utils/CustomButton';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import useKeyboardAwareModal from '../../utils/useKeyboardAwareHook';


const RiderAgentWithdrawalModal = ({ isOpen, onClose, handleChange, onChangeAmount, amount, wallet_pin, onChangePin,loading , withdraw}) => {

    useKeyboardAwareModal()
    const { riderAgentPaymentInfo } = useSelector((state) => state.riderAgent)
    // Transform the array into the format expected by react-select
    const bankOptions = riderAgentPaymentInfo.map((bank) => ({
        value: bank.id,  // You can use bank.id or bank.bankCode depending on your needs
        label: `${bank.bankName} - ${bank.bankAccountNumber}`,  // This will show both bank name and account number
        bankAccountName: bank.bankAccountName,  // Keep other properties as needed
        bankCode: bank.bankCode,
        bankAccountType: bank.bankAccountType,
    }));

    const disabled = () => {
        return !amount || !wallet_pin || loading;
    }


    return (
        <div className={`riderAgentModal ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="riderAgentModalContent" onClick={(e) => e.stopPropagation()}>
                <CustomInput label={'Amount'} value={amount} onChange={onChangeAmount} />

                <div style={{ marginBottom: '16px' }}>
                    <label className={`f14 grey semiBoldText flex alignCenter`} >
                        Bank name
                    </label>

                    <Select
                        options={bankOptions}
                        onChange={handleChange}
                        isSearchable
                        placeholder="Select a payment method"
                        styles={{
                            option: (base) => ({
                                ...base,
                                color: '#000',
                                padding: '8px !important'
                            }),
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: '#ebebeb',
                                borderRadius: '8px',
                                padding: '6px 16px',
                                marginTop: '8px'
                            }),
                        }}

                    />
                </div>
                <CustomInput label={'Withdrawal Pin'} value={wallet_pin} onChange={onChangePin} />
                <CustomButton title={'Withdraw'}  loading={loading} disabled={disabled()} onClick={withdraw}/>
            </div>
        </div>
    );
};

export default RiderAgentWithdrawalModal;
