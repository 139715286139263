import React from 'react'
import { useMediaQuery } from 'react-responsive'

const AboutUsCard = ({ items, title }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const renderItems = () => {
        if (items) {
            return items?.map((item, index) => {
                return (
                    <li className={'flexRow  alignCenter'} key={index}>
                        <span className='itemIcon' style={{
                            background: item?.type === 'green' ? '#43A047' : '#181819',
                            border: item?.type === 'green' ? '1px solid #C8E6C9' : '1px solid rgba(200, 230, 201, 1)',
                            color: 'rgba(255, 255, 255, 1)'
                        }}>
                            <img src={item?.icon} alt='icons' />
                        </span>
                        <div>
                            <h5 style={{ fontSize: isDesktopOrLaptop ? '20px' : '16px', color: '#622E18' }}>{item?.title}</h5>
                            <p style={{ fontSize: isDesktopOrLaptop ? '16px' : '14px', color: '#565758' }}>{item?.description}</p>
                        </div>
                    </li>
                )
            })
        }
    }



    return (
        <div className={'aboutUsCard'}>
            <header>
                <h4>{title}</h4>
            </header>
            <ul style={{ display: 'grid', gridTemplateColumns: isDesktopOrLaptop ? 'repeat(2,1fr)' : 'repeat(1,1fr)', gap: '16px' }}>
                {renderItems()}
            </ul>
        </div>
    )
}

export default AboutUsCard