import React from 'react';
import CustomInput from '../../utils/CustomInput';
import CustomButton from '../../utils/CustomButton';
import { Warning2 } from 'iconsax-react';
import useKeyboardAwareModal from '../../utils/useKeyboardAwareHook';



const RiderAgentPinModal = ({ isOpen, onClose, onChange, pin, setPin ,loading}) => {
useKeyboardAwareModal()
    return (
        <div className={`riderAgentModal ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="riderAgentModalContent" onClick={(e) => e.stopPropagation()}>
                <div style={{
                    background: 'rgba(255, 245, 234, 1)', padding: '10px', borderRadius: '12px',
                    color: 'rgba(66, 84, 102, 1)', textAlign: 'center', display: 'flex',
                    flexDirection: 'column', alignItems: "center", marginBottom:'20px'
                }}>
                    <Warning2 size="20" color="rgba(230, 178, 32, 1)" />
                    <p className={'f12 boldText'} style={{margin:'10px 0 5px 0'}}>Please setup withdrawal pin to enable you withdraw.</p>
                    <p className={'f12 boldText'}>If you face any issue or need to reset pin please contact support.</p>
                </div>
                <div>
                    {/* <OtpInput valueLength={4}/> */}
                    <CustomInput label={'Enter 4 digit pin'} type={'tel'} onChange={onChange} value={pin} />
                    <CustomButton title={'Set pin'} onClick={setPin} loading={loading}/>
                </div>
            </div>
        </div>
    );
};

export default RiderAgentPinModal;
