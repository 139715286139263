import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegUserCircle } from "react-icons/fa";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Player } from '@lottiefiles/react-lottie-player';
import { useMediaQuery } from 'react-responsive';
import { UPDATE_ROUTE } from '../redux/Types';
import { isEmpty } from '../utils/Helpers';
import { trackEvent } from '../utils/Mixpanel';
import { Driving, ProfileCircle, Shop } from 'iconsax-react';
import { MobileOrderNow, OrderNow } from '../assets/images';

const Navbar = () => {
    const { routeDetails, addressDetails } = useSelector(state => state.search);
    const [isNavVisible, setNavVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' });

    useEffect(() => {
        if (isEmpty(routeDetails)) {
            dispatch({ type: UPDATE_ROUTE, payload: { to: '/landing', label: 'The Customer' } });
        }
    }, [routeDetails, dispatch]);

    const toggleNav = () => setNavVisible(!isNavVisible);

    const handleRouteChange = (link) => {
        dispatch({ type: UPDATE_ROUTE, payload: link });
    };

    const renderIcon = (label) => {
        switch (label) {
            case 'Customers': return <ProfileCircle />;
            case 'Couriers': return <Driving />;
            case 'Vendors': return <Shop />;
            default: return <FaRegUserCircle />;
        }
    };

    const handleOrderClick = () => {
        trackEvent('Order_now_button_clicked');
        navigate(addressDetails ? '/home' : '/locations');
    };

    const getLogoUrl = (path) => {
        return path === '/' ? 'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402377848' : 'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402428249';
    };

    const links = [
        { to: '/', label: 'Customers' },
        { to: '/couriers', label: 'Couriers' },
        { to: '/vendors', label: 'Vendors' },
    ];

    return (
        <nav className="navContainer">
            <section className='flexRow alignCenter' style={{gap:isDesktop ? '40px':'5px'}}>
            <button className="callButton" style={{ width: isDesktop ?'70px' :'50px', height:isDesktop ?'70px':'50px' }}>
                <NavLink to="/">
                    <img src={getLogoUrl(location.pathname)} alt="logo" style={{ objectFit: 'cover', width: '100%', height:"100%" }} />
                </NavLink>
            </button>
            {!isDesktop ? (
                <section style={{ position: 'relative' }}>
                    <button className="mobileActiveLink flexRow alignCenter justifyBetween boldText f14" style={{ color: "#fff" }} onClick={toggleNav}>
                        {renderIcon(routeDetails?.label)}
                        {routeDetails?.label}
                        <MdKeyboardArrowDown color="rgba(4, 217, 209, 1)" />
                    </button>
                    {isNavVisible && (
                        <ul className="mobileNavDropDown">
                            {links.map(link => (
                                <li key={link.to} className="boldText f12" onClick={() => { handleRouteChange(link); toggleNav(); }} style={{ color: link.to === routeDetails?.to ? '#fff' : 'rgba(177, 178, 180, 1)' }}>
                                    <NavLink to={link.to} className="flexRow alignCenter" style={{ gap: '8px' }}>
                                        {renderIcon(link.label)}
                                        {link.label}
                                        {routeDetails?.to === link.to && <MdKeyboardArrowUp size={16} color="rgba(4, 217, 209, 1)" />}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    )}
                </section>
            ) : (
                <section className="navLinksContainer" style={{ borderColor: location.pathname === '/' ? '#181819' : '#025754', background: location.pathname === '/' ? '#032027' : '#E6FBFA' }}>
                    <ul>
                        {links.map(link => (
                            <li key={link.to} className="boldText f14">
                                <NavLink to={link.to} className={({ isActive }) => `flexRow alignCenter ${isActive ? (location.pathname === link.to ? 'activeLink-home' : 'activeLink-others') : (location.pathname === link.to ? 'none-home' : 'none-others')}`} style={{ gap: '8px' }}>
                                    {renderIcon(link.label)}
                                    {link.label}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </section>
            )}
            </section>
            {!isDesktop ? (  
                 <button
                 // className="mobileOrderNowBtn"
                 onClick={handleOrderClick}
                 style={{
                     height: '80px',
                     marginLeft:!isDesktop? '20px':'0',
                     position:'relative',
                 }}
             >
                 <img
                     src={MobileOrderNow}
                     alt="order_now"
                     style={{
                         height: '100%',
                         width: '100%',
                         objectFit: isDesktop ?'cover' :'contain',
                         zIndex: 5,
                     }}
                 />
                 <Player
                     autoplay
                     loop
                     src={require('../assets/images/lotties/OrderNow.json')}
                     style={{
                         position: 'absolute',
                         top: '10px',
                         left: '-2px',
                         width: '100%',
                         height: '40%',
                         zIndex: 10,
                     }}
                 />
             </button>
           
            ) :
            (
              <button
            // className="mobileOrderNowBtn"
            onClick={handleOrderClick}
            style={{
                height: '55px',
                marginLeft:!isDesktop? '20px':'0',
                position:'relative',
            }}
        >
            <img
                src={OrderNow}
                alt="order_now"
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: isDesktop ?'cover' :'contain',
                    zIndex: 5,
                }}
            />
            <Player
                autoplay
                loop
                src={require('../assets/images/lotties/OrderNow.json')}
                style={{
                    position: 'absolute',
                    top: isDesktop ? '5px':'10px',
                    left: isDesktop ? '-48px' :'-39px',
                    width: '100%',
                    height: isDesktop ? '70%': '50%',
                    zIndex: 10,
                }}
            />
        </button>)}
        </nav>
    );
};

export default Navbar;
