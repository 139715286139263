import React from 'react'
import { useSelector } from 'react-redux'
import RiderAgentRidersTable from '../../components/rider-agent/RiderAgentRidersTable'

const RiderAgentRiders = () => {
  const {riderAgentRiders} = useSelector((state)=> state.riderAgent)
  // console.log(riderAgentRiders, 'orders riders')
  return (
    <main className={'riderAgentContainer'}>
    <header>
        <h2>Riders</h2>
        <p>Manage riders</p>
    </header>

    <section>
        <RiderAgentRidersTable />
    </section>
   
</main>
  )
}

export default RiderAgentRiders