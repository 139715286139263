import React, { useState } from 'react';
import '../../assets/styles/modalStyles.css';
import { AiOutlineClose } from 'react-icons/ai';
import { DeliveryBottomImage } from '../../assets/images';
import { useDispatch, useSelector } from 'react-redux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Geocode from "react-geocode";
import { getAllRestaurants, getCategories } from '../../redux/actions/CustomerActions';
import CustomButton from '../../utils/CustomButton';
import { GET_LOCATIONS_SUCCESSFUL, STORE_ADDRESS, UPDATE_COORDINATES } from '../../redux/Types';


const AddressModal = (props) => {

    const [value, setValue] = useState(null)
    const [loading, setLoading] = useState(false)
    const { restaurants } = useSelector(state => state.customer)
    const dispatch = useDispatch()


    const disable = () => {
        if (!value) {
            return true
        }
        return false
    }

    const getLat = () => {
        if (value) {
            Geocode.setApiKey('AIzaSyA7SpnnJlPtGXUtCiJM-tPbY3D2BY-ZtCM')
            setLoading(true)
            Geocode.fromAddress(value?.label).then(
                (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    dispatch({ type: UPDATE_COORDINATES, payload: response.results[0].geometry.location })
                    dispatch({ type: GET_LOCATIONS_SUCCESSFUL, payload: value })

                    if (lat && lng) {
                        dispatch(getAllRestaurants(1, 30, lat, lng))
                        dispatch(getCategories())
                        if (!loading && restaurants) {
                            sessionStorage.setItem("location", value?.label);
                            setLoading(false)
                            Geocode.fromLatLng(lat, lng).then(
                                (response) => {
                                    dispatch({ type: STORE_ADDRESS, payload: response })
                                },
                                (error) => {
                                    throw error
                                }
                            );
                        }
                    }
                },
                (error) => {
                    setLoading(false)
                    throw error;
                }
            );
        }
    }


    return (
        <>
            {props.visible &&
                <section className={'modalContainer'}>

                    <div className={'modalContentContainer animate__animated animate__slideInUp'}>
                        <div className={'modalCloseBtn'} onClick={props.onDecline}>
                            <AiOutlineClose size={20} />
                        </div>
                        <div>
                            <h4 className={'boldText textCenter'}>Change Delivery address</h4>
                            <p className={'regularText textCenter'}>Enter address to view vendors around you</p>
                        </div>
                        <div>
                            <span className={'regularText'}>Cities</span>
                            <GooglePlacesAutocomplete
                                apiKey='AIzaSyA7SpnnJlPtGXUtCiJM-tPbY3D2BY-ZtCM'

                                selectProps={{
                                    value,
                                    onChange: setValue,
                                    // input
                                }}
                            />
                        </div>
                        <div className={'locationBtn'}>
                            <CustomButton title={'Continue'} onClick={getLat} loading={loading} disabled={disable()} />
                        </div>
                        <div className={'modalBottomImage'}>
                            <img src={DeliveryBottomImage} alt='borderImage' />
                        </div>
                    </div>
                </section>
            }
        </>
    )

}

export default AddressModal