import React from 'react'

const CardsWithHeader = (props) => {
    return (
        <>
            <section className={`cardWithHeaderContainer ${props.class}`}>
                <div className={'flexRow alignCenter cardWithHeaderHeader'}>
                    {props.icon && props.icon}
                    <h5 className={'f16 boldText'} style={{ color: '#38383A' , marginLeft:'10px'}}>
                        {props.title}
                    </h5>
                </div>
                {props.children}
            </section>
        </>
    )
}

export default CardsWithHeader