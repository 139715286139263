import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import RiderAgentMobileCard from './RiderAgentMobileCard';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { getRiderAgentOrders } from '../../redux/actions/RiderAgentActions';
import Skeleton from 'react-loading-skeleton';

const OrdersTable = () => {
    const [activeTab, setActiveTab] = useState('Active');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const { riderAgentOrders, gettingRiderAgentOrders } = useSelector(state => state.riderAgent);
    const meta = riderAgentOrders?.data?.meta;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const orderStatus = activeTab === 'Active' ? 'active' : 'delivered';
        dispatch(getRiderAgentOrders(currentPage, meta?.perPage, null, null, orderStatus));
    }, [activeTab, currentPage]);

    const getSingleOrder = (val) => {
        navigate('/rider_agency/orders/details', {
            state: {
                data: val,
            }
        });
    };

    const filteredOrders = riderAgentOrders && riderAgentOrders?.data?.orders?.filter(order =>
    (order.id.toString().includes(searchTerm) ||
        order.user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.originBusinessName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.orderStatus.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const paginatedOrders = filteredOrders?.slice((currentPage - 1) * meta?.perPage, currentPage * meta?.perPage);

    const handlePageChange = (direction) => {
        if (direction === 'next' && currentPage < meta?.totalPages) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <section className='ordersTableContainer'>
            <nav className='ordersTableNav'>
                <div className='ordersTableTabContainer'>
                    <button className={activeTab === 'Active' ? 'active' : ''} onClick={() => setActiveTab('Active')}>
                        Active Orders
                    </button>
                    <button className={activeTab === 'Delivered' ? 'active' : ''} onClick={() => setActiveTab('Delivered')}>
                        Delivered Orders
                    </button>
                </div>
                <div className='ordersTableSearch' style={{ marginTop: '20px' }}>
                    <input
                        type="text"
                        placeholder="Search orders..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className='input'
                    />
                </div>
            </nav>


            <div style={{ margin: '40px 0' }}>
                {gettingRiderAgentOrders ? <>

                    {[...Array(4)].map((_, index) => (
                        <div key={index}>
                            <Skeleton className='activeOrdersCard' style={{ height: '200px', borderColor: '#ccc' }} />
                        </div>
                    ))}

                </> :
                    paginatedOrders?.map(order => (
                        <RiderAgentMobileCard key={order.id} data={order} />
                    ))
                }
            </div>

            <div className="paginationContainer">
                <button
                    className="flexRow alignCenter"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange('prev')}
                >
                    <ArrowLeft2 size="16" color="#000" variant="Bold" />
                    Previous
                </button>
                <span>Page {currentPage} of {meta?.totalPages}</span>
                <button
                    className="flexRow alignCenter"
                    disabled={currentPage === meta?.totalPages}
                    onClick={() => handlePageChange('next')}
                >
                    Next
                    <ArrowRight2 size="16" color="#000" variant="Bold" />
                </button>
            </div>
        </section>
    );
};

export default OrdersTable;
