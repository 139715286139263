import React from 'react';
import { AiOutlineClose, AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';

const Counter = (props) => {
    return (
        <div className={'counterContainer'} style={{ backgroundColor: props.bgColor, marginRight: props.mr }}>
            <span onClick={props.decrease}  ><AiOutlineMinusCircle color={props.controlsColor} /></span>
            <span className={'boldText'} style={{ color: props.counterColor }}>{props.counter}</span>
            <span onClick={props.increase}><AiOutlinePlusCircle color={props.controlsColor} /></span>
        </div>
    )
}

export default Counter