import React from 'react'

const CartFloatingButton = (props) => {
  return (
    <div className={'cartFloatingButton flexRow alignCenter justifyBetween'} onClick={props.onClick} style={{ zIndex: 20 }}>
      <div className={'flexRow alignCenter'}>
        <div className={'orderCount'}>
          <p className={'f14 boldText'} style={{ color: '#181819' }}>{props.amount}</p>
        </div>
        <div>
          <p className={'f14 semiBoldText'} style={{ color: '#F9F9FB' }}>View orders</p>
        </div>
      </div>
      <div>
        <p className={'boldText f16'} style={{ color: '#F9F9FB' }}>₦{props.price}</p>
      </div>
    </div>
  )
}

export default CartFloatingButton