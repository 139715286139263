import React, { useEffect, useRef, useState } from 'react'
import { AboutFirstSlide, AboutSecondSlide, AboutThirdSlide, Screen1 } from '../../../assets/images';
import { EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import ImageWithPreloader from '../../../utils/CustomImage';



const AboutVideoSection = () => {
    const swiperRef = useRef(null);
    useEffect(() => {
        // Autoplay the first video when the component mounts
        const firstVideo = document.getElementById('video-one');
        const secondVideo = document.getElementById('video-two');
        const thirdVideo = document.getElementById('video-three');

        if (firstVideo) {
            firstVideo.play();
        }
        if (secondVideo) {
            secondVideo.play();
        }
        if (thirdVideo) {
            thirdVideo.play()
        }
    }, [])

    return (
        <section className='about-swiper'>
            <ImageWithPreloader src={Screen1} alt={'people'} />
            {/* <img src={Screen1} alt='screen1' /> */}
        </section>
    );
};

export default AboutVideoSection;
