import React, { useEffect, useRef, useState } from 'react'
import CustomInput from '../../utils/CustomInput'
import CustomButton from '../../utils/CustomButton'
import OtpInput from '../../utils/OtpInput'
import { showError, showSuccess } from '../../utils/Toast'
import { getRiderAgentEmailOtp, getRiderAgentPhoneOtp, verifyRiderAgentOtp } from '../../redux/actions/RiderAgentActions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const RiderAgentLogin = () => {

    const [state, setState] = useState({
        showMailInput: false, showPhoneInput: true, showOtpInput: false, email: '', phone: '',
        loading: false, pin: '',  country_code: '234',
    })
    const dispatch = useDispatch()
    const Ref = useRef(null);
    const [timer, setTimer] = useState("00:00");
    const [token, setToken] = useState('')
    const navigate = useNavigate()

    const onChangePin = (value) => {
        setState({ ...state, pin: value });
    };

    const showEmail = () => {
        setState({ ...state, showMailInput: true, showPhoneInput: false, showOtpInput: false, phone: '' });
    }

    const showPhone = () => {
        setState({ ...state, showPhoneInput: true, showMailInput: false, showOtpInput: false, email: "" });
    }

    const showOtp = () => {
        setState({ ...state, showOtpInput: true, showMailInput: false, showPhoneInput: false });
    }

    const onChangePhone = (e) => {
        setState({ ...state, phone: e.target.value });
    };

    const onChangeEmail = (e) => {
        setState({ ...state, email: e.target.value });
    };




    

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);

        return {
            total,
            minutes,
            seconds,
        };
    };

    const startTimer = (e) => {
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer((minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds));
        }
    };

    const clearTimer = (e) => {
        setTimer("01:30");
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 90);
        return deadline;
    };


    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);


    const onClickReset = () => {
        const { phone, country_code } = state
        clearTimer(getDeadTime());
        requestOTP(country_code, phone);
    };


    const renderInput = () => {
        const { showMailInput, showOtpInput, showPhoneInput, phone, pin, email } = state
        if (showMailInput) {
            return <CustomInput label={'Enter email address'} value={email} onChange={onChangeEmail} type={'email'} />
        }
         if (showPhoneInput) {
            return <CustomInput label={'Enter phone number'} onChange={onChangePhone} value={phone} type={'tel'} />
        }
       if (showOtpInput) {
            return <OtpInput value={pin} valueLength={6} onChange={onChangePin} />
        }
       return null
    }

    const renderToggleShowBtn = () => {
        const { showMailInput, showPhoneInput } = state
        if (showMailInput) {
            return (

                <div className={'flexRow alignCenter justifyCenter'} style={{ padding: '20px' }}>
                    <p className={'f14 regularText'}>Having issues?</p>
                    <button className={'f14 boldText'} style={{
                        fontWeight: '400', marginLeft: '5px',
                        textDecoration: 'underline', color: 'rgba(104, 232, 227, 1)'
                    }}
                        onClick={showPhone}
                    >Try using your phone number</button>
                </div>


            )
        }
        else if (showPhoneInput) {
            return (
                <div className={'flexRow alignCenter justifyCenter'} style={{ padding: '20px' }}>
                    <p className={'f14 regularText'}>Having issues?</p>
                    <button className={'f14 boldText'} style={{
                        fontWeight: '400', marginLeft: '5px',
                        textDecoration: 'underline', color: 'rgba(104, 232, 227, 1)'
                    }}
                        onClick={showEmail}
                    >Try using your email</button>
                </div>
            )
        }
        return null
    }

    const renderCounter = () => {
        const { showOtpInput } = state
        if (showOtpInput) {
            return (
                <div style={{margin:'10px 0 26px 0'}}>
                    <p className={"textCenter semiBoldText bottomLink"} style={{ color:'#000'}}>
                        Didn’t receive OTP?
                        {timer !== "00:00" ? (
                            <span className={timer !== "00:00" ? "timerActive " : "timerInactive"} style={{color: 'rgba(104, 232, 227, 1)' }}> Resend OTP in {timer}</span>
                        ) : (
                            <span className={"timerInactive"} onClick={onClickReset} style={{color: 'rgba(104, 232, 227, 1)' }}>
                                {" "}
                                Resend OTP{" "}
                            </span>
                        )}
                    </p>
                </div>
            )
        }
    }



    const requestOTP = async () => {
        const { phone, email, country_code } = state;
        showOtp()
        setState({ ...state, loading: true });
        // console.log(email, phone)
        try {
            let res;
            if (email === '' && phone) {
                // Request OTP via phone
                const obj = { country_code, phone }
                res = await dispatch(getRiderAgentPhoneOtp(obj));
               
            } else if (phone === '' && email) {
                // Request OTP via email
                const obj = { email: email.trim() }
                res = await dispatch(getRiderAgentEmailOtp(obj));

            } else {
                showError('Email or phone number must be entered to proceed')
            }
            // console.log(res)
            // console.log(res?.data,'dcdc')
            setToken(res?.data?.token)
            setState({ ...state, loading: false, token: res?.data?.token, phone, country_code });
            if(res) {
                showOtp()
            }
        } catch (error) {
            setState({ ...state, loading: false });
            showError(error?.message || "An error occurred");
            // console.log(error)
        }
    };


    const verifyCode = async () => {
        const {pin,  phone, country_code} = state
        const obj = {otp:pin, token };
        setState({ ...state, loading: true });
        try {
            const res = await dispatch(verifyRiderAgentOtp(obj));
            showSuccess("Logged In");
            setState({ ...state, loading: false });
            navigate('/rider_agency/orders') 
           
        } catch (error) {
            setState({ ...state, loading: false });
            showError("Please enter a valid code");    
        }
    };

    const renderLoginBtn = () => {
        const {showOtpInput}= state
        if(showOtpInput) {
            return (
                <CustomButton title={'Verify'} onClick={verifyCode} loading={state.loading} />
            )
        }
       return  <CustomButton title={'Login'} onClick={requestOTP} loading={state.loading} />
        
    }


    return (
        <main className={'riderAgentContainer'}>
            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402377848'} alt='logo' style={{ width: '60px', height: '60px', objectFit: 'contain' }} />

            <section className={'riderAgentLoginContainer'}>
                <header>
                    <h3 className={'boldText f30'}>Login</h3>
                    <p className={'regularText f16'}>Enter login details to access dashboard</p>
                </header>
                <div >
                    {renderInput()}
                    {renderCounter()}
                    {renderLoginBtn()}
                </div>
                {renderToggleShowBtn()}
            </section>
        </main>
    )
}

export default RiderAgentLogin