import React from 'react';
import { useSelector } from 'react-redux';
import '../../assets/styles/customersStyles.css';
import EmptyCard from './EmptyCard';
import PreOrderCard from './PreOrderCard';

const CartCard = (props) => {

    const { showEmptyState, showOrders, openAddAddressModal, openSavedAddressModal, selectedProduct, singleProduct, } = props
    const { loggedIn } = useSelector(state => state.auth)
    const { cart } = useSelector(state => state.customer);



    const renderCarts = () => {

        if (!loggedIn && cart && !cart?.data?.cart) {
            return (
                <EmptyCard />
            )
        }
        if (loggedIn && cart && !cart?.data?.cart) {
            return (
                <EmptyCard />
            )
        }
        if (cart && cart?.data?.cart?.items?.length !== 0) {
            return (
                <PreOrderCard showEmptyState={showEmptyState} showOrders={showOrders} openAddAddressModal={openAddAddressModal} openSavedAddressModal={openSavedAddressModal}
                    selectedProduct={selectedProduct} singleProduct={singleProduct}
                />
            )
        }
    }


    return (
        <section style={{ position: 'sticky', top: '90px', zIndex: '2' }}>
            {renderCarts()}
        </section>
    )
}

export default CartCard