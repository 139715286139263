import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRiderAgentProfile } from '../../redux/actions/RiderAgentActions'
import { Logout, LogoutCurve } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import { SIGNOUT_RIDER_AGENT, SIGNOUT_RIDER_AGENT_SUCCESSFUL } from '../../redux/Types'

const RiderAgentProfile = () => {
  const {riderAgentProfile} = useSelector((state)=> state.riderAgent)
      // console.log(riderAgentProfile, 'orders riders')
      const dispatch = useDispatch()
      useEffect(()=> {
     
        dispatch(getRiderAgentProfile())
    },[])
    const navigate = useNavigate()
    const signOut = () => {
        localStorage.removeItem('tokenKey')
        dispatch({ type: SIGNOUT_RIDER_AGENT })
        dispatch({ type: SIGNOUT_RIDER_AGENT_SUCCESSFUL });
        navigate('/rider_agency/login');

    }

  return (
      
  
        <main className={'riderAgentContainer'}>
        <header>
           <div className={'profileImageContainer'}>
            <img src={riderAgentProfile?.profile?.image} alt='profile_image'/>
           </div>
           <h4 style={{fontSize:'26px', textAlign:'left'}}>{riderAgentProfile?.profile?.name}</h4>
        </header>
    
        <section>
           <div style={{marginBottom:'16px'}}>
            <h5 style={{fontSize:'18px'}}>Email address</h5>
            <p style={{color:'rgba(105, 106, 107, 1)'}}>{riderAgentProfile?.profile?.email}</p>
           </div>

           <div>
            <h5 style={{fontSize:'18px'}}>Phone number</h5>
            <p style={{color:'rgba(105, 106, 107, 1)'}}>{riderAgentProfile?.profile?.user?.phoneNumber}</p>
           </div>
        </section>

        <div className={'flexRow alignCenter justifyCenter'} style={{marginTop:'60px', gap:'10px', }} onClick={signOut}>
          <h3 style={{fontSize:'20px'}}>Logout</h3>
          <LogoutCurve size="18" variant="Linear" />
        </div>
       
    </main>
    
  )
}

export default RiderAgentProfile