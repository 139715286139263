import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { DeliveryBottomImage } from "../../../assets/images";
import CustomInput from "../../../utils/CustomInput";
import CustomButton from "../../../utils/CustomButton";

const SignUp = ({ onDecline, newUserAuth, loading }) => {
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
    });

    const { firstName, lastName, email } = state;

    const onChangeFirstName = (e) => {
        setState({ ...state, firstName: e.target.value });
    };

    const onChangeLastName = (e) => {
        setState({ ...state, lastName: e.target.value });
    };

    const onChangeEmail = (e) => {
        setState({ ...state, email: e.target.value });
    };

    const disabled = loading || !firstName || !lastName || !email;

    return (
        <section className="modalContainer">
            <div className="modalContentContainer">
                <div className="modalCloseBtn" onClick={onDecline}>
                    <AiOutlineClose size={20} />
                </div>
                <div>
                    <h4 className="boldText textCenter">Update Profile</h4>
                    <p className="regularText textCenter">To continue update profile</p>
                </div>
                <div>
                    <div>
                        <CustomInput label="First name*" placeholder="Enter your first name" type="text" onChange={onChangeFirstName} />
                    </div>
                    <div>
                        <CustomInput label="Last name*" placeholder="Enter your last name" type="text" onChange={onChangeLastName} />
                    </div>
                    <div>
                        <CustomInput label="Email address*" placeholder="Enter your email address" type="email" onChange={onChangeEmail} />
                    </div>
                </div>

                <div>
                    <CustomButton title="Continue" onClick={() => newUserAuth(firstName, lastName, email)} loading={loading} disabled={disabled} />
                </div>

                <div className="terms" style={{ margin: "30px auto" }}>
                    <p className="textCenter regularText">
                        By continuing you agree to the <span className="boldText">Privacy Terms & Conditions</span> without reservation
                    </p>
                </div>

                <div className="modalBottomImage">
                    <img src={DeliveryBottomImage} alt="borderImage" />
                </div>
            </div>
        </section>
    );
};

export default SignUp;
