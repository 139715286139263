import moment from 'moment';
import React from 'react'
import { useNavigate } from 'react-router-dom';


const RiderAgentRiderMobileCard = (props) => {

  const { data } = props
  
  return (

    <section className={'activeOrdersCard'}>
      <div className={'flexRow justifyBetween activeOrdersCardHeader'} >
        <div className={'flexRow alignCenter'}>
          <div className={'userOrderAvatar'}>
            <img src={`${data?.user?.avatar}`} style={{ width: '100%', height: '100%', borderRadius: '100px' }} />
          </div>
          <div>
            <p className={'f12 boldText capitalize'}>
              {data?.user?.firstName || 'No name'}  {data?.user?.lastName || 'No name'}
            </p>
            <p className={'f12 regularText'}>+{data?.user?.phoneNumber}</p>
          </div>
        </div>
        <div>
          <p style={{ backgroundColor: 'rgba(4, 217, 209, 1)', borderRadius:'10px',  margin: '0', color: '#fff'}}
            className={'regularText f10 textCenter'}>
          {data?.user?.role}
          </p>
          <p className={'f10 regularText'} style={{ paddingTop: '5px' }}>
            {moment(data?.createdAt).format('YYYY-MM-DD hh:mm')}
          </p>
        </div>
      </div>
     
      <div className={'flexRow justifyBetween alignCenter '} style={{paddingTop:'16px'}}>
      <p style={{  color: '#000', fontWeight:'400'}}
            className={'boldText f12 textCenter'}>
         Rider location - {data?.riderOperationLocation}
          </p>
        {/* <div className={'activeOrderView'}>
          <p className={'semiBoldText f12'} onClick={getSingleOrder} style={{ cursor: 'pointer' }}>View</p>
        </div> */}
      </div>
    </section>

  )
}

export default RiderAgentRiderMobileCard