import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import MultiButton from '../../utils/MultiButton';
import Skeleton from 'react-loading-skeleton';
import BackComponent from '../../utils/BackComponent';
import CardsWithHeader from '../../components/rider-agent/CardsWithHeader';
import RiderAgentAssignRiderModal from '../../components/rider-agent/RiderAgentAssignRider';
import { useDispatch, useSelector } from 'react-redux';
import { getRiderAgentSingleOrder } from '../../redux/actions/RiderAgentActions';
import FullScreenLoader from '../../utils/FullScreenLoader';
import RiderAgentStatusUpdateModal from '../../components/rider-agent/RiderAgentStatusUpdate';

const RiderAgentOrderDetails = () => {

  const location = useLocation();
  const { data } = location.state || {};
  const dispatch = useDispatch()
  const { riderAgentSingleOrder, gettingRiderAgentSingleOrder } = useSelector((state) => state.riderAgent)
  console.log(riderAgentSingleOrder, 'order')

  useEffect(() => {
    dispatch(getRiderAgentSingleOrder(data?.id))
  }, [])


  const [state, setState] = useState({
    assignModal: false, statusModal: false,
  })

  const recievingCustomerName = riderAgentSingleOrder?.data?.user?.name;
  const recievingCustomerPhone = riderAgentSingleOrder?.data?.user?.phoneNumber;
  const destinationAddress = riderAgentSingleOrder?.data?.shipping?.destinationAddress;
  const orderStatus = riderAgentSingleOrder?.data?.order?.orderStatus || null;

  const hideScroll = () => {
    document.body.style.overflow = "hidden"
  }
  const unhideScroll = () => {
    document.body.style.overflow = "unset"
  }


  const openAssignModal = () => {
    setState((prevState) => ({ ...prevState, assignModal: true }))
    hideScroll()
  }
  const closeAssignModal = () => {
    setState((prevState) => ({ ...prevState, assignModal: false }))
    unhideScroll()
  }


  const openStatusModal = () => {
    setState((prevState) => ({ ...prevState, statusModal: true }))
    hideScroll()
  }
  const closeStatusModal = () => {
    setState((prevState) => ({ ...prevState, statusModal: false }))
    unhideScroll()
  }




  const statusTextSwitch = (status) => {
    switch (status) {
      case 'ORDER_CREATED':
        return 'PENDING'
      case 'ORDER_CONFIRMED':
        return 'CONFIRMED'
      case 'ORDER_AWAITING_ASSIGNMENT':
        return 'AWAITING ASSIGNMENT'
      case 'ORDER_ASSIGNED':
        return 'ASSIGNED'
      case 'ORDER_ACCEPTED':
        return 'ACCEPTED'
      case 'ORDER_REJECTED':
        return 'REJECTED'
      case 'ORDER_PICKED_UP':
        return 'TRANSIT'
      case 'ORDER_DELIVERED':
        return 'DELIVERED'
      case 'ORDER_CANCELLED':
        return 'CANCELLED'
      default:
        return 'CANCELLED'
    }
  }

  const statusBgSwitch = (status) => {
    switch (status) {
      case 'ORDER_CREATED':
        return '#EBF2FA'
      case 'ORDER_CONFIRMED':
        return '#DEFFEE'
      case 'ORDER_AWAITING_ASSIGNMENT':
        return '#DEFFEE'
      case 'ORDER_ASSIGNED':
        return '#E1E8FF'
      case 'ORDER_ACCEPTED':
        return '#DEFFEE'
      case 'ORDER_REJECTED':
        return '#FFE6E4'
      case 'ORDER_PICKED_UP':
        return '#FFF8E1'
      case 'ORDER_DELIVERED':
        return '#DEFFEE'
      case 'ORDER_CANCELLED':
        return '#FFE6E4'
      default:
        return '#FFE6E4'
    }
  }

  const statusTextColorSwitch = (status) => {
    switch (status) {
      case 'ORDER_CREATED':
        return '#A6B7D4'
      case 'ORDER_CONFIRMED':
        return '#66CB9F'
      case 'ORDER_AWAITING_ASSIGNMENT':
        return '#66CB9F'
      case 'ORDER_ASSIGNED':
        return '#4C6FFF'
      case 'ORDER_ACCEPTED':
        return '#66CB9F'
      case 'ORDER_REJECTED':
        return '#F16063'
      case 'ORDER_PICKED_UP':
        return '#FFD54F'
      case 'ORDER_DELIVERED':
        return '#66CB9F'
      case 'ORDER_CANCELLED':
        return '#F16063'
      default:
        return '#F16063'
    }
  }


  const renderButtons = () => {

    if (!riderAgentSingleOrder?.data?.rider && riderAgentSingleOrder?.data?.orderStatus !== 'ORDER_PICKED_UP'
      && riderAgentSingleOrder?.data?.orderStatus !== 'ORDER_CANCELLED' && riderAgentSingleOrder?.data?.orderStatus !== 'ORDER_DELIVERED') {
      return (
        <MultiButton title={'Assign to rider'} icon={false} bgColor={'#66BB6A'} onClick={openAssignModal} height={'40px'} width={'144px'} />
      )
    }
    if (riderAgentSingleOrder?.data?.orderStatus === 'ORDER_ASSIGNED' || riderAgentSingleOrder?.data?.orderStatus === 'ORDER_REJECTED' || riderAgentSingleOrder?.data?.orderStatus === 'ORDER_ACCEPTED' || riderAgentSingleOrder?.data?.orderStatus === 'ORDER_PICKED_UP') {
      return (
        <MultiButton title={'Reassign to rider'} icon={false} bgColor={'#66BB6A'} onClick={openAssignModal} height={'40px'} width={'144px'} />
      )
    }
  }


  const renderLoading = () => {
    if (gettingRiderAgentSingleOrder) {
      return (
        <FullScreenLoader />
      )
    }
  }





  return (
    <main className={'riderAgentContainer'}>
      {renderLoading()}
      <section className={'flexRow alignCenter justifyBetween miniNav flexWrap'}>
        <div className={'flexRow alignCenter'} style={{ marginBottom: '16px' }}>
          <BackComponent />
          <>
            <h3 className={'boldText f24'} style={{ margin: '0 16px 0 24px' }}>{riderAgentSingleOrder?.data?.id}</h3>
            <p style={{ backgroundColor: statusBgSwitch(riderAgentSingleOrder?.data?.orderStatus), borderRadius: '10px', margin: '0', padding: '5px 10px', color: statusTextColorSwitch(riderAgentSingleOrder?.data?.orderStatus) }}
              className={'regularText f10 textCenter'}>
              {statusTextSwitch(riderAgentSingleOrder?.data?.orderStatus)}
            </p>
          </>

        </div>
        <div className={'flexRow alignCenter flexWrap'} style={{ marginBottom: '16px', gap: '10px' }}>
          {/* {riderAgentSingleOrder?.data?.orderStatus !== 'ORDER_CANCELLED' &&
            <div style={{ marginRight: '16px' }}>
              <MultiButton title={'Cancel order'} icon={false} bgColor={'#F96868'} onClick={openCancelModal} height={'40px'} width={'128px'} />
            </div>} */}
          {riderAgentSingleOrder?.data?.orderStatus === 'ORDER_ASSIGNED' || riderAgentSingleOrder?.data?.orderStatus === 'ORDER_PICKED_UP' ?
            <MultiButton title={'Order Status'} icon={false} bgColor={'#fff'} color={'#000'} brd={'1px solid #000'} onClick={openStatusModal} height={'40px'} width={'144px'} /> : null}
          <div>
            {renderButtons()}

          </div>
        </div>
      </section>
      <section className={'orderDetailsGrid'}>

        <CardsWithHeader title={'About customer'} >
          <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
            <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery address</p>
            <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{destinationAddress}</p>
          </div>
          {recievingCustomerName && (
            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
              <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Receivers name</p>
              <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{recievingCustomerName}</p>
            </div>
          )}
          {recievingCustomerPhone && (
            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
              <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Receivers mobile number</p>
              <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{recievingCustomerPhone}</p>
            </div>
          )}
        </CardsWithHeader>
        {riderAgentSingleOrder?.data?.rider &&
          <CardsWithHeader title={'About Rider'} >
            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
              <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Fullname</p>
              <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{riderAgentSingleOrder?.data?.rider?.user?.firstName + ' ' + riderAgentSingleOrder?.data?.rider?.user?.lastName || 'No name'}</p>
            </div>
            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
              <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Mobile number</p>
              <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{riderAgentSingleOrder?.data?.rider?.user?.phoneNumber || 'No number'}</p>
            </div>

          </CardsWithHeader>}
        <CardsWithHeader title={'Item'}>
          {riderAgentSingleOrder?.data?.orderItems?.map((item, index) => {
            return (
              <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }} key={index}>
                <div>
                  <p className={'f16 boldText capitalize'}>{item?.name}</p>
                </div>
                <div>
                  <p className={'f16 boldText'} style={{ color: '#919294' }}>x{item?.quantity}</p>
                </div>
              </div>
            )
          })}
        </CardsWithHeader>

      </section>
      <RiderAgentAssignRiderModal onClose={closeAssignModal} isOpen={state.assignModal} orderId={riderAgentSingleOrder?.data?.id} />
      <RiderAgentStatusUpdateModal onClose={closeStatusModal} isOpen={state.statusModal} orderId={riderAgentSingleOrder?.data?.id} />
    </main>
  )
}

export default RiderAgentOrderDetails