import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import RiderAgentRiderMobileCard from './RiderAgentRiderMobileCard';
import { getRiderAgentRiders } from '../../redux/actions/RiderAgentActions';
import { shortenXterLength } from '../../utils/Helpers';
import Skeleton from 'react-loading-skeleton';

const RiderAgentRidersTable = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const { riderAgentRiders, gettingRiderAgentRiders } = useSelector(state => state.riderAgent);


    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getRiderAgentRiders())
    }, [])
    const meta = riderAgentRiders?.meta;
    // console.log(meta)
    const navigate = useNavigate();



    const filteredOrders = riderAgentRiders?.data?.filter(rider =>
    (rider.id.toString().includes(searchTerm) ||
        rider.userId.toString().includes(searchTerm) ||
        rider.agencyId.toString().includes(searchTerm) ||
        rider.user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rider.riderType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rider.riderOperationLocation?.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const paginatedOrders = filteredOrders?.slice((currentPage - 1) * meta?.perPage, currentPage * meta?.perPage);

    const handlePageChange = (direction) => {
        if (direction === 'next' && currentPage < meta?.totalPages) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <section className='ordersTableContainer'>
            {/* {isTabletOrMobile ? ( */}
            <div style={{ margin: '40px 0' }}>
                {gettingRiderAgentRiders ? <>

                    {[...Array(4)].map((_, index) => (
                        <div key={index}>
                            <Skeleton className='activeOrdersCard' style={{ height: '200px', borderColor: '#ccc' }} />
                        </div>
                    ))}

                </> :
                    paginatedOrders?.map(order => (
                        <RiderAgentRiderMobileCard key={order?.id} data={order} />
                    ))
                }
            </div>


            <div className="paginationContainer">
                <button
                    className="flexRow alignCenter"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange('prev')}
                >
                    <ArrowLeft2 size="16" color="#000" variant="Bold" />
                    Previous
                </button>
                <span>Page {currentPage} of {meta?.totalPages}</span>
                <button
                    className="flexRow alignCenter"
                    disabled={currentPage === meta?.totalPages}
                    onClick={() => handlePageChange('next')}
                >
                    Next
                    <ArrowRight2 size="16" color="#000" variant="Bold" />
                </button>
            </div>
        </section>
    );
};

export default RiderAgentRidersTable;
