import React from 'react'
import { formatAmount } from '../../utils/Helpers'
import { WalletCardIcon } from '../../assets/images'
import { Wallet2 } from 'iconsax-react'

const WalletCard = ({ bg, iconBg, title, amount, titleColor, amountColor,height, width }) => {
    return (
        <div className={'walletCard'} style={{ background: bg }}>
            <div>
                <p className={'regularText f14'} style={{ color: titleColor }}>{title}</p>
                <p className={'boldText f20'} style={{ color: amountColor }}>₦ {formatAmount(amount)}</p>
            </div>
            <div className='walletCardIcon' style={{ background: iconBg, height:height, width:width }}>
            <Wallet2 size="18" color="#fff"/>
            </div>
        </div>
    )
}

export default WalletCard