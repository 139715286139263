import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import VendorAuth from '../pages/auth/vendor/VendorAuth'
import Home from '../pages/customers/Home'
import LandingPage from '../pages/home/LandingPage'
import RidersLandingPage from '../pages/couriers/RidersLandingPage'
import VendorLandingPage from '../pages/vendors/VendorLandingPage'
import RestaurantsHome from '../pages/customers/RestaurantsHome'
import PlaceOrder from '../pages/customers/PlaceOrder'
import Location from '../pages/home/Location'
import VerifyPayments from '../pages/customers/VerifyPayments'
import PrivacyPage from '../pages/home/Privacy'
import TermsPage from '../pages/home/Terms'
import NotFoundPage from '../utils/404Page'
import PayStackPay from '../pages/customers/PayStackPay'
import FlutterWavePay from '../pages/customers/FlutterWavePay'
import Maas from '../pages/home/Maas'
import CustomerWallet from '../pages/customers/CustomerWallet'
import { AnimatePresence } from 'framer-motion';
import Splash from '../utils/Splash'
import RiderAgentOrders from '../pages/rider-agent/RiderAgentOrders'
import RiderAgent from '../pages/rider-agent/RiderAgent'
import RiderAgentOrderDetails from '../pages/rider-agent/RiderAgentOrderDetails'
import RiderAgentRiders from '../pages/rider-agent/RiderAgentRiders'
import RiderAgentProfile from '../pages/rider-agent/RiderAgentProfile'
import RiderAgentLogin from '../pages/rider-agent/RiderAgentLogin'
import PrivateRoute from './PrivateRoute'
import RiderAgentWallet from '../pages/rider-agent/RiderAgentWallet'
import RiderAgentPaymentInfo from '../pages/rider-agent/RiderAgentPaymentInfo'

const RoutesContainer = () => {
    const location = useLocation();

    return (
        <>
            <AnimatePresence mode='wait'>
                <Routes key={location.pathname} location={location}>
                    <Route path='/' element={<LandingPage />} />
                    <Route path='/vendors' element={<VendorLandingPage />} />
                    <Route path='/couriers' element={<RidersLandingPage />} />
                    <Route path='/vendorAuth' element={<VendorAuth />} />
                    <Route path='/home/:address?/:categoryId?' element={<Home />} />
                    <Route path='/restaurants/:id' element={<RestaurantsHome />} />
                    <Route path='/placeOrder' element={<PlaceOrder />} />
                    <Route path='/locations' element={<Location />} />
                    <Route path='/verifyPayments' element={<VerifyPayments />} />
                    <Route path='/payStack' element={<PayStackPay />} />
                    <Route path='/flutterWave' element={<FlutterWavePay />} />
                    <Route path='/privacy' element={<PrivacyPage />} />
                    <Route path='/terms' element={<TermsPage />} />
                    <Route path='/maas' element={<Maas />} />
                    <Route path='/rider_agency/login' element={<RiderAgentLogin />} />
                    <Route path='/rider_agency' element={
                        <PrivateRoute>
                            <RiderAgent />
                        </PrivateRoute>
                    }>
                        <Route index element={<Navigate to="orders" />} />
                        <Route path='orders' element={<RiderAgentOrders />} />
                        <Route path='orders/details' element={<RiderAgentOrderDetails />} />
                        <Route path='riders' element={<RiderAgentRiders />} />
                        <Route path='profile' element={<RiderAgentProfile />} />
                        <Route path='wallet' element={<RiderAgentWallet />} />
                        <Route path='paymentInfo' element={<RiderAgentPaymentInfo />} />
                    </Route>

                    <Route path='/wallet' element={<CustomerWallet />} />
                    <Route path='*' element={<NotFoundPage />} />
                </Routes>

            </AnimatePresence>
        </>
    )
}

export default RoutesContainer