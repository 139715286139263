import { UPDATE_ADDRESS } from "../redux/Types";
import { showError } from "./Toast";
import http from "./Utils";

export const RE_DIGIT = new RegExp(/^\d+$/);

export const setToken = async (data, version = 'v1') => {
    try {
        const accessToken = data.accessToken;
        const tokenKey = version === 'v2' ? 'v2AccessToken' : 'v1AccessToken';
        localStorage.setItem(tokenKey, accessToken);
    } catch (error) {
        console.log("Could not set accessToken ", error.message);
    }
}

export const shortenXterLength = (string, number = 10) => {
    if (string) {
        if (string.length <= number) {
            return string
        }
        return `${string.slice(0, number)}..`
    }
    return ''
}


export const setLocation = async (data) => {
    try {
        localStorage.setItem("location", data);
    } catch (error) {
        console.log("Could not set location ", error.message);
    }
}

export const formatAmount = (amount, maxDigit = 2) => {
    if (Number(amount) === 0 || amount) {
        return Number(amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: maxDigit,
        });
    }

    return '***';
}

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY

export const isEmpty = obj => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
};



export const items =
    [
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809518544',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809518629',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809518732',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809518827',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809913451',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809915102',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809518827',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809518544',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809915102',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809913451',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809518629',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694880586844',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809913451',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809915102',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809518732',
        'https://dado-food-store.s3.eu-central-1.amazonaws.com/1694809518827',
    ];

export const faqs = [
    {
        title: "How does the app work?",
        answer: "The app allows you to browse menus from local restaurants, place orders, and have food delivered to your doorstep. You can search for restaurants by cuisine, view their menus, and make selections."
    },
    {
        title: "Is the app available in my area?",
        answer: "Our app is currently only available in Enugu & Abuja. You can check if we deliver to your specific location by entering your address when you try to make an order."
    },
    {
        title: "How do I create an account?",
        answer: "To create an account, simply download the app, open it, and click on the 'Sign Up' or 'Create Account' button. You can simply sign up using your phone number."
    },
    {
        title: "What payment methods are accepted?",
        answer: "We accept a variety of payment methods, including credit/debit cards, bank transfers, and in app wallet. The available options may vary by location."
    },
    {
        title: "How long does delivery take?",
        answer: "Delivery times can vary depending on the restaurant's location and preparation time, order volume, and traffic conditions. Typically, deliveries take anywhere from 30 to 45 minutes."
    },
    {
        title: "Is there a minimum order requirement?",
        answer: "Minimum order requirements, if any, may vary by restaurant. You can check the specific minimum order amount for each restaurant on their menu page."
    },
    {
        title: "What if there's an issue with my order?",
        answer: "If you encounter any issues with your order, such as missing items or incorrect dishes, please contact our customer support team through the app. We'll quickly resolve the problem."
    },
    {
        title: "Do you offer discounts or promotions?",
        answer: "Yes, we regularly offer discounts, promotions, and loyalty programs to our users. Keep an eye on the app for the latest deals and offers."
    },
    {
        title: "Can I leave special instructions for my order (e.g., allergies or extra sauce)?",
        answer: "Absolutely! During the ordering process, you can add special instructions to your order to let the restaurant know about any dietary preferences, allergies, or customization requests."
    },
    {
        title: "Is my personal information secure?",
        answer: "Yes, we take your privacy and security seriously. We use advanced encryption and security measures to protect your personal information and payment details."
    },
    {
        title: "What happens if my order is delayed?",
        answer: "We strive to deliver orders on time, but in case of unexpected delays, we will keep you informed via the app. You can also contact customer support for updates."
    },
    {
        title: "Are there any delivery fees?",
        answer: "Delivery fees vary depending on the restaurant and your location. The app will display any fees before you place your order."
    }
];

export const ridersFaq = [
    {
        title: "How do I get paid?",
        answer: `Once you sign up on the Dado rider app as a official partner, an e-wallet gets created for you. You can track your earnings per order transparently and withdraw funds to any bank at your convenience. Remember, delivery fees vary by restaurant and location, but you’ll see them clearly before accepting any order.`
    },
    {
        title: "Will I work on a schedule or can I choose my own hours?",
        answer: `With Dado, you get to choose your own hours. Whenever you are ready to work at any given time, simply open the app to accept pending orders. You are your own boss as you decide your resumption and closing hours.`
    },
    {
        title: "Are there any bonuses for increasing my earnings?",
        answer: `We provide bonuses for our best couriers on a monthly basis. This is determined by the number of completed orders, and customer reviews submitted by customers served within the specified timeframe.`
    },
    {
        title: "Do I need a smartphone to deliver with Dado Food?",
        answer: `Yup, to deliver with Dado Food, a smartphone is absolutely essential, you’ll use the app to find and accept new orders, and have a smooth Dado delivery experience. Our Dado Courier app is also packed with features that make your job easier and deliveries smoother. With turn-by-turn navigation with traffic updates in real-time to avoid delays and provide accurate estimated arrival times to customers.`
    },
    {
        title: "Do you charge a commission for orders?",
        answer: `Yes, we charge a commission for all orders on our app. This is a pre-agreed profit percentage that goes to the company for bringing business to you, the rest comes wholly to you and will be reflected on your wallet as soon as you successfully complete order deliveries on the app.`
    },
    {
        title: "Must I be tech-savvy to be able to use the app?",
        answer: `Our App is super easy to use and intuitive. You can always count on our support and help with any questions or guidance.`
    },
]

export const vendorsFaq = [
    {
        title: 'How do I get paid',
        answer: `As an official vendor with Dado, an e-wallet will be automatically created for you when setting up your profile. Your e-wallet helps you to transparently track your earnings with successfully completed orders on the app. Much like your regular bank account, you’ll receive top-up messages when customers pay for your products. Needless to say, you are free to withdraw from your e-wallet to any bank of choice at any day or time.`,
    },
    {
        title: "Can I select my operating hours for my business?",
        answer: `With Dado, you get to choose your own hours. You are your own boss as you decide your opening and closing hours. Also, you dictate your food prep time, and the time it takes for the food to be ready for delivery.`
    },
    {
        title: "Do you charge a commission for orders?",
        answer: `Yes, we charge a commission for all orders processed in our app. This is a pre-agreed profit percentage that goes to the company for bringing business to you, the rest comes wholly to you and will be reflected in your wallet as soon as you successfully complete orders on the app. This does not cut into your prices for meals listed in the app.`
    },
    {
        title: "Must I be tech-savvy to be able to use the app?",
        answer: `Our App is super easy to use and intuitive. You can always count on our support and help with any questions or guidance.`
    },
]

export const getAddressDetails = async (address, setLoading, dispatch, navigate) => {
    setLoading(true);
    const obj = { address };
    try {
        const res = await http.post(`get_address_details`, obj);
        if (res) {
            const address = res?.data?.location?.givenAddress;
            const formatted_address = res?.data?.location?.formattedAddress;
            const lat = res?.data?.location?.latitude;
            const lng = res?.data?.location?.longitude;
            const city = res?.data?.location?.city;
            const country = res?.data?.location?.country;
            const state = res?.data?.location?.state;
            const addressData = { address, formatted_address, lat, lng, city, country, state };
            dispatch({ type: UPDATE_ADDRESS, payload: addressData });
            setLoading(false);
            localStorage.setItem("location", address);
            // console.log(addressData, res)
            navigate("/home", {
                state: {
                    data: { lng, lat },
                },
            });
        }
    } catch (error) {
        // console.log(error)
        setLoading(false);
    }
};



export const returnError = (e) => {
    let formErrors = null;
    formErrors = e?.map((item) => {
        if(item) {
            showError(item?.message)
        }
    });
     
};




