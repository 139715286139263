import React from 'react';

const CustomButton = ({ type, onClick, disabled, name, customStyle, title, loading, img, ...otherProps }) => {
    return (
        <button type={type} onClick={onClick} disabled={disabled} name={name} className={`button f16 semiBoldText ${customStyle}`}
            style={{ backgroundColor: disabled ? '#68E8E3' : '#04D9D1' }}  {...otherProps}
        >
            {!loading ? title : ''}
            {img && <img src={img} alt={'icon'} />}
            {loading && <span className={'loader'}></span>}
        </button>
    )
}

export default CustomButton