import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import CustomButton from '../../utils/CustomButton';
import Counter from '../../utils/Counter';
import { useDispatch, useSelector } from 'react-redux';
import { getCart } from '../../redux/actions/CustomerActions';
import { showWarning } from '../../utils/Toast';
import { PERSIST_CART } from '../../redux/Types';
import { formatAmount } from '../../utils/Helpers';

const ProductOrderModal = (props) => {

    const { onDecline, visible, showPreOrder, singleProduct, AddToCart, businessTagId, openWarningModal,
        foodOption, getOption, counter, increase, decrease, openAuthModal, business_id, product_id, product_variation_id,
        quantity,
    } = props

    const dispatch = useDispatch()
    const { cart } = useSelector(state => state.customer)
    const { loggedIn } = useSelector(state => state.auth);


    const closeModal = () => {
        onDecline()
    }

    const payload = { business_id, product_id, product_variation_id, quantity, }





    const addToCart = () => {
        if (loggedIn) {
            if (Object.entries(foodOption).length === 0) {
                showWarning('Please select an option')
            } else {
                // console.log(params)
                if (businessTagId !== cart?.data?.cart?.businessTagId && cart?.data?.cart !== null) {
                    openWarningModal()
                } else {
                    AddToCart(counter)
                    showPreOrder()
                    // setState((prevState) => ({ ...prevState, checked: false }))
                    dispatch(getCart())
                }
            }
        }
        else {
            openAuthModal()
            closeModal()
            dispatch({ type: PERSIST_CART, payload: payload })
        }

    }






    return (
        <>
            {visible &&
                <section className={'modalContainer'}>
                    <div className={'productOrderContainer'}>
                        <div className={'closeOrder'} onClick={closeModal}>
                            <AiOutlineClose />
                        </div>
                        <div className={'productOrderImage'}>
                            <img src={singleProduct?.images[0]} alt={'food'} />
                        </div>
                        <div className={'productOrderContent'}>
                            <div className={'productOrderHeader flexRow alignCenter justifyBetween pb8'}>
                                <h4 className={'boldText f18'} style={{ color: '#38383A' }}>{singleProduct?.name}</h4>
                                <p className={'boldText f18'} style={{ color: '#FFA024' }}>₦{singleProduct?.variations[0]?.price}</p>
                            </div>
                            <div>
                                <p className={'regularText f16 pb16'} style={{ color: ' #696A6B' }}>{singleProduct?.description}</p>
                            </div>
                            <div>
                                <div>
                                    <p className={'boldText f14 '} style={{ color: '#38383A' }}>Option 1</p>
                                    <p className={'regularText f14 pb16'} style={{ color: ' #696A6B' }}>Choose at least one</p>
                                </div>

                                {singleProduct?.variations?.map((item, i) => {
                                    return (
                                        <div className={'flexRow alignCenter justifyBetween pb16'} key={i} onClick={() => getOption(item, i)}>
                                            <div className={'semiBoldText f14 flexRow alignCenter'} style={{ color: '#38383A' }}>
                                                <div className={'radioBox'} style={{ marginRight: '5px' }} >
                                                    <span className={'radioBoxInner'} style={{ backgroundColor: foodOption[i] ? '#FFA024' : '' }}></span>
                                                </div>
                                                <span className={'regularText f14'} style={{ cursor: 'pointer' }}>{item.name}</span>
                                            </div>
                                            <div>
                                                <p className={'semiBoldText f14'} style={{ color: '#B1B2B4' }}>₦{formatAmount(item.price)}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginTop: '42px' }}>
                                <div style={{ width: '30%' }}>
                                    <Counter decrease={decrease} increase={increase} counter={counter} mr={'16px'}
                                        controlsColor={'#FFA024'} counterColor={'#FFA024'} bgColor={'rgba(255, 144, 0, 0.16)'}
                                    />
                                </div>

                                <div style={{ width: '70%' }} >
                                    <CustomButton title={'Add to cart'} onClick={addToCart} />
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )

}

export default ProductOrderModal;