import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRiderAgentWallet } from '../../redux/actions/RiderAgentActions';
import WalletCard from '../../components/customers/WalletCard';
import http from '../../utils/Utils';
import CustomButton from '../../utils/CustomButton';
import RiderAgentPinModal from '../../components/rider-agent/RiderAgentPinModal';
import RiderAgentWithdrawalModal from '../../components/rider-agent/RiderAgentWithdrawalModal';
import { showError, showSuccess } from '../../utils/Toast';
import { formatAmount, returnError } from '../../utils/Helpers';
import { MoneyRecive, MoneySend, Receipt1 } from 'iconsax-react';
import moment from 'moment';

const RiderAgentWallet = () => {
    const { riderAgentWallet } = useSelector((state) => state.riderAgent);
    const [state, setState] = useState({
        loading: false,
        amount: '',
        payment_info_id: null,
        wallet_pin: '',
        pinModal: false,
        withdrawalModal: false,
        filter: 'ALL',
        currentPage: 1,
        itemsPerPage: 12,
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRiderAgentWallet());
    }, [dispatch]);

    const openPinModal = () => {
        setState((prevState) => ({ ...prevState, pinModal: true }));
    };

    const closePinModal = () => {
        setState((prevState) => ({ ...prevState, pinModal: false }));
    };

    const openWithdrawModal = () => {
        if (riderAgentWallet?.wallet?.walletPinActivated) {
            setState((prevState) => ({ ...prevState, withdrawalModal: true }));
        } else {
            openPinModal();
        }
    };

    const closeWithdrawModal = () => {
        setState((prevState) => ({ ...prevState, withdrawalModal: false }));
    };

    const handleChange = (selectedOption) => {
        setState((prevState) => ({ ...prevState, payment_info_id: selectedOption?.value }));
    };

    const onChangeAmount = (e) => {
        setState((prevState) => ({ ...prevState, amount: e.target.value }));
    };

    const onChangePin = (e) => {
        const value = e.target.value;
        const finalPin = value.replace(/[^0-9]/g, '');

        setState((prevState) => ({
            ...prevState,
            wallet_pin: finalPin,
        }));

        if (finalPin.length !== value.length) {
            showError('Please enter numbers only');
        }
    };

    const withdraw = async () => {
        const { amount, payment_info_id, wallet_pin } = state;
        const obj = { amount, payment_info_id, wallet_pin };
        if (riderAgentWallet?.wallet?.walletBalance > Number(amount)) {
            setState((prevState) => ({ ...prevState, loading: true }));
            try {
                const res = await http.post('rider_agency/wallet/withdraw', obj, true, {}, 'v2');
                showSuccess(res?.data?.message);
                closeWithdrawModal();
                setState((prevState) => ({ ...prevState, loading: false }));
            } catch (error) {
                returnError(error);
                setState((prevState) => ({ ...prevState, loading: false }));
            }
        } else {
            showError('Insufficient balance');
        }
    };

    const setPin = async () => {
        setState((prevState) => ({ ...prevState, loading: true }));
        const { wallet_pin } = state;
        const obj = { wallet_pin };
        try {
            const res = await http.post('rider_agency/wallet/create_wallet_pin', obj, true, {}, 'v2');
            setState((prevState) => ({ ...prevState, loading: false }));
            showSuccess(res.message);
            dispatch(getRiderAgentWallet());
            openWithdrawModal();
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const handleFilterChange = (filter) => {
        setState((prevState) => ({ ...prevState, filter, currentPage: 1 }));
    };

    const handlePageChange = (page) => {
        setState((prevState) => ({ ...prevState, currentPage: page }));
    };

    const renderEmpty = () => {
        if (riderAgentWallet?.wallet?.history?.transactions?.length === 0) {
            return (
                <div style={{ marginTop: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Receipt1 size="32" color="#000" />
                    <h3 style={{ margin: '10px 0 40px 0' }}>No Transaction History</h3>
                </div>
            );
        }
    };

    const filteredTransactions = riderAgentWallet?.wallet?.history?.transactions.filter((item) => {
        if (state.filter === 'ALL') return true;
        return state.filter === item.transactionType;
    });

    const totalItems = filteredTransactions.length;
    const totalPages = Math.ceil(totalItems / state.itemsPerPage);
    const currentItems = filteredTransactions.slice(
        (state.currentPage - 1) * state.itemsPerPage,
        state.currentPage * state.itemsPerPage
    );
    const renderTransactionType = (value) => {
        if (value === 'DEBIT') {
            return (
                <MoneySend size="32" color="#D82C0D" />
            )
        }
        else if (value === 'CREDIT') {
            return (
                <MoneyRecive size="32" color="#008060" />
            )
        }
    }

    const returnStatusColor = (value) => {
        if (value === 'FAILED') {
            return { textColor: "#D82C0D", bgColor: "#FFF4F4" }
        }
        else if (value === 'SUCCESSFULL') {
            return { textColor: "#008060", bgColor: "#F1F8F5" }
        }
        else {
            return { textColor: "#008060", bgColor: "#F1F8F5" }
        }
    }

    const renderWalletHistory = () => {
        if (filteredTransactions.length === 0) {
            return renderEmpty();
        } else {
            return (
                <div style={{ marginTop: '0px', display: 'flex', flexDirection: 'column' }}>
                    <ul>
                        {currentItems.map((item, index) => (
                            <li key={index} className={'walletListItem'} style={{ borderBottom: '1px solid #DBE0E5' }}>
                                <div className={'flexRow alignCenter'} style={{ gap: '10px' }}>
                                    {renderTransactionType(item?.transactionType)}
                                    <div style={{ marginTop: '8px' }}>
                                        <p className={'boldText f14'} style={{ color: '#000' }}>₦{formatAmount(item?.amount)}</p>
                                        <p className={'regularText f14'} style={{ color: '#425466' }}>{item?.description}</p>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ padding: '5px 0', borderRadius: '8px', backgroundColor: returnStatusColor(item?.transactionStatus).bgColor, textAlign: 'center', marginBottom: '8px' }}>
                                        <p className={'f10 regularText'} style={{ color: returnStatusColor(item?.transactionStatus).textColor }}>{item?.transactionStatus}</p>
                                    </div>
                                    <p className={'regularText f14'} style={{ color: '#425466' }}>{moment(item?.updatedAt).format('YYYY-MM-DD hh:mm')}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                    {totalPages > 1 && (
                        <div className="pagination">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={state.currentPage === index + 1 ? 'active' : ''}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            );
        }
    };

    return (
        <main className={'riderAgentContainer'}>
            <WalletCard amount={riderAgentWallet?.wallet?.walletBalance} iconBg={'rgba(104, 232, 227, 1)'} title={'Wallet Balance'} height={'40px'} width={'40px'} />

            <div className={'flexRow alignCenter justifyBetween'} style={{ maxWidth: '100px', padding: '20px 0' }}>
                <CustomButton title={'Withdraw'} onClick={openWithdrawModal} />
            </div>

            <div className={'walletHistoryContainer'}>
                <h3>Wallet history</h3>
                <div className={'ordersTableTabContainer'} style={{ margin: '20px 0' }}>
                    <button onClick={() => handleFilterChange('ALL')} className={state.filter === 'ALL' ? 'active' : ''}>All</button>
                    <button onClick={() => handleFilterChange('DEBIT')} className={state.filter === 'DEBIT' ? 'active' : ''}>Debit</button>
                    <button onClick={() => handleFilterChange('CREDIT')} className={state.filter === 'CREDIT' ? 'active' : ''}>Credit</button>
                </div>
                {renderWalletHistory()}
            </div>
            <RiderAgentPinModal onClose={closePinModal} isOpen={state.pinModal} onChange={onChangePin} pin={state.wallet_pin} setPin={setPin} loading={state.loading} />
            <RiderAgentWithdrawalModal onClose={closeWithdrawModal} isOpen={state.withdrawalModal} handleChange={handleChange} onChangeAmount={onChangeAmount} amount={state.amount} wallet_pin={state.wallet_pin} onChangePin={onChangePin}
                loading={state.loading} withdraw={withdraw}
            />
        </main>
    );
};

export default RiderAgentWallet;
