import React, { useState } from "react";
import ServicesCard from "./ServicesCard";
import { CustomerMock, RiderMock, VendorMock } from "../../assets/images";
import { useMediaQuery } from "react-responsive";
import { showWarning } from "../../utils/Toast";
import mixpanel from "mixpanel-browser";
import { trackEvent } from "../../utils/Mixpanel";


const ServicesSection = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const customerIosStore = () => {
    window.open('https://apps.apple.com/us/app/dado-food/id6444361467', '_blank', 'noreferrer');
    trackEvent('clicks_download_app', {
      appType:'customer-app',
      version:'ios',
      location: 'services-card'
    })
  }
  const customerPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.dado.ng', '_blank', 'noreferrer');
    trackEvent('clicks_download_app', {
      appType:'customer-app',
      version:'android',
      location: 'services-card'
    })
  }

  const riderIosStore = () => {
    window.open('https://apps.apple.com/ng/app/dado-rider/id6448981515', '_blank', 'noreferrer');
    trackEvent('clicks_download_app', {
      appType:'rider-app',
      version:'ios',
      location: 'services-card'
    })
  }
  const riderPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=rider.dado.ng', '_blank', 'noreferrer');
    trackEvent('clicks_download_app', {
      appType:'rider-app',
      version:'android',
      location: 'services-card'
    })
  }


  const vendorIosStore = () => {
    // window.open('https://apps.apple.com/us/app/dado-food/id6444361467', '_blank', 'noreferrer');
    showWarning('Currently unavailable')
    trackEvent('clicks_download_app', {
      appType:'vendor-app',
      version:'ios',
      location: 'services-card'
    })
  }
  const vendorPlayStore = () => {
    // window.open('https://play.google.com/store/apps/details?id=com.dado.ng', '_blank', 'noreferrer');
    showWarning('Currently unavailable')
    trackEvent('clicks_download_app', {
      appType:'vendor-app',
      version:'android',
      location: 'services-card'
    })

  }
  return (
    <section className={"servicesContainer"}>
      <header>
        <h4 className={`semiBoldText ${isDesktopOrLaptop ? 'f20' : 'f16'}`} style={{ color: '#FFA024', marginBottom: '24px' }}>OUR SUITE</h4>
        <h2 className={`boldText ${isDesktopOrLaptop ? 'f38' : 'f20'}`} >Explore Our Offerings </h2>
        <p className={`lightText ${isDesktopOrLaptop ? 'f20' : 'f16'}`} style={{ paddingRight: isDesktopOrLaptop ? '100px' : '0', }}>
        Experience the delight of Dado, the ultimate food delivery platform for everyone. Craving something delicious? Use our app to get a wide range of cuisines,
         from African to Continental, delivered in minutes. Couriers, join our flexible network with the rider app and start earning on your schedule. Restaurants and chefs, 
        grow your business with our vendors app, making it easy to manage orders and reach more customers. With Dado, everyone’s cravings are just a tap away.
        </p>
      </header>

      <div className={"servicesGrid"}>
        <ServicesCard title={'Order now'} src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710339352674'} description={`Discover the easiest way to satisfy your cravings – simply order from a variety of cuisines with a quick tap on our app. Experience the convenience firsthand. Download now for a taste-filled adventure at your fingertips.`}
          iosClick={customerIosStore} playClick={customerPlayStore}
        />
        <ServicesCard title={'Manage orders'} src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340868302'} description={`Effortlessly streamline your food business operations with our user-friendly platform. Manage orders efficiently, track deliveries seamlessly, and enhance your customer service, all in one place.`}
          iosClick={vendorIosStore} playClick={vendorPlayStore}
        />
        <ServicesCard title={'Ride & Earn'} src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340834143'} description={`Turn your time into earnings by joining our dynamic delivery team.Experience the freedom of flexible hours and the joy of being your own boss.`}
          iosClick={riderIosStore} playClick={riderPlayStore}
        />

      </div>
    </section>
  );
};

export default ServicesSection;
