import mixpanel from 'mixpanel-browser';
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { trackEvent } from '../../../utils/Mixpanel';


const AboutUsFooterContainer = () => {


    const customerIosStore = () => {
        window.open('https://apps.apple.com/us/app/dado-food/id6444361467', '_blank', 'noreferrer');
        trackEvent('clicks_download_app', {
          appType:'customer-app',
          version:'ios',
          location: 'about-section'
        })
      }
      const customerPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.dado.ng', '_blank', 'noreferrer');
        trackEvent('clicks_download_app', {
          appType:'customer-app',
          version:'android',
          location: 'about-section'
        })
      }

    return (
        <footer className={'aboutUsFooterContainer'}>
            <h4 style={{ color: 'rgba(8, 79, 97, 1)', }}>We spread happiness and a smile, one meal at a time!</h4>
            <div className={'flexRow alignCenter'} style={{ gap: '20px', justifyContent: 'center', marginTop: '24px' }}>
                <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710284682999'} alt='appStore'  onClick={customerIosStore}/>
                <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340589868'} alt='playStore' onClick={customerPlayStore}/>

            </div>
        </footer>
    )
}

export default AboutUsFooterContainer