import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { DeliveryBottomImage } from "../../../assets/images";
import CustomInput from "../../../utils/CustomInput";
import CustomButton from "../../../utils/CustomButton";
const SignIn = (props) => {
    const { onDecline, requestOTP, loading } = props;

    const [state, setState] = useState({
        country_code: "234",
        phone: "",
    });
    const { country_code, phone } = state;

    const onChangePhone = (e) => {
        setState({ ...state, phone: e.target.value });
    };

    const disabled = loading || !state.phone
    return (
        <>
            <section className={"modalContainer"}>
                <div className={"modalContentContainer  animate__animated animate__slideInUp"}>
                    <div className={"modalCloseBtn"} onClick={onDecline}>
                        <AiOutlineClose size={20} />
                    </div>
                    <div>
                        <h4 className={"boldText textCenter"}>Get started</h4>
                        <p className={"regularText textCenter"}>To continue enter your mobile number and enjoy </p>
                    </div>
                    <div>
                        <CustomInput label={"Mobile number*"} placeholder={"Enter mobile number "} type={"tel"} onChange={onChangePhone} />
                    </div>
                    <div>
                        <CustomButton title={"Continue"} onClick={() => requestOTP(country_code, phone)} loading={loading} disabled={disabled} />
                    </div>

                    <div className={"modalBottomImage"}>
                        <img src={DeliveryBottomImage} alt="borderImage" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default SignIn;
