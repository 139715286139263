export const LOADING_USER = "loading_user";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAIL = "login_fail";

export const GETTING_OTP = 'getting_otp';
export const GETTING_OTP_SUCCESSFUL = 'getting_otp_successful';
export const GETTING_OTP_FAIL = 'getting_otp_fail';


export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESSFUL = 'signout_user_successful';
export const SIGNOUT_USER_FAIL = 'signout_user_fail';


// Search 
export const GET_LOCATIONS = "get_locations";
export const GET_LOCATIONS_SUCCESSFUL = "get_locations_successful";
export const GET_LOCATIONS_FAIL = "get_locations_fail";


export const UPDATE_COORDINATES = 'update_coordinates';
export const UPDATE_COURIER_FEE = 'update_courier_fee';
export const UPDATE_TXF_REF = 'update_txf_ref';
export const STORE_ADDRESS = 'store_address';
export const UPDATE_ADDRESS = 'update_address';
export const UPDATE_F_ADDRESS = 'update_f_address';
export const UPDATE_ROUTE = 'update_route';


// export const GET_


export const LOADING_VENDORS = 'loading_vendors';
export const VENDORS_SUCCESSFUL = 'vendors_successful';
export const VENDORS_FAIL = 'vendors_fail';


export const GETTING_ALL_RESTAURANTS = 'getting_all_restaurants';
export const GETTING_ALL_RESTAURANTS_SUCCESSFUL = 'getting_all_restaurants_successful';
export const GETTING_ALL_RESTAURANTS_FAIL = 'getting_all_restaurants_fail';


export const GETTING_RESTAURANT = 'getting_restaurant';
export const GETTING_RESTAURANT_SUCCESSFUL = 'getting_restaurant_successful';
export const GETTING_RESTAURANT_FAIL = 'getting_restaurant_fail';


export const GETTING_RESTAURANTS_BY_CATEGORIES = 'getting_restaurants_by_categories';
export const GETTING_RESTAURANTS_BY_CATEGORIES_SUCCESSFUL = 'getting_restaurants_by_categories_successful';
export const GETTING_RESTAURANTS_BY_CATEGORIES_FAIL = 'getting_restaurants_by_categories_fail';



export const GETTING_RESTAURANTS_PRODUCT = 'getting_restaurants_product';
export const GETTING_RESTAURANTS_PRODUCT_SUCCESSFUL = 'getting_restaurants_product_successful';
export const GETTING_RESTAURANTS_PRODUCT_FAIL = 'getting_restaurants_product_fail';

export const GETTING_CATEGORIES = 'getting_categories';
export const GETTING_CATEGORIES_SUCCESSFUL = 'getting_categories_successful';
export const GETTING_CATEGORIES_FAIL = 'getting_categories_fail';

export const GETTING_CART = 'getting_cart';
export const GETTING_CART_SUCCESSFUL = 'getting_cart_successful';
export const GETTING_CART_FAIL = 'getting_cart_fail';

export const ADDING_TO_CART = 'adding_to_cart';
export const ADDING_TO_CART_SUCCESSFUL = 'adding_to_cart_successful';
export const ADDING_TO_CART_FAIL = 'adding_to_cart_fail';
export const PERSIST_CART = 'persist_cart';


export const REMOVING_FROM_CART = 'removing_from_cart';
export const REMOVING_FROM_CART_SUCCESSFUL = 'removing_from_cart_successful';
export const REMOVING_FROM_CART_FAIL = 'removing_from_cart_fail';

export const DELETING_FROM_CART = 'deleting_from_cart';
export const DELETING_FROM_CART_SUCCESSFUL = 'deleting_from_cart_successful';
export const DELETING_FROM_CART_FAIL = 'deleting_from_cart_fail';


export const GETTING_ADDRESSES = 'getting_addresses';
export const GETTING_ADDRESSES_SUCCESSFUL = 'getting_addresses_successful';
export const GETTING_ADDRESSES_FAIL = 'getting_addresses_fail';


export const GETTING_ORDERS = 'getting_orders';
export const GETTING_ORDERS_SUCCESSFUL = 'getting_orders_successful';
export const GETTING_ORDERS_FAIL = 'getting_orders_fail';

export const GETTING_ORDERS_STATUS = 'getting_orders_status';
export const GETTING_ORDERS_STATUS_SUCCESSFUL = 'getting_orders_status_successful';
export const GETTING_ORDERS_STATUS_FAIL = 'getting_orders_status_fail';

export const GET_TRENDING = 'get_trending';
export const GET_TRENDING_SUCCESSFUL = 'get_trending_successful';
export const GET_TRENDING_FAIL = 'get_trending_fail';


export const SEARCH_QUERY = 'search_query';
export const CLEAR_QUERY = 'clear_query';


export const UPLOAD_ADDRESS = 'upload_address';
export const UPLOAD_ADDRESS_SUCCESSFUL = 'upload_address_successful';
export const UPLOAD_ADDRESS_FAIL = 'upload_address_fail';


export const GET_WORKING_TIME = 'get_working_time';
export const WORKING_TIME = 'working_time';


export const GETTING_WALLET = 'getting_wallet';
export const WALLET = 'wallet';
export const WALLET_FAIL = 'wallet_fail';





// Rider Agent Types



export const LOADING_RIDER_AGENT_USER = "loading_rider_agent_user";
export const RIDER_AGENT_LOGIN_SUCCESS = "rider_agent_login_success";
export const RIDER_AGENT_LOGIN_FAIL = "rider_agent_login_fail";



export const SIGNOUT_RIDER_AGENT = 'signout_rider_agent';
export const SIGNOUT_RIDER_AGENT_SUCCESSFUL = 'signout_rider_agent_successful';
export const SIGNOUT_RIDER_AGENT_FAIL = 'signout_agent_fail';

export const GETTING_RIDER_AGENT_OTP = 'getting_rider_agent_otp';
export const GETTING_RIDER_AGENT_OTP_SUCCESSFUL = 'getting_rider_agent_otp_successful';
export const GETTING_RIDER_AGENT_OTP_FAIL = 'getting_rider_agent_otp_fail';


export const GETTING_RIDER_AGENT_ORDERS = 'getting_rider_agent_orders';
export const RIDER_AGENT_ORDERS_SUCCESSFUL = 'rider_agent_orders_successful';
export const RIDER_AGENT_ORDERS_FAIL = 'rider_agent_orders_fail';



export const GETTING_RIDER_AGENT_SINGLE_ORDER = 'getting_rider_agent_single_order';
export const RIDER_AGENT_SINGLE_ORDER_SUCCESSFUL = 'rider_agent_single_order_successful';
export const RIDER_AGENT_SINGLE_ORDER_FAIL = 'rider_agent_single_order_fail';




export const GETTING_RIDER_AGENT_RIDERS = 'getting_rider_agent_riders';
export const RIDER_AGENT_RIDERS_SUCCESSFUL = 'rider_agent_riders_successful';
export const RIDER_AGENT_RIDERS_FAIL = 'rider_agent_riders_fail';



export const GETTING_RIDER_AGENT_PROFILE = 'getting_rider_agent_profile';
export const RIDER_AGENT_PROFILE_SUCCESSFUL = 'rider_agent_profile_successful';
export const RIDER_AGENT_PROFILE_FAIL = 'rider_agent_profile_fail';



export const GETTING_RIDER_AGENT_WALLET = 'getting_rider_agent_wallet';
export const RIDER_AGENT_WALLET_SUCCESSFUL = 'rider_agent_wallet_successful';
export const RIDER_AGENT_WALLET_FAIL = 'rider_agent_wallet_fail';




export const GETTING_RIDER_AGENT_PAYMENT_INFO = 'getting_rider_agent_payment_info';
export const RIDER_AGENT_PAYMENT_INFO_SUCCESSFUL = 'rider_agent_payment_info_successful';
export const RIDER_AGENT_PAYMENT_INFO_FAIL = 'rider_agent_payment_info_fail';


export const GETTING_BANKS = 'getting_banks';
export const BANKS_SUCCESSFUL = 'banks_fail';
export const BANKS_FAIL = 'banks_fail';





// list_of_nigeria_banks