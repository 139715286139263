import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { getAddressDetails } from '../utils/Helpers';


const NewsLetterComponent = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userAddress, setAddress] = useState(null);


    useEffect(() => {
        if (userAddress) {
            const address = userAddress?.label
            getAddressDetails(address, dispatch, navigate)
        }
    }, [userAddress, dispatch, navigate])


    const returnPlaceHolder = () => {
        return (
            <p className={"regularText f14 flexRow alignCenter"} style={{ gap: "8px", justifyContent: 'center', }}>
                Enter your city & view vendors
            </p>
        )
    }


    return (
        <>
            <section className={`newsLetterContainer alignCenter flexRow`}>
                <div style={{ width: isDesktopOrLaptop ? '515px' : '100%', textAlign: isDesktopOrLaptop ? 'left' : 'center' }}>
                    <h2 className={'f20 mediumText'} style={{ color: 'rgba(4, 217, 209, 1)', marginBottom: '8px' }}>Want to Place an order?</h2>
                    <p className={'f16 regularText'} style={{ color: 'rgba(181, 202, 208, 1)', marginBottom: '20px' }}>Enter your address and order online.</p>
                </div>
                <div style={{ width: isDesktopOrLaptop ? '515px' : '100%', gap: '10px', justifyContent: 'flex-end', }} className='flexRow'>
                    <div style={{ width: isDesktopOrLaptop ? '346px' : '100%' }}>
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            selectProps={{
                                userAddress,
                                onChange: setAddress,
                                styles: {
                                    control: (provided) => ({
                                        ...provided,
                                        padding: isDesktopOrLaptop ? '8px 16px' : '5px 16px',
                                        background: ' #fff',
                                        borderRadius: '8px',
                                        border: ' 0.5px #919294 solid',
                                        width: '100%',
                                        textAlign: 'center'
                                        // zIndex: '30'
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        color: " #999CA0",
                                        fontFamily: "Nunito",
                                        fontSize: "16px",
                                        width: "90%"

                                    }),
                                },
                                placeholder: returnPlaceHolder(),
                            }}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: ["ng"],
                                },
                            }}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewsLetterComponent