import http from "../../utils/Utils"
import { GET_LOCATIONS, GET_LOCATIONS_FAIL, GET_LOCATIONS_SUCCESSFUL, UPLOAD_ADDRESS, UPLOAD_ADDRESS_FAIL, UPLOAD_ADDRESS_SUCCESSFUL } from "../Types"

export const getLocations = () => {
    return dispatch => {
        dispatch({ type: GET_LOCATIONS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get('operation_locations')
                const data = res
                // console.log('locations', res)
                dispatch({ type: GET_LOCATIONS_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                dispatch({ type: GET_LOCATIONS_FAIL, payload: error });
                reject(error);
            }
        })
    }
}



export const storeAddress = (obj) => {
    return dispatch => {
        dispatch({ type: UPLOAD_ADDRESS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post('auth/customer/profile/address/add', obj)
                const data = res
                // console.log('upload address', res)
                dispatch({ type: UPLOAD_ADDRESS_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log('upload address', error)
                dispatch({ type: UPLOAD_ADDRESS_FAIL, payload: error });
                reject(error);
            }
        })
    }
}


// const storeAddress = async () => {
//     const obj = {
//         name_of_address: 'address',
//         country_of_address: country,
//         state_of_address: states,
//         city_of_address: city,
//         address: address
//     }
//     console.log(obj)
//     try {
//         const res = await http.post(`/auth/customer/profile/address/add`, obj);
//         console.log('save address', res)
//         setState({ ...state, phoneVerificationModal: false })
//         dispatch(getAddresses())
//     } catch (error) {
//         console.log(error);
//     }
// }

