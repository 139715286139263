import React from 'react';
import './utils.css';

const CounterLoader = () => {
    return (
        <div class="loadingio-spinner-rolling-qknyt5f6jt"><div class="ldio-68bts848xrf">
            <div></div>
        </div></div>
    )
}

export default CounterLoader