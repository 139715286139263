import React from 'react'
import { useMediaQuery } from 'react-responsive'

const AboutUsTextContainer = () => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    return (
        <section>
            <article className={'aboutUsTextContainer'}>
                <h3 style={{ fontSize: isDesktopOrLaptop ? '40px' : '24px', marginBottom: isDesktopOrLaptop ? '24px' : '16px' }}>We are <span style={{ color: '#04D9D1' }}>Dado!</span></h3>
                <p className='lightText' style={{ fontSize: isDesktopOrLaptop ? '20px' : '14px', color: '#063F4E' }}>
                    <b className='mediumText' style={{ color: '#063F4E' }}>The Essence of Dado:</b>{' '}
                    Welcome to Dado, the platform that seamlessly connects customers, vendors, and riders.
                    Our journey is driven by a passion for solving problems and tackling challenges others deem impossible.
                    At Dado, we’re on a mission to revolutionize the food delivery experience.
                </p>
                <p className='lightText' style={{ fontSize: isDesktopOrLaptop ? '20px' : '14px', color: '#063F4E' }}>
                {' '}Experience the joy of having a diverse range of restaurant cuisines, from African to Continental,
                    delivered to your doorstep in minutes. Whether you’re a foodie craving delicious meals, a courier seeking flexible earning opportunities,
                    or a chef looking to expand your reach, Dado is here to fulfil your needs.

                </p>
                <p className='lightText' style={{ fontSize: isDesktopOrLaptop ? '20px' : '14px', color: '#063F4E' }}>
                    {' '}We’re missionaries, not mercenaries. Our focus is on creating a positive impact on the world, prioritizing users over profits.
                    We are user-centric, always starting from the customer’s needs and working backward to find the best solutions.
                </p>
                <p className='lightText' style={{ fontSize: isDesktopOrLaptop ? '20px' : '14px', color: '#063F4E' }}>
                {' '}Our values guide us, shaping our identity and defining our essence. Join us on this journey and experience the Dado difference.
                </p>
                <p className='lightText' style={{ fontSize: isDesktopOrLaptop ? '20px' : '14px', color: '#063F4E' }}>
                {'  '}Explore Dado today and discover a world of flavors, convenience, and community.
                </p>
            </article>
        </section>
    )
}

export default AboutUsTextContainer
