import React from 'react';
import '../../assets/styles/modalStyles.css';
import { AiOutlineClose } from 'react-icons/ai';
import { DeliveryBottomImage, } from '../../assets/images';
// import { useSelector } from 'react-redux';
import CustomButton from '../../utils/CustomButton';


const WarningModal = (props) => {

    // const { locations } = useSelector(state => state.search)
    const { onDecline, updateCart, } = props

    return (
        <>
            {props.visible &&
                <section className={'modalContainer'} >
                    <div className={'modalContentContainer animate__animated animate__slideInUp'} >
                        <div className={'modalCloseBtn'} onClick={onDecline}>
                            <AiOutlineClose size={20} />
                        </div>
                        <div>
                            <h4 className={'boldText textCenter'}>Restaurant switch</h4>
                            <p className={'regularText textCenter'}>Are you sure you want to switch to another restaurant? This action will clear your cart</p>
                        </div>
                        <div className={'flexRow alignCenter justifyCenter'} style={{ gap: '16px' }}>

                            <div className={'warningModalBtn'}>
                                <CustomButton title={'Cancel'} customStyle={'cancelButton'} onClick={onDecline} />
                            </div>

                            <div className={'warningModalBtn'}>
                                <CustomButton title={'Yes, continue'} onClick={updateCart} />
                            </div>
                        </div>
                        <div className={'modalBottomImage'}>
                            <img src={DeliveryBottomImage} alt='borderImage' />
                        </div>
                    </div>
                </section>
            }
        </>
    )

}

export default WarningModal;