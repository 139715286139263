import React, { useState } from 'react'
import http from '../../../utils/Utils'
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { useMediaQuery } from 'react-responsive';
import { showSuccess } from '../../../utils/Toast';
import { NewsImage, Socials, TopLeft, TopRight, btLeft, btRight } from '../../../assets/images';
import AboutUs3dGrid from './AboutUs3dGrid';
import ImageWithPreloader from '../../../utils/CustomImage';
import { trackEvent } from '../../../utils/Mixpanel';


const AboutUsContactContainer = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const [state, setState] = useState({
        email: "", loading: false
    })

    const onChangeEmail = (e) => {
        setState({ ...state, email: e.target.value })
    }


    const disable = () => {
        if (state.email === '') {
            return true
        }
        return false
    }

    // comment
    const subscribe = async () => {
        setState({ ...state, loading: true, })
        const { email } = state
        const obj = { email }
        try {
            const res = await http.post(`newsletter/subscribe`, obj)
            // console.log('subscribe', res)
            showSuccess(res.message)
            setState({ ...state, loading: false })
            trackEvent('news_letter_signup_successful', {
                email: email
            })
        } catch (error) {
            // console.log('subscribe', error)
            setState({ ...state, loading: false })
            trackEvent('news_letter_signup_fail', {
                email: email,
                error: error
            })
        }
    }

    return (
        <section className={'aboutUsContactContainer'}>
            <header >
                <h3 style={{ fontSize: isDesktopOrLaptop ? '40px' : '32px', lineHeight: '35px', marginBottom: '16px' }}>
                    Get on the <span style={{ color: 'rgba(244, 116, 59, 1)' }}>success path</span>, Be part of the action.
                </h3>
                <p style={{ fontSize: isDesktopOrLaptop ? '20px' : '16px', color: '#565758', }}>Join our fast growing community and share  integral contributions to our journey</p>
            </header>
            <div className={'aboutUsContactGrid'}>


                <div className="item item2">
                    <ImageWithPreloader src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710267741667'} alt="Image 2" />

                    <div className="content">
                        <header>
                            <h3 className={`boldText  textCenter ${isDesktopOrLaptop ? 'f24' : 'f20'}`}>Let us connect</h3>
                            <p className={`textCenter lightText ${isDesktopOrLaptop ? 'f20' : 'f16'} `}>Come along for the ride!</p>
                        </header>
                        <ul className="flexRow alignCenter justifyCenter" style={{ gap: "16px", marginBottom: '20px' }}>
                            <li className="communitySocialIconContainer">
                                <a href='https://twitter.com/dadofood'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsTwitter />
                                </a>
                            </li>
                            <li className="communitySocialIconContainer">
                                <a href='https://instagram.com/dadofoodng?igshid=NDc0ODY0MjQ='
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsInstagram />
                                </a>
                            </li>
                            <li className="communitySocialIconContainer">
                                <a href='https://m.facebook.com/dadofoods'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsFacebook />
                                </a>
                            </li>
                            <li className="communitySocialIconContainer">
                                <a href='https://ng.linkedin.com/company/dadochops'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsLinkedin />
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="item item3">
                    <ImageWithPreloader src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710284751905'} alt="Image 3" />
                    <div className="content">
                        <header>
                            <h3 className={`boldText  textCenter ${isDesktopOrLaptop ? 'f24' : 'f20'}`}>Stay updated & Informed</h3>
                            <p className={`textCenter lightText ${isDesktopOrLaptop ? 'f20' : 'f16'} `}>Newsletter</p>

                        </header>
                        <div className={"communityForm"}>
                            <input placeholder="E-mail address" onChange={onChangeEmail} value={state.email} />
                            <button onClick={subscribe}>
                                Subscribe
                            </button>
                        </div>
                    </div>
                </div>

            </div>





        </section >
    )
}

export default AboutUsContactContainer