import moment from 'moment';
import React from 'react'
import { useNavigate } from 'react-router-dom';


const RiderAgentMobileCard = (props) => {

  const { data } = props
  // console.log(data)
  const navigate = useNavigate()
  const getSingleOrder = (val) => {
    navigate('/rider_agency/orders/details', {
      state: {
        data: val,
      }
    });
  };

  // 20 5months 

  const statusTextSwitch = (status) => {
    switch (status) {
      case 'ORDER_CREATED':
        return 'PENDING'
      case 'ORDER_CONFIRMED':
        return 'CONFIRMED'
      case 'ORDER_AWAITING_ASSIGNMENT':
        return 'AWAITING ASSIGNMENT'
      case 'ORDER_ASSIGNED':
        return 'ASSIGNED'
      case 'ORDER_ACCEPTED':
        return 'ACCEPTED'
      case 'ORDER_REJECTED':
        return 'REJECTED'
      case 'ORDER_PICKED_UP':
        return 'TRANSIT'
      case 'ORDER_DELIVERED':
        return 'DELIVERED'
      default:
        return 'CANCELLED'
    }
  }

  const statusBgSwitch = (status) => {
    switch (status) {
      case 'ORDER_CREATED':
        return '#EBF2FA'
      case 'ORDER_CONFIRMED':
        return '#DEFFEE'
      case 'ORDER_AWAITING_ASSIGNMENT':
        return '#DEFFEE'
      case 'ORDER_ASSIGNED':
        return '#E1E8FF'
      case 'ORDER_ACCEPTED':
        return '#DEFFEE'
      case 'ORDER_REJECTED':
        return '#FFE6E4'
      case 'ORDER_PICKED_UP':
        return '#FFF8E1'
      case 'ORDER_DELIVERED':
        return '#DEFFEE'
      default:
        return '#FFE6E4'
    }
  }

  const statusTextColorSwitch = (status) => {
    switch (status) {
      case 'ORDER_CREATED':
        return '#A6B7D4'
      case 'ORDER_CONFIRMED':
        return '#66CB9F'
      case 'ORDER_AWAITING_ASSIGNMENT':
        return '#66CB9F'
      case 'ORDER_ASSIGNED':
        return '#4C6FFF'
      case 'ORDER_ACCEPTED':
        return '#66CB9F'
      case 'ORDER_REJECTED':
        return '#F16063'
      case 'ORDER_PICKED_UP':
        return '#FFD54F'
      case 'ORDER_DELIVERED':
        return '#66CB9F'
      default:
        return '#F16063'
    }
  }


  return (

    <section className={'activeOrdersCard'}>
      <div className={'flexRow justifyBetween activeOrdersCardHeader'}>
        <div className={'flexRow alignCenter'}>
          <div className={'userOrderAvatar'}>
            <img src={`${data?.user?.avatar}`} style={{ width: '100%', height: '100%', borderRadius: '100px' }} />
          </div>
          <div>
            <p className={'f12 boldText capitalize'}>
              {data?.user?.firstName || 'No name'}
              <span style={{ fontSize: '8px', color: '#919294' }} > - CUSTOMER</span>
            </p>
            <p className={'f12 regularText'}>+{data?.user?.phoneNumber}</p>
          </div>
        </div>
        <div>
          <p style={{ backgroundColor: statusBgSwitch(data?.orderStatus), borderRadius: '10px', margin: '0', color: statusTextColorSwitch(data?.orderStatus) }}
            className={'regularText f10 textCenter'}>
            {statusTextSwitch(data?.orderStatus)}
          </p>
          <p className={'f10 regularText'} style={{ paddingTop: '5px' }}>
            {moment(data?.createdAt).format('YYYY-MM-DD hh:mm')}
          </p>
        </div>



      </div>
      <div style={{ marginTop: '16px', display: 'grid', gridTemplateColumns: '90% 10%', gap: '16px', alignItems: 'center' }}>
        <div sty>
          <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '4px' }}>
            <p className={'boldText f14 capitalize'}>{data?.originBusinessName}</p>
            {/* <p className={'semiBoldText f14'}>₦{data?.totalItemsPrice}</p> */}
          </div>
          <div>
            <ul>
              {data?.orderItems?.map((item, index) => {
                return (
                  <li className={'flexRow alignCenter justifyBetween f12 regularText capitalize '} style={{ marginBottom: '4px' }} key={index}>
                    {item?.name || 'No name'}
                    {/* <span style={{ color: '#696A6B' }}>₦{item?.price}</span> */}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div >
          <p></p>
        </div>

      </div>

        <div className={'flexRow justifyBetween alignCenter activeOrdersCardFooter'}>
          {data?.rider ? 
          (<div className={'flexRow alignCenter'}>
            <div className={'userOrderAvatar'}>
              <img src={`${data?.rider?.user?.avatar}`} style={{ width: '100%', height: '100%', borderRadius: '100px' }} />
            </div>
            <div>
              <p className={'f12 boldText'}>
                {data?.rider?.user?.firstName || 'No name'}
                <span style={{ fontSize: '8px', color: '#919294' }} > - COURIER</span>
              </p>
              <p className={'f12 regularText'}>{data?.rider?.user?.phoneNumber}</p>
            </div>
          </div>) : <div>
          <p className={'semiBoldText f12'}>No rider assigned</p>
          </div> }
          <div className={'activeOrderView'}>
            <p className={'semiBoldText f12'} onClick={() => getSingleOrder(data)} style={{ cursor: 'pointer' }}>View</p>
          </div>
        </div>
      
    </section>

  )
}

export default RiderAgentMobileCard