import React from 'react';
import { BlackStar, ClockIcon, OrangeCooking } from '../../assets/images';
import '../../assets/styles/homeStyles.css';
import Skeleton from 'react-loading-skeleton'
import moment from 'moment';
import { useSelector } from 'react-redux';


const FoodCard = (props) => {

    // const { loading } = useSelector(state => state.customer)
    const { loading, data, customStyle, gettingRestaurants, index } = props
    const { workingTime } = useSelector(state => state.customer);
    // console.log(workingTime, 'time')


    // console.log('data', data)
    const prepTime = data?.prepTime
    const shortenedPrepTime = prepTime?.replace('minutes', 'mins')



    const renderFoodCardOverlay = () => {
        if (workingTime?.isDadofoodsOpen) {
            return (
                <div className={'prepTime'}>
                    <img src={OrangeCooking} alt={'clockIcon'} style={{ width: '14px', height: '14px' }} />
                    <p className={'boldText'} style={{ fontSize: "12px" }}>{shortenedPrepTime}</p>
                </div>
            )
        } else {
            return (
                <div className={'foodCardImageOverlay'}>
                    <p className={'f16 boldText'} style={{ color: '#F9F9FB' }}>Working hours 9am - 6pm</p>
                    <div className={'prepTime'}>
                        <img src={ClockIcon} alt={'clockIcon'} style={{ width: '14px', height: '14px' }} />
                        <p className={'boldText'} style={{ fontSize: "12px" }}>Closed</p>
                        {/* <p className={'boldText'} style={{ fontSize: "12px" }}>{workingTime?.message}</p> */}
                    </div>
                </div>
            )
        }
    }

    // newText = originalText.replace('Geeks', '');
    return (
        <>
            <section >
                {loading || gettingRestaurants ?
                    <section className='foodCardContainer' style={{ width: props.width, height: props.height }} onClick={props.onClick}>
                        <div>
                            <Skeleton height={'150px'} />
                        </div>
                        <div>
                            <Skeleton height={'20px'} style={{ margin: '10px 0' }} />
                            <Skeleton height={'20px'} style={{ marginBottom: '10px' }} />
                        </div>
                    </section> :
                    <section className={`foodCardContainer ${customStyle}`} style={{ width: props.width, height: props.height, }} key={`${index}`} onClick={props.onClick}>
                        <div className={'foodCardImageContainer'} style={{ height: props.imgHeight }}>
                            <img src={data?.businessImage} alt={'poster'} style={{ width: '100%', height: props.imageHeight, backgroundColor: 'rgba(1, 1, 1, 0.2)', borderRadius: '16px' }} />
                            {renderFoodCardOverlay()}
                        </div>
                        <div className={'foodCardContent'} >
                            <div style={{ cursor: 'pointer' }}>
                                <div className={'flexRow justifyBetween'}>
                                    <h5 className={'boldText'}>{data?.businessName}</h5>
                                    <span className={'boldText'}><img src={BlackStar} alt={'icon'} />{data?.businessAverageRating}</span>
                                </div>
                                <div className={'foodCardTags'}>
                                    {data?.tags?.map((item, i) => {
                                        return (
                                            <div className={'foodCardTags'} key={`${i}`}>
                                                <li className={'boldText'} style={{ color: '#FFB049', fontSize: '12px' }} >{item}</li>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </section >
                }
            </section>

        </>
    )
}

export default FoodCard