import React from 'react';
import '../../assets/styles/modalStyles.css';
import '../../assets/styles/customersStyles.css';
import { AnimatePresence, motion } from 'framer-motion';
import PreOrderCard from './PreOrderCard';

const CartModal = (props) => {

    const { visible } = props
    return (
        <>
            {visible &&
                <section className={'modalContainer'}>
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{ width: '100%' }}
                        >
                            <PreOrderCard />
                        </motion.div>
                    </AnimatePresence>
                </section>
            }
        </>
    )

}

export default CartModal;