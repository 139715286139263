import React from "react";
import FaqAccordion from "./FaqAccordion";
import { useMediaQuery } from "react-responsive";


const Faq = ({ faqs }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const renderFaqs = () => {
    if (faqs?.length !== 0) {
      return faqs?.map((item, index) => {
        return <FaqAccordion title={item?.title} content={item?.answer} key={index * 1} />
      })
    }
  }

  return (
    <section className={"faqSection"}>
      <header>
        <h4 className={`boldText ${isDesktopOrLaptop ? 'f20' : 'f16'}`} style={{ color: '#FFA024', marginBottom: '24px' }}>FREQUENTLY ASKED QUESTIONS</h4>
        <h2 className={`semiBoldText ${isDesktopOrLaptop ? 'f38' : 'f20'}`}>Got a question or need clarity?</h2>
        <p className={`regularText ${isDesktopOrLaptop ? 'f20' : 'f16'}`}>
          Discover quick answers to common questions and concerns in our comprehensive Frequently Asked Questions (FAQs) list.
        </p>
      </header>
      <div className={"faqGrid"}>
        {renderFaqs()}
      </div>
    </section>
  );
};

export default Faq;


