import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Assuming you're using Redux for authentication state

const PrivateRoute = ({ children }) => {
    const {isAuthenticated} = useSelector(state => state.riderAgent); 

    return isAuthenticated ? children : <Navigate to="/rider_agency/login" />;
};

export default PrivateRoute;
