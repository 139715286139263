import React from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { FoodTray } from '../../assets/images';
import MiniLoader from '../../utils/MiniLoader';
import OrderAccordion from './OrderAccordion';


const OrdersCard = (props) => {

    const { orders, gettingOrders } = useSelector(state => state.customer)
    const { closeCart } = props

    const renderDate = (item) => {
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
        const setDate = new Date(item?.createdAt)
        if (item?.createdAt === date) {
            return 'Today'
        } else {
            return (
                setDate.toDateString()
            )
        }

    }

    const renderOrders = () => {
        if (gettingOrders) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <MiniLoader />
                </div>
            )
        }
        if (orders && orders?.data?.orders?.length !== 0) {
            return orders?.data?.orders?.map((item, index) => {
                return (
                    <div key={index}>
                        <p className='semiBoldText f14' style={{ color: '#919294', marginBottom: '16px' }}>{renderDate(item)}</p>
                        <OrderAccordion data={item} index={index} />
                    </div>
                )
            })
        }
        if (orders && orders?.data?.orders?.length === 0) {
            return (

                <div className={'flexColumn alignCenter justifyCenter'}>
                    <img src={FoodTray} alt='poster' style={{ marginBottom: '40px' }} />
                    <h5 className={'textCenter boldText f16'}>Your tray is empty</h5>
                    <p className={'regularText f14 textCenter'} style={{ color: '#919294;' }}>Place an order and you can track the progress</p>

                </div>
            )
        }
    }


    return (
        <section className={'ordersContainerBg'}>
            <section className={'ordersCardContainer cartCardContainer'} style={{ zIndex: '10px ', }}>

                {orders && orders?.data?.orders?.length !== 0 &&
                    <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '24px' }}>
                        <h4 className={'boldText f18'} style={{ color: '#38383A', }}>Your Orders</h4>
                        <div className={'closeOrdersBtn'} onClick={closeCart}>
                            <AiOutlineClose size={20} />
                        </div>
                    </div>

                }
                <div >
                    {renderOrders()}
                </div>
            </section>

        </section>

    )
}

export default OrdersCard