import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBanks, getRiderAgentPaymentInfo } from '../../redux/actions/RiderAgentActions'
import PaymentMethodModal from '../../components/rider-agent/RiderAgentPaymentMethodModal'
import CustomButton from '../../utils/CustomButton'
import { Receipt1, Trash } from 'iconsax-react'
import { shortenXterLength } from '../../utils/Helpers'
import FullScreenLoader from '../../utils/FullScreenLoader'
import http from '../../utils/Utils'
import { showSuccess } from '../../utils/Toast'

const RiderAgentPaymentInfo = () => {
  const { riderAgentPaymentInfo } = useSelector((state) => state.riderAgent)
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  console.log(riderAgentPaymentInfo,'me')

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getRiderAgentPaymentInfo())
    dispatch(getBanks())
  }, [])


  const openModal = () => { setModalOpen(true); }
  const closeModal = () => { setModalOpen(false); }

  const deletePaymentInfo = async (id) => {
    setLoading(true)
    try {
        const obj = { };
        const res = await http.delete(`rider_agency/payment/info/${id}/delete`, obj,  "v2");
        showSuccess('Payment method deleted successfully');
        setLoading(false)
        dispatch(getRiderAgentPaymentInfo());
    } catch (error) {
        console.error('Failed to delete info', error);
    } finally {
      setLoading(false)
    }
  }


  const renderEmpty = () => {
    if (riderAgentPaymentInfo?.length === 0) {
      return (
        <div style={{ marginTop: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Receipt1 size="32" color="#000" />
          <h3 style={{ margin: '10px 0 40px 0' }}>No Payment methods</h3>
        </div>
      )
    }
  }


  const renderPaymentInfoList = () => {
    if (riderAgentPaymentInfo?.length !== 0) {
      return riderAgentPaymentInfo?.map((item) => {
        return (
          <div style={{ border: '1px solid rgba(104, 232, 227, 1)', borderRadius: '8px', padding: '8px 12px', marginBottom: '16px' }} key={item?.bankCode}>
            <div className={'flexRow alignCenter justifyBetween'}>
              <h5>{shortenXterLength(item.bankName, 30)}</h5>
              <Trash size="18" color="rgba(249, 104, 104, 1)" onClick={()=>deletePaymentInfo(item?.id)}/>
            </div>
            <div className={'flexRow alignCenter justifyBetween'} style={{ marginTop: '10px' }}>
              <p className={'f14'}>{item?.bankAccountName}</p>
              <p className={'f12'}>{item?.bankAccountNumber}</p>
            </div>
          </div>
        )
      })
    }
  }

  const renderLoading = () => {
    if(loading) {
      return (
        <FullScreenLoader/>
      )
    }
  }


  return (
    <main className={'riderAgentContainer'}>
      {renderLoading()}
      <header>
        <h2>Payment Info</h2>
        <p>Manage your payment methods</p>
        <section style={{ marginTop: '60px' }}>
          {renderEmpty()}
          {renderPaymentInfoList()}
          <div style={{ marginTop: '40px' }}>
            <CustomButton title={'Add Payment Method'} onClick={openModal} />

          </div>
        </section>
      </header>

      {/* <button onClick={openModal}>Open Modal</button> */}

      <PaymentMethodModal isOpen={isModalOpen} onClose={closeModal} />
    </main>
  )
}

export default RiderAgentPaymentInfo