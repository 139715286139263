import React, { useState, useEffect } from 'react';
import './utils.css';
import { DadoAppLogo } from '../assets/images';

const SmartBanner = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Check if the banner was dismissed
        const dismissed = localStorage.getItem('smartBannerDismissed');
        if (!dismissed) {
            // Check device type
            const userAgent = navigator.userAgent || window.opera;
            setIsIOS(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
            setIsAndroid(/android/i.test(userAgent));

            // Show banner if not dismissed
            setIsBannerVisible(true);
            adjustNavbar('add');
        }
    }, [isIOS, isAndroid]);

    const adjustNavbar = (action) => {
        const homeNav = document.querySelector('.homeNavContainer');
        const fixedNav = document.querySelector('.customerLandingPage');
        if (homeNav) {
            action === 'add'
                ? homeNav.classList.add('with-smart-banner')
                : homeNav.classList.remove('with-smart-banner');
        }
        if (fixedNav) {
            action === 'add'
                ? fixedNav.classList.add('with-smart-banner')
                : fixedNav.classList.remove('with-smart-banner');
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const closeBanner = () => {
        setIsBannerVisible(false);
        localStorage.setItem('smartBannerDismissed', 'true');
        adjustNavbar('remove');
    };

    if (!isBannerVisible || screenWidth > 1024) {
        return null;
    }

    const returnLink = () => {
        return isIOS ? "https://apps.apple.com/us/app/dado-food/id6444361467" : "https://play.google.com/store/apps/details?id=com.dado.ng";
    };

    return (
        <div className={`smart-banner ${isBannerVisible ? 'slide-down' : 'slide-up'}`}>
            <button className="smart-banner-close" onClick={closeBanner}>
                &times;
            </button>
            <div className="smart-banner-content">
                <div className="flexRow alignCenter" style={{ gap: '10px' }}>
                    <img src={DadoAppLogo} alt='app_logo' style={{ width: '40px', height: '40px', objectFit: 'contain', borderRadius: '10px' }} />
                    <div className="smart-banner-text">
                        <h3>Dado Food: Order local food</h3>
                        <p>Order food quickly and easily on our mobile app</p>
                    </div>
                </div>
                <div className="smart-banner-buttons">
                    <a href={returnLink()} className="smart-banner-button ios">
                        Open
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SmartBanner;
