import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/styles/main.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/Index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-step-progress-bar/styles.css";
import 'react-loading-skeleton/dist/skeleton.css';
import 'animate.css';
import './assets/styles/authStyles.css';
import './assets/styles/customersStyles.css';
import './assets/styles/extraStyles.css';
import './assets/styles/generalStyles.css';
import './assets/styles/modalStyles.css';
import './assets/styles/textStyles.css';
import './assets/styles/widgetStyles.css';
import './assets/styles/homeStyles.css';
import './assets/styles/ridersStyles.css';
import './assets/styles/vendorStyles.css';
import './assets/styles/otherLandingPageStyles.css';
import './assets/styles/walletStyles.css';
import './assets/styles/riderAgentStyles.css';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-coverflow";
import { IntercomProvider } from 'react-use-intercom';
import initializeMixpanel from './utils/Mixpanel';
import SmartBanner from './utils/SmartBannerComponent';


const environment = process.env.REACT_APP_ENVIRONMENT;
const mixpanelKey = process.env.REACT_APP_MIXPANEL_KEY;
const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;

console.log(environment, 'env');

initializeMixpanel();
const INTERCOM_APP_ID = 'dne87ccd';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={environment === 'prod' && true}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ToastContainer />
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </IntercomProvider>
  </React.StrictMode>
);

reportWebVitals();
