import React from 'react';
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, YoutubeIcon } from '../assets/images';
import '../assets/styles/widgetStyles.css';
import '../assets/styles/generalStyles.css';
import { Link } from 'react-router-dom';
import NewsLetterComponent from './NewsLetterComponent';
import { useMediaQuery } from 'react-responsive';
import FooterTab from './FooterTab';


const Footer = (props) => {

    const now = new Date();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const goToMaas = () => {
        window.open('https://dadomaas.com', '_blank', 'noreferrer')
    }

    return (
        <>
            <main className={'footerMainContainer'}>
                <NewsLetterComponent />
                <footer className={'footerContainer'} >
                    <header>
                        <h2 className={`boldText textCenter ${isDesktopOrLaptop ? 'f38' : 'f24'}`} style={{ color: '#fff' }}>Dado; your <span style={{ color: "#F4743B" }}>best</span> choice for local meals!!!</h2>
                    </header>
                    <section className={'footerGridContainer'}>
                        <aside>
                            <div>
                                <h5 className={'f20 boldText'} style={{ color: "rgba(145, 146, 148, 1)" }}>Dado Food</h5>
                                <ul className={'flexRow alignCenter'} style={{ gap: "12px" }}>

                                    {/* <li className={` mediumText textCenter ${isDesktopOrLaptop ? 'f16' : 'f12'}`}
                                        style={{
                                            color: 'rgba(249, 249, 251, 1)', borderRight: "1px solid #919294",
                                            paddingRight: isDesktopOrLaptop ? '12px' : '5px', cursor: 'pointer'
                                        }}
                                        onClick={goToMaas}
                                    >Menu as a Service</li> */}

                                    <Link to={'/couriers'}>
                                        <li className={` mediumText textCenter ${isDesktopOrLaptop ? 'f16' : 'f12'}`} style={{ color: 'rgba(249, 249, 251, 1)', }}>The couriers</li>
                                    </Link>
                                    <Link to={'/vendors'}>
                                        <li className={` mediumText textCenter  ${isDesktopOrLaptop ? 'f16' : 'f12'}`} style={{ color: 'rgba(249, 249, 251, 1)', borderLeft: "1px solid #919294", paddingLeft: isDesktopOrLaptop ? '12px' : '5px' }}>The vendors</li>

                                    </Link>
                                </ul>
                            </div>
                            <div style={{ marginTop: '24px' }}>
                                <h5 className={'f20 boldText'} style={{ color: "rgba(145, 146, 148, 1)" }}>Resources</h5>
                                <ul className={'flexRow alignCenter'} style={{ gap: "12px" }}>
                                    <Link to={'/privacy'}>
                                        <li className={` mediumText textCenter ${isDesktopOrLaptop ? 'f16' : 'f12'}`} style={{ color: 'rgba(249, 249, 251, 1)', borderRight: "1px solid #919294", paddingRight: isDesktopOrLaptop ? '12px' : '5px' }}>Privacy</li>
                                    </Link>
                                    <Link to={'/terms'}>
                                        <li className={` mediumText textCenter ${isDesktopOrLaptop ? 'f16' : 'f12'}`} style={{ color: 'rgba(249, 249, 251, 1)', borderRight: "1px solid #919294", paddingRight: isDesktopOrLaptop ? '12px' : '5px' }}>Terms and Condition</li>
                                    </Link>
                                    <Link to={'/#'}>
                                        <li className={` mediumText textCenter ${isDesktopOrLaptop ? 'f16' : 'f12'}`} style={{ color: 'rgba(249, 249, 251, 1)' }} onClick={() => window.location = 'mailto:support@dado.ng'}>support@dado.ng</li>
                                    </Link>
                                </ul>
                            </div>
                        </aside>
                        <aside>
                            <div className={'storeLinksContainer'}>
                                <FooterTab />
                            </div>
                        </aside>
                    </section>
                    <section className={`flexRow alignCenter justifyBetween footerBottom ${isDesktopOrLaptop ? 'noWrap' : 'flexWrap justifyCenter'}`} style={{ gap: '20px 0' }}>
                        <div>
                            <p className={'f16 regularText'} style={{ color: 'rgba(152, 162, 179, 1)' }}>© {now.getFullYear()} Dado foods. All rights reserved.</p>
                        </div>
                        <div>
                            <ul className={'flexRow alignCenter'} style={{ gap: "24px" }}>
                                <li>
                                    <a href='https://twitter.com/dadofood'
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={TwitterIcon} alt={'twitter icon'} />
                                    </a>
                                </li>
                                <li>
                                    <a href='https://instagram.com/dadofoodng?igshid=NDc0ODY0MjQ='
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={InstagramIcon} alt={'instagram icon'} />
                                    </a>
                                </li>
                                <li>
                                    <a href='https://m.facebook.com/dadofoods'
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={FacebookIcon} alt={'facebook icon'} />
                                    </a>
                                </li>
                                <li>
                                    <a href='https://ng.linkedin.com/company/dadochops'
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={LinkedInIcon} alt={'linkedIn icon'} />
                                    </a>
                                </li>


                                <li>
                                    <a href='https://m.youtube.com/channel/UCRpeaWxAKKN_K4lEnQKLOXA'
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={YoutubeIcon} alt={'youtube icon'} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </footer>
            </main>

        </>
    )
}

export default Footer