import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import http from './Utils';
import { useSelector } from 'react-redux';


const BankDropdown = ({onChangeBankName, onChangeBankCode}) => {
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const fetchBanks = async () => {
            try {
                const res = await http.get('/list_of_nigeria_banks', {}, 'v2'); 
                setBanks(res.data.banks.map(bank => ({
                    label: `${bank.name}`,
                    value: bank.code
                })));
                setLoading(false);

            } catch (error) {
                // console.error("Error fetching banks", error);
                setLoading(false);
            }
        };

        fetchBanks();
    }, []);


    const handleBankChange = (selectedOption) => {
        // console.log("Selected Bank:", selectedOption);
        onChangeBankName(selectedOption?.label)
        onChangeBankCode(selectedOption.value)
    };


    return (
        <div style={{marginBottom:'16px'}}>
            <label className={`f14 grey semiBoldText flex alignCenter`} >
                Bank name
            </label>

            <Select
                options={banks}
                onChange={handleBankChange}
                isSearchable
                placeholder="Select a bank"
                styles={{
                    option: (base) => ({
                        ...base,
                        color: '#000',
                        padding: '8px !important'
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#ebebeb',
                        borderRadius: '8px',
                        padding: '6px 16px',
                        marginTop:'8px'
                    }),
                }}

            />
        </div>

    );
};

export default BankDropdown;
