import React from 'react';
import '../../assets/styles/modalStyles.css';
import { AiOutlineClose } from 'react-icons/ai';
import { DeliveryBottomImage, SavedAddress } from '../../assets/images';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import CustomButton from '../../utils/CustomButton';


const SavedAddressModal = (props) => {
    return (
        <>
            {props.visible &&
                <section className={'modalContainer'}>
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{ width: '100%' }}
                        >
                            <div className={'modalContentContainer'}>
                                <div className={'modalCloseBtn'} onClick={props.onDecline}>
                                    <AiOutlineClose size={20} />
                                </div>
                                <div>
                                    <h4 className={'boldText textCenter'}>Delivery address</h4>
                                    <p className={'regularText textCenter'}>Choose a delivery address for the riders</p>
                                </div>
                                <div>
                                    <img src={SavedAddress} alt={'icon'} />
                                    <p className={'regularText f16'}>Saved addresses</p>
                                </div>
                                <div>
                                    <p>Delivery address</p>
                                    <GooglePlacesAutocomplete
                                        apiKey='AIzaSyA7SpnnJlPtGXUtCiJM-tPbY3D2BY-ZtCM'
                                    />
                                </div>
                                <div>
                                    <CustomButton title={'Add address'} />
                                </div>
                                <div className={'modalBottomImage'}>
                                    <img src={DeliveryBottomImage} alt='borderImage' />
                                </div>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                </section>
            }
        </>
    )

}

export default SavedAddressModal