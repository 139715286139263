import React from 'react'
import AboutUsCard from './AboutUsCard'
import { StatsCourier, StatsOrders, StatsUser, StatsVendors, ValueDrop, ValueGrowth, ValueHeart, ValueUp } from '../../../assets/images';

const AboutSectionCardsContainer = () => {

    const greenItems = [
        { title: 'Convenience', description: 'No Hassle & Empathy', type: 'green', icon: ValueHeart },
        { title: 'Unparalleled selections', description: 'Explore & Savor', type: 'green', icon: ValueGrowth },
        { title: 'Community', description: 'Connect & Thrive.', type: 'green', icon: ValueUp },
        { title: 'Customer love', description: 'Delight & Empower.', type: 'green', icon: ValueDrop },

    ]

    const blackItems = [
        { title: '15k', description: 'Happy & Loyal customers.', type: 'black', icon: StatsUser },
        { title: '50+ Couriers ', description: 'Delivering & Earning', type: 'black', icon: StatsCourier },
        { title: 'Over 50k orders', description: 'Processed & Completed', type: 'black', icon: StatsOrders },
        { title: '61 Vendors', description: 'Growing their businesses.', type: 'black', icon: StatsVendors },

    ]

    return (

        <section >
            <div className={'aboutUsCardContainer'}>
                <AboutUsCard title={'Core Values'} items={greenItems} />
                <AboutUsCard title={'Operational Stats'} items={blackItems} />
            </div>
            {/* <div style={{ marginTop: '40px', width: '153px', margin: '40px auto' }}>
                <CustomButton title={'learn more'} />
            </div> */}
        </section>
    )
}

export default AboutSectionCardsContainer