import http from "../../utils/Utils"
import { ADDING_TO_CART, ADDING_TO_CART_FAIL, ADDING_TO_CART_SUCCESSFUL, DELETING_FROM_CART, DELETING_FROM_CART_FAIL, DELETING_FROM_CART_SUCCESSFUL, GETTING_ALL_RESTAURANTS, GETTING_ALL_RESTAURANTS_FAIL, GETTING_ALL_RESTAURANTS_SUCCESSFUL, GETTING_CART, GETTING_CART_FAIL, GETTING_CART_SUCCESSFUL, GETTING_CATEGORIES, GETTING_CATEGORIES_FAIL, GETTING_CATEGORIES_SUCCESSFUL, GETTING_ORDERS, GETTING_ORDERS_FAIL, GETTING_ORDERS_STATUS, GETTING_ORDERS_STATUS_FAIL, GETTING_ORDERS_STATUS_SUCCESSFUL, GETTING_ORDERS_SUCCESSFUL, GETTING_RESTAURANT, GETTING_RESTAURANTS_BY_CATEGORIES, GETTING_RESTAURANTS_BY_CATEGORIES_FAIL, GETTING_RESTAURANTS_BY_CATEGORIES_SUCCESSFUL, GETTING_RESTAURANTS_PRODUCT, GETTING_RESTAURANTS_PRODUCT_FAIL, GETTING_RESTAURANTS_PRODUCT_SUCCESSFUL, GETTING_RESTAURANT_FAIL, GETTING_RESTAURANT_SUCCESSFUL, GETTING_WALLET, GET_TRENDING, GET_TRENDING_FAIL, GET_TRENDING_SUCCESSFUL, GET_WORKING_TIME, WALLET, WALLET_FAIL, WORKING_TIME } from "../Types"

export const getAllRestaurants = (page, limit, lat, lng) => {
    return dispatch => {
        dispatch({ type: GET_TRENDING })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`customer/businesses/trending/location?lat=${lat}&lng=${lng}&page=${page}&limit=${limit}`)
                const data = res
                // console.log('All restaurants', res)
                dispatch({ type: GET_TRENDING_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                dispatch({ type: GET_TRENDING_FAIL, payload: error });
                reject(error);
            }
        })
    }
}


// customer / business /: business_id
export const getSingleRestaurant = (businessId) => {
    return dispatch => {
        dispatch({ type: GETTING_RESTAURANT })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`customer/business/${businessId}`)
                const data = res?.data
                // console.log('All restaurants', res)
                dispatch({ type: GETTING_RESTAURANT_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                dispatch({ type: GETTING_RESTAURANT_FAIL, payload: error });
                reject(error);
            }
        })
    }
}


export const getTrendingRestaurants = (page, limit, lat, lng) => {
    return dispatch => {
        dispatch({ type: GETTING_ALL_RESTAURANTS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`customer/businesses/location?lat=${lat}&lng=${lng}&page=${page}&limit=${limit}`)
                const data = res
                // console.log('All restaurants', res)
                dispatch({ type: GETTING_ALL_RESTAURANTS_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                dispatch({ type: GETTING_ALL_RESTAURANTS_FAIL, payload: error });
                reject(error);
            }
        })
    }
}


// customer / businesses / location ? lat = 6.458820 & lng=7.497100 & page=1 & limit=20



export const getCategories = () => {
    return dispatch => {
        dispatch({ type: GETTING_CATEGORIES })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get('customer/businesses/categories?page=1&limit=10')
                const data = res
                // console.log('categories', res)
                dispatch({ type: GETTING_CATEGORIES_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                dispatch({ type: GETTING_CATEGORIES_FAIL, payload: error });
                reject(error);
            }
        })
    }
}
// customer/businesses/location/1/categories?page=1&limit=4

// customer/businesses/location/2/category/1?page=1&limit=4

export const getRestaurantsByCategories = (page, limit, lat, lng, categories) => {
    return dispatch => {
        dispatch({ type: GETTING_ALL_RESTAURANTS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`customer/businesses/category/${categories}/location?lat=${lat}&lng=${lng}&page=${page}&limit=${limit}`)
                const data = res
                // console.log('restaurants by categories', res)
                dispatch({ type: GETTING_ALL_RESTAURANTS_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                dispatch({ type: GETTING_ALL_RESTAURANTS_FAIL, payload: error });
                reject(error);
            }
        })
    }
}




// export const getRestaurantsProduct = (businessId, categoryId = null) => {
//     return dispatch => {
//         dispatch({ type: GETTING_RESTAURANTS_PRODUCT });
//         return new Promise(async (resolve, reject) => {
//             try {
//                 let url = `customer/business/${businessId}/products`;

//                 if (categoryId !== null) {
//                     url += `?category_id=${categoryId}`;
//                 }
//                 const res = await http.get(url);
//                 const data = res.data; 
//                 dispatch({ type: GETTING_RESTAURANTS_PRODUCT_SUCCESSFUL, payload: data });
//                 resolve(data);
//             } catch (error) {
//                 // console.log(error);
//                 dispatch({ type: GETTING_RESTAURANTS_PRODUCT_FAIL, payload: error });
//                 reject(error);
//             }
//         });
//     };
// };

// export const getRestaurantsProduct = (businessId, page = 1, perPage = 20, categoryId = null) => {
//     return dispatch => {
//         dispatch({ type: GETTING_RESTAURANTS_PRODUCT });

//         return new Promise(async (resolve, reject) => {
//             try {
//                 let url = `customer/business/${businessId}/products`;
//                 let params = `?page=${page}&per_page=${perPage}`;

//                 if (categoryId !== null) {
//                     params += `&category_id=${categoryId}`;
//                 }
//                 const res = await http.get(`${url}${params}`);
//                 dispatch({ type: GETTING_RESTAURANTS_PRODUCT_SUCCESSFUL, payload: res });
//                 resolve(res);
//             } catch (error) {
//                 dispatch({ type: GETTING_RESTAURANTS_PRODUCT_FAIL, payload: error });
//                 reject(error);
//             }
//         });
//     };
// };

// export const getRestaurantsProduct = (businessId, categoryId = 0, page = 1, perPage = 20) => {
//     return dispatch => {
//         dispatch({ type: GETTING_RESTAURANTS_PRODUCT });
//         return new Promise(async (resolve, reject) => {
//             try {
//                 let url = `customer/business/${businessId}/products?category_id=${categoryId}&page=${page}&perPage=${perPage}`;
//                 const res = await http.get(url);
//                 const data = res.data; 
//                 dispatch({ type: GETTING_RESTAURANTS_PRODUCT_SUCCESSFUL, payload: data });
//                 resolve(data);
//             } catch (error) {
//                 // console.log(error);
//                 dispatch({ type: GETTING_RESTAURANTS_PRODUCT_FAIL, payload: error });
//                 reject(error);
//             }
//         });
//     };
// };


export const getRestaurantsProduct = (businessId, categoryId = 0, page = 1, perPage = 20) => {
    return dispatch => {
        dispatch({ type: GETTING_RESTAURANTS_PRODUCT });
        return new Promise(async (resolve, reject) => {
            try {
                let url = `customer/business/${businessId}/products?category_id=${categoryId}&page=${page}&perPage=${perPage}`;
                const res = await http.get(url);
                const data = res.data;
                dispatch({ type: GETTING_RESTAURANTS_PRODUCT_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: GETTING_RESTAURANTS_PRODUCT_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



export const getProductCategories = (businessId) => {
    return dispatch => {
        // dispatch({ type: GETTING_RESTAURANTS_PRODUCT })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`customer/business/${businessId}/product_categories`)
                const data = res
                console.log('products category', res)
                // dispatch({ type: GETTING_RESTAURANTS_PRODUCT_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                // dispatch({ type: GETTING_RESTAURANTS_PRODUCT_FAIL, payload: error });
                reject(error);
            }
        })
    }
}


export const getCart = () => {
    return dispatch => {
        dispatch({ type: GETTING_CART })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get('customer/cart')
                const data = res
                // console.log('cart', res)
                dispatch({ type: GETTING_CART_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                dispatch({ type: GETTING_CART_FAIL, payload: error });
                reject(error);
            }
        })
    }
}


export const addToCart = (business_id, product_id, quantity) => {
    const obj = { business_id, product_id, quantity }
    return dispatch => {
        dispatch({ type: ADDING_TO_CART })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post('customer/cart/add', obj)
                const data = res
                // console.log('cart', res)
                dispatch({ type: ADDING_TO_CART_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                dispatch({ type: ADDING_TO_CART_FAIL, payload: error });
                reject(error);
            }
        })
    }
}



export const deleteFromCart = (obj) => {
    return dispatch => {
        dispatch({ type: DELETING_FROM_CART })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post('customer/cart/delete', obj)
                const data = res
                // console.log('cart', res)
                dispatch({ type: DELETING_FROM_CART_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log(error)
                dispatch({ type: DELETING_FROM_CART_FAIL, payload: error });
                reject(error);
            }
        })
    }
}



export const getOrders = () => {
    return dispatch => {
        dispatch({ type: GETTING_ORDERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get('customer/orders')
                const data = res
                // console.log('ORDERS', res)
                dispatch({ type: GETTING_ORDERS_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log('ORDERS ERROR', error)
                dispatch({ type: GETTING_ORDERS_FAIL, payload: error });
                reject(error);
            }
        })
    }
}

export const getOrdersStatus = (id) => {
    return dispatch => {
        dispatch({ type: GETTING_ORDERS_STATUS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`customer/order/${id}/status`)
                const data = res
                // console.log('ORDERS STATUS', res)
                dispatch({ type: GETTING_ORDERS_STATUS_SUCCESSFUL, payload: data });
                resolve(data)
            } catch (error) {
                // console.log('ORDERS STATUS ERROR', error)
                dispatch({ type: GETTING_ORDERS_STATUS_FAIL, payload: error });
                reject(error);
            }
        })
    }
}



export const getWorkingTime = (id) => {
    return dispatch => {
        dispatch({ type: GET_WORKING_TIME })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`operation_details`)
                const data = res.data
                // console.log('ORDERS STATUS', res)
                dispatch({ type: WORKING_TIME, payload: data });
                resolve(data)
            } catch (error) {
                // console.log('ORDERS STATUS ERROR', error)
                dispatch({ type: GET_WORKING_TIME, payload: error });
                reject(error);
            }
        })
    }
}




export const getWallet = (id) => {
    return dispatch => {
        dispatch({ type: GETTING_WALLET })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`customer/wallet`)
                const data = res.data
                console.log('WALLET', res)
                dispatch({ type: WALLET, payload: data });
                resolve(data)
            } catch (error) {
                // console.log('WALLET ERROR', error)
                dispatch({ type: WALLET_FAIL, payload: error });
                reject(error);
            }
        })
    }
}



