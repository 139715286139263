import React, { useEffect, useState } from "react";
import Navbar from "../../widgets/Navbar";
import Footer from "../../widgets/Footer";
import HeroSection from "../../components/landingPage/HeroSection";
import ServicesSection from "../../components/landingPage/ServicesSection";
import Faq from "../../components/landingPage/Faq";
import CustomerAuth from "../auth/customer/CustomerAuth";
import { faqs } from "../../utils/Helpers";
import MaasSection from "../../components/landingPage/MaasSection";
import OrderNowSection from "../../components/landingPage/OrderNowSection";
import CampusSection from "../../components/landingPage/CampusSection";
import About from "../../components/landingPage/about/About";
import Splash from "../../utils/Splash";
import AnimatePage from "../../utils/AnimatePage";
import mixpanel from "mixpanel-browser";
import { trackEvent } from "../../utils/Mixpanel";

const LandingPage = () => {

    const [state, setState] = useState({
        signInModal: false, signUpModal: false, phoneVerificationModal: false,
        splashScreen: true,
    })


    useEffect(() => {
        // Check if the splash screen has been shown before
        const hasSeenSplash = localStorage.getItem('hasSeenSplash');

        if (hasSeenSplash) {
            setState((prevState) => ({ ...prevState, splashScreen: false }));
        } else {
            // Set the flag in localStorage to indicate that the splash screen has been shown
            localStorage.setItem('hasSeenSplash', 'true');
        }

        trackEvent('User_visits_web_app', { page: 'LandingPage' });
    }, []);

    const hideScroll = () => {
        document.body.style.overflow = "hidden"
    }
    const unhideScroll = () => {
        document.body.style.overflow = "unset"
    }

    const openSignInModal = () => {
        setState((prevState) => ({ ...prevState, signInModal: true }))
        closePhoneVerificationModal()
        closeSignUpModal()
        hideScroll()
    }
    const closeSignInModal = () => {
        setState((prevState) => ({ ...prevState, signInModal: false }))
        unhideScroll()
    }


    const openSignUpModal = () => {
        setState((prevState) => ({ ...prevState, signUpModal: true }))
        closePhoneVerificationModal()
        closeSignInModal()
        hideScroll()
    }
    const closeSignUpModal = () => {
        setState((prevState) => ({ ...prevState, signUpModal: false }))
        unhideScroll()
    }


    const openPhoneVerificationModal = () => {
        setState((prevState) => ({ ...prevState, phoneVerificationModal: true, }))
        closeSignInModal()
        closeSignUpModal()
        hideScroll()
    }
    const closePhoneVerificationModal = () => {
        setState((prevState) => ({ ...prevState, phoneVerificationModal: false }))
        unhideScroll()
    }

    const closeSplashScreen = () => {
        setState((prevState) => ({ ...prevState, splashScreen: false }))
    }

    return (
        <>
            {state.splashScreen ? <Splash closeSplashScreen={closeSplashScreen} /> :
                <>
                    <Navbar openAuthModal={openSignInModal} />
                    <main className="landingPageContainer">
                        <HeroSection />
                        <section className={"secondLayerSection"}>
                            <div className={"thirdLayerSection"}>
                                <ServicesSection />
                                {/* <MaasSection /> */}
                                <CampusSection />
                            </div>
                            <About showCards showIntro showVideo />

                        </section>
                        <section >
                            <OrderNowSection />
                            <Faq faqs={faqs} />
                            <Footer />
                        </section>
                        <section style={{ padding: '40px 100px', display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340702880'} alt="footer-logo" style={{ objectFit: 'contain' }} />
                        </section>

                    </main>

                    <CustomerAuth signIn={state.signInModal} signUp={state.signUpModal} phoneVerification={state.phoneVerificationModal}
                        closePhoneVerification={closePhoneVerificationModal} closeSignIn={closeSignInModal} closeSignUp={closeSignUpModal}
                        openPhoneVerificationModal={openPhoneVerificationModal} openSignInModal={openSignInModal} openSignUpModal={openSignUpModal}
                    />
                </>
            }
        </>
    );
};

export default LandingPage;
