import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineArrowLeft } from 'react-icons/ai';

import { Verify } from '../../assets/images';
import CustomButton from '../../utils/CustomButton';
import http from '../../utils/Utils';
import HomeNavbar from '../../widgets/HomeNavBar';
import { getCart } from '../../redux/actions/CustomerActions';
import { trackEvent } from '../../utils/Mixpanel';


const VerifyPayments = () => {
    const { txfRef } = useSelector(state => state.customer);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const transactionId = params.get('transaction_id');
    const transactionRef = params.get('trxref') || params.get('tx_ref');
    const status = params.get('status');

    const verifyPayment = async () => {
        const payload = { transaction_ref: transactionRef, transaction_id: transactionId };
        try {
            const res = await http.post('customer/order/checkout/verify_payment', payload);
            if (res) {
                clearCart();
                trackEvent('order_successfully_completed');
            }
        } catch (error) {
            trackEvent('order_failed', { error });
        }
    };

    const clearCart = async () => {
        try {
            const res = await http.delete('customer/cart');
            if (res) {
                dispatch(getCart());
            }
        } catch (error) {
            console.error('Failed to clear cart', error);
        }
    };

    const renderResponseText = (val) => {
        switch (val) {
            case 'cancelled':
                return 'Payment Cancelled';
            case 'completed':
                return 'Payment Successful';
            case 'failed':
                return 'Payment Failed';
            default:
                return 'Payment Successful';
        }
    };

    useEffect(() => {
        verifyPayment();
    }, []);

    return (
        <>
            <HomeNavbar bgColor='#ffffff' />
            <main className='verifyPageContainer'>
                <section className='onGoBack flexRow alignCenter'>
                    <Link to='/home'>
                        <div className='backComponentContainer'>
                            <AiOutlineArrowLeft fontSize='30px' color='#000000' />
                        </div>
                    </Link>
                    <h3 className='boldText f24' style={{ marginLeft: '24px' }}>Check out</h3>
                </section>
                <section className='verifyPaymentContainer'>
                    <img src={Verify} alt='poster' style={{ width: '150px', height: '150px' }} />
                    <div>
                        <h4 className='boldText textCenter f24'>{renderResponseText(status)}</h4>
                        <p className='regularText textCenter'>Track the progress of your order from your order history as we process it.</p>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                        <Link to='/home'>
                            <CustomButton title='Return to home' />
                        </Link>
                    </div>
                </section>
            </main>
        </>
    );
};

export default VerifyPayments;
