import React from 'react';
import { NavLink} from 'react-router-dom';
import {  Bank, Home3, Profile, Truck, Wallet2 } from 'iconsax-react';


const navItems = [
    { to: '/rider_agency/orders', iconBold: <Home3 size="18" variant="Bold" />, iconLinear: <Home3 size="18" variant="Linear" /> },
    { to: '/rider_agency/riders', iconBold: <Truck size="18" variant="Bold" />, iconLinear: <Truck size="18" variant="Linear" /> },
    { to: '/rider_agency/wallet', iconBold: <Wallet2 size="18" variant="Bold" />, iconLinear: <Wallet2 size="18" variant="Linear" /> },
    { to: '/rider_agency/paymentInfo', iconBold: <Bank size="18" variant="Bold" />, iconLinear: <Bank size="18" variant="Linear" /> },
    { to: '/rider_agency/profile', iconBold: <Profile size="18" variant="Bold" />, iconLinear: <Profile size="18" variant="Linear" /> },
];

const RiderAgentNav = () => {
 

    return (
        <>
            <nav className={'riderAgentNavContainer'}>
                <section className={'logoContainer'}>
                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402377848'} alt='logo' />
                </section>
                <section className={'navListContainer'}>
                    <ul>
                        {navItems.map((item, index) => (
                            <li key={index}>
                                <NavLink
                                    to={item.to}
                                    className={({ isActive }) => `navLink ${isActive ? 'navLinkActive' : 'navLinkInactive'}`}
                                >
                                    {({ isActive }) => (isActive ? item.iconBold : item.iconLinear)}
                                </NavLink>
                            </li>
                        ))}
                      
                    </ul>
                </section>
            </nav>
        </>
    );
};

export default RiderAgentNav;
