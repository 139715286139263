import React from 'react';
import { FoodTray } from '../../assets/images';
import '../../assets/styles/customersStyles.css';


const EmptyCard = () => {
    return (
        <div className={'cartCardContainer flexColumn alignCenter'}>
            <img src={FoodTray} alt={'food tray'} style={{ marginBottom: '40px' }} />
            <h5 className={'textCenter boldText f16'}>Your tray is empty</h5>
            <p className={'regularText f14 textCenter'} style={{ color: '#919294' }}>Add orders and it will be displayed here</p>
        </div>
    )
}

export default EmptyCard