import React, { useState } from 'react'
import CustomerAuth from '../auth/customer/CustomerAuth'
import Navbar from '../../widgets/Navbar'
// import { Spring, Tour } from '../../assets/images'


const Maas = () => {
    // const navigate = useNavigate();
    const [state, setState] = useState({
        signInModal: false, signUpModal: false, phoneVerificationModal: false,
    })

    const hideScroll = () => {
        document.body.style.overflow = "hidden"
    }
    const unhideScroll = () => {
        document.body.style.overflow = "unset"
    }

    const openSignInModal = () => {
        setState((prevState) => ({ ...prevState, signInModal: true }))
        closePhoneVerificationModal()
        closeSignUpModal()
        hideScroll()
    }
    const closeSignInModal = () => {
        setState((prevState) => ({ ...prevState, signInModal: false }))
        unhideScroll()
    }


    const openSignUpModal = () => {
        setState((prevState) => ({ ...prevState, signUpModal: true }))
        closePhoneVerificationModal()
        closeSignInModal()
        hideScroll()
    }
    const closeSignUpModal = () => {
        setState((prevState) => ({ ...prevState, signUpModal: false }))
        unhideScroll()
    }


    const openPhoneVerificationModal = () => {
        setState((prevState) => ({ ...prevState, phoneVerificationModal: true, }))
        closeSignInModal()
        closeSignUpModal()
        hideScroll()
    }
    const closePhoneVerificationModal = () => {
        setState((prevState) => ({ ...prevState, phoneVerificationModal: false }))
        unhideScroll()
    }

    return (
        <>
            <Navbar openAuthModal={openSignInModal} />
            <main className={'maasContainer'}>
                {/* <img src={Spring} alt='spring' className={'maasFloatLeft'} /> */}
                <header>

                    <h5 className={'regularText textCenter'}>WE ARE STILL</h5>
                    <h2 className={'boldText  textCenter'}>Cooking our <span style={{ color: "#43A047" }}>website</span>.</h2>
                    <h4 className={'semiBoldText  textCenter'}>We will be launching our solution very soon, stay tuned</h4>
                </header>
                {/* <img src={Tour} alt='tour' className={'maasFloatRight'} /> */}
            </main>

            <CustomerAuth signIn={state.signInModal} signUp={state.signUpModal} phoneVerification={state.phoneVerificationModal}
                closePhoneVerification={closePhoneVerificationModal} closeSignIn={closeSignInModal} closeSignUp={closeSignUpModal}
                openPhoneVerificationModal={openPhoneVerificationModal} openSignInModal={openSignInModal} openSignUpModal={openSignUpModal}
            />
        </>

    )
}

export default Maas