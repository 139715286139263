import React from 'react'
import AboutVideoSection from './AboutVideoSection'
import AboutUsTextContainer from './AboutUsTextContainer'
import AboutSectionCardsContainer from './AboutSectionCardsContainer'
import AboutUsContactContainer from './AboutUsContactContainer'
import { TopLeft, TopRight } from '../../../assets/images'
import AboutUsFooterContainer from './AboutUsFooterContainer'

const About = ({ showVideo, showIntro, showCards }) => {
    return (
        <section className='aboutSection'>
            {showVideo &&
                <AboutVideoSection />}
            {showIntro &&
                <AboutUsTextContainer />}
            {showCards &&
                <AboutSectionCardsContainer />}
            <AboutUsContactContainer />
            <AboutUsFooterContainer />
        </section>
    )
}

export default About



