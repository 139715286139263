import React, { useEffect } from "react";
import RoutesContainer from "./routes/Routes";
import { useIntercom } from "react-use-intercom";

const App = () => {
  const { boot, shutdown, hide, show, update } = useIntercom();
  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === 'prod') {
      boot()
    }
  }, [boot])

  return (
    <main >
      <RoutesContainer />
    </main>
  );
};

export default App;
