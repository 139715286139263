import React, { useState, useCallback } from "react";
import AuthCard from "../../../components/auth/AuthCard";
import { motion, AnimatePresence } from "framer-motion";
import CustomInput from "../../../utils/CustomInput";
import CustomButton from "../../../utils/CustomButton";
// import { Amico } from "../../../assets/images";
import { vendorAuth } from "../../../redux/actions/AuthActions";
import { connect } from "react-redux";
import { showSuccess } from "../../../utils/Toast";

const VendorAuth = ({ vendorAuth, onDecline }) => {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        vendor_first_name: "",
        vendor_last_name: "",
        vendor_phone_number: "",
        vendor_email_address: "",
        business_name: "",
        business_address: "",
        business_email_address: "",
        business_country: "",
        business_state: "",
        business_city: "",
        business_phone_number: "",
    });

    const togglePage = useCallback((page) => {
        setPage(page);
    }, []);

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const joinWaitList = useCallback(async () => {
        setLoading(true);
        try {
            const res = await vendorAuth(formData);
            showSuccess("Successfully joined the wait list");
            setMessage(res.message);
            togglePage(3);
        } catch (error) {
            // Handle the error
        } finally {
            setLoading(false);
        }
    }, [formData, togglePage, vendorAuth]);

    const renderFormContent = () => {
        switch (page) {
            case 1:
                return (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ width: "100%" }}>
                        <AuthCard onDecline={onDecline}>
                            <section className={"vendorAuthContainer"}>
                                <div className={"progressBar"}>
                                    <p className={"boldText f14"} style={{ color: "#fff" }}>
                                        1
                                    </p>
                                    <span>
                                        <span className={"progressLength"} style={{ width: "0" }}></span>
                                    </span>
                                    <p className={"boldText f14"} style={{ color: "#fff" }}>
                                        2
                                    </p>
                                </div>
                                <div>
                                    <h2 className={"boldText textCenter"}>Create Account</h2>
                                    <p className={"regularText textCenter"}>Quickly set up your profile, provide us with the correct details below.</p>
                                </div>
                                <form className={"vendorFormContainer"}>
                                    <div>
                                        <CustomInput label={"First name*"} onChange={onChange} name={"vendor_first_name"} value={formData.vendor_first_name} />
                                    </div>
                                    <div>
                                        <CustomInput label={"Last name*"} onChange={onChange} name={"vendor_last_name"} value={formData.vendor_last_name} />
                                    </div>
                                    <div>
                                        <CustomInput label={"Mobile number*"} onChange={onChange} name={"vendor_phone_number"} value={formData.vendor_phone_number} />
                                    </div>
                                    <div>
                                        <CustomInput label={"Email address*"} onChange={onChange} name={"vendor_email_address"} value={formData.vendor_email_address} />
                                    </div>
                                </form>
                                <div className={"vendorBtnContainer"}>
                                    <CustomButton title={"Next"} onClick={() => togglePage(2)} />
                                </div>
                            </section>
                        </AuthCard>
                    </motion.div>
                );
            case 2:
                return (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ width: "100%" }}>
                        <AuthCard onDecline={onDecline} backBtn={true} onClick={() => togglePage(1)}>
                            <section className={"vendorAuthContainer"}>
                                <div className={"progressBar"}>
                                    <p className={"boldText f14"} style={{ color: "#fff" }}>
                                        1
                                    </p>
                                    <span>
                                        <span className={"progressLength"} style={{ width: "60px" }}></span>
                                    </span>
                                    <p className={"boldText f14"} style={{ color: "#fff" }}>
                                        2
                                    </p>
                                </div>
                                <div>
                                    <h2 className={"boldText textCenter"}>About your Business</h2>
                                    <p className={"regularText textCenter"}>Tell us about your business.</p>
                                </div>
                                <form className={"vendorFormContainer"} id={"height"}>
                                    <div>
                                        <CustomInput label={"Business name*"} onChange={onChange} name={"business_name"} value={formData.business_name} />
                                    </div>
                                    <div>
                                        <CustomInput label={"Business address*"} onChange={onChange} name={"business_address"} value={formData.business_address} />
                                    </div>
                                    <div>
                                        <CustomInput label={"Business phone number*"} onChange={onChange} name={"business_phone_number"} value={formData.business_phone_number} />
                                    </div>
                                    <div>
                                        <CustomInput label={"Business email address*"} onChange={onChange} name={"business_email_address"} value={formData.business_email_address} />
                                    </div>
                                    <div>
                                        <CustomInput label={"Business country*"} onChange={onChange} name={"business_country"} value={formData.business_country} />
                                    </div>
                                    <div>
                                        <CustomInput label={"Business state*"} onChange={onChange} name={"business_state"} value={formData.business_state} />
                                    </div>
                                    <div>
                                        <CustomInput label={"Business city*"} onChange={onChange} name={"business_city"} value={formData.business_city} />
                                    </div>
                                </form>
                                <div className={"vendorBtnContainer"}>
                                    <CustomButton title={"Submit"} onClick={joinWaitList} loading={loading} />
                                </div>
                            </section>
                        </AuthCard>
                    </motion.div>
                );
            case 3:
                return (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ width: "100%" }}>
                        <AuthCard onDecline={onDecline}>
                            <section className={"vendorAuthContainer"}>
                                <div className={"progressBar"}>
                                    <p className={"boldText f14"} style={{ color: "#fff" }}>
                                        1
                                    </p>
                                    <span>
                                        <span className={"progressLength"} style={{ width: "120px" }}></span>
                                    </span>
                                    <p className={"boldText f14"} style={{ color: "#fff", backgroundColor: "#ffa024" }}>
                                        2
                                    </p>
                                </div>
                                <div>
                                    <h2 className={"boldText textCenter"}>Congratulations</h2>
                                    <p className={"regularText textCenter"}>{message}</p>
                                </div>
                                <div className={"vendorAuthCompletedImageContainer"}>
                                    {/* <img src={Amico} alt="amico" /> */}
                                </div>
                                <div className={"vendorBtnContainer"}>
                                    <p className={"regularText textCenter"} style={{ fontSize: "18px", cursor: "pointer" }} onClick={onDecline}>
                                        Return home
                                    </p>
                                </div>
                            </section>
                        </AuthCard>
                    </motion.div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <section className={"vendorAuthModal"}>
                <section style={{ width: "100%", height: "auto" }}>
                    <AnimatePresence>{renderFormContent()}</AnimatePresence>
                </section>
            </section>
        </>
    );
};

export default connect(null, { vendorAuth })(VendorAuth);
