import { useEffect } from 'react';

const useKeyboardAwareModal = () => {
    useEffect(() => {
        const adjustModalPosition = () => {
            const modalContent = document.querySelector('.riderAgentModalContent');
            if (window.innerHeight < 600) {
                modalContent.style.transform = 'translateY(-50px)';
            } else {
                modalContent.style.transform = 'translateY(0)';
            }
        };

        window.addEventListener('resize', adjustModalPosition);

        return () => {
            window.removeEventListener('resize', adjustModalPosition);
        };
    }, []);
};

export default useKeyboardAwareModal;
