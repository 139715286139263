import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { GOG, IOS } from '../../assets/images'
import ImageWithPreloader from '../../utils/CustomImage'

const ServicesCard = ({ title, description, src, iosClick, playClick }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    return (
        <div className={'servicesCardContainer'}>
            <header>
                <h3 className={`boldText ${isDesktopOrLaptop ? 'f24' : 'f20'}`}>{title}</h3>
                <p className={`lightText ${isDesktopOrLaptop ? 'f16' : 'f14'}`} >{description}</p>
            </header>
            <div className={'serviceCardImageContainer'}>
                <ImageWithPreloader src={src} alt={'image'} />
                {/* <img src={src} alt='image' /> */}
                <div className={'serviceCardOverlay'}>
                    <div className={'animate__animated animate__slideInLeft'}>
                        <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710284682999'} alt='app_store' style={{ width: '100%', height: "100%", objectFit: "contain", cursor: "pointer", }} onClick={iosClick} />
                    </div>
                    <div className={'animate__animated animate__slideInRight'}>
                        <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340589868'} alt='play_store' style={{ width: '100%', height: "100%", objectFit: "contain", cursor: "pointer", }} onClick={playClick} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesCard