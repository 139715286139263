import React, { useState } from 'react';
import { FooterBikeTabIcon, FooterChefTabIcon, FooterFoodTabIcon, GOG, IOS } from '../assets/images';
import { showWarning } from '../utils/Toast';
import mixpanel from 'mixpanel-browser';
import { trackEvent } from '../utils/Mixpanel';

const FooterTab = () => {
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const customerIosStore = () => {
        window.open('https://apps.apple.com/us/app/dado-food/id6444361467', '_blank', 'noreferrer');
        trackEvent('clicks download app', {
            appType:'customer-app',
            version:'ios',
            location: 'footer-section'
          })
    }
    const customerPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.dado.ng', '_blank', 'noreferrer');
        trackEvent('clicks download app', {
            appType:'customer-app',
            version:'android',
            location: 'footer-section'
          })
    }

    const riderIosStore = () => {
        window.open('https://apps.apple.com/ng/app/dado-rider/id6448981515', '_blank', 'noreferrer');
        trackEvent('clicks download app', {
            appType:'rider-app',
            version:'ios',
            location: 'footer-section'
          })
    }
    const riderPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=rider.dado.ng', '_blank', 'noreferrer');
        trackEvent('clicks download app', {
            appType:'rider-app',
            version:'android',
            location: 'footer-section'
          })
    }


    const vendorIosStore = () => {
        // window.open('https://apps.apple.com/us/app/dado-food/id6444361467', '_blank', 'noreferrer');
        showWarning('Currently unavailable')
        trackEvent('clicks download app', {
            appType:'vendor-app',
            version:'ios',
            location: 'footer-section'
          })
    }
    const vendorPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=vendor.dado.ng', '_blank', 'noreferrer');
        showWarning('Currently unavailable')
        trackEvent('clicks download app', {
            appType:'vendor-app',
            version:'android',
            location: 'footer-section'
          })

    }

    return (
        <div>
            <div className="storeLinksTab">
                <button
                    className={activeTab === 'tab1' ? 'active-tab' : ''}
                    onClick={() => handleTabChange('tab1')}
                >
                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710339597692'} style={{ width: "100%", height: '100%', objectFit: 'contain' }}  alt='icon'/>
                </button>
                <button
                    className={activeTab === 'tab2' ? 'active-tab' : ''}
                    onClick={() => handleTabChange('tab2')}
                >
                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340036739'} style={{ width: "100%", height: '100%', objectFit: 'contain' }} alt='icon' />
                </button>
                <button
                    className={activeTab === 'tab3' ? 'active-tab' : ''}
                    onClick={() => handleTabChange('tab3')}
                >
                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710339469760'} style={{ width: "100%", height: '100%', objectFit: 'contain' }}  alt='icon'/>
                </button>
            </div>
            <div className="storeLinksContent">
                {activeTab === 'tab1' && <div>
                    <p className={'f12 regularText textCenter'} style={{ color: '#F9F9FB', marginBottom: '8px' }}>- GET DADO CUSTOMER -</p>
                    <div className={'flexRow alignCenter'} style={{ gap: "10px", }}>
                        <button onClick={customerPlayStore}>
                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340589868'} alt='appStore' />
                        </button>
                        <button onClick={customerIosStore}>
                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340617282'} alt='appStore' />
                        </button>
                    </div>
                </div>}
                {activeTab === 'tab2' && <div>
                    <p className={'f12 regularText textCenter'} style={{ color: '#F9F9FB', marginBottom: '8px' }}>- GET DADO VENDOR -</p>
                    <div className={'flexRow alignCenter'} style={{ gap: "10px" }}>
                        <button onClick={vendorPlayStore}>
                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340589868'} alt='appStore' />
                        </button>
                        <button onClick={vendorIosStore}>
                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340617282'} alt='appStore' />
                        </button>
                    </div>
                </div>}
                {activeTab === 'tab3' && <div>
                    <p className={'f12 regularText textCenter'} style={{ color: '#F9F9FB', marginBottom: '8px' }}>- GET DADO RIDER -</p>
                    <div className={'flexRow alignCenter'} style={{ gap: "10px" }}>
                        <button onClick={riderPlayStore}>
                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340589868'} alt='appStore' />
                        </button>
                        <button onClick={riderIosStore}>
                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340617282'} alt='appStore' />
                        </button>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default FooterTab;
