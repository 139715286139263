import { track } from "mixpanel-browser";
import React, { useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { trackEvent } from "../../utils/Mixpanel";

const FaqAccordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    trackEvent('clicked_on_faq', {
      content: {
        title:title,
        answer:content
      }
    })
  };

  return (
    <div className={`faqAccordion ${isOpen ? "faqOpen" : ""}`}>
      <div className={` faqAccordionToolTip ${isOpen ? 'answerToolTip' : 'questionToolTip'}`}>
        {isOpen ? 'A' : 'Q'}
      </div>
      <div className="faqAccordionHeader boldText" onClick={toggleAccordion} style={{ borderRadius: isOpen ? "24px 24px 0 0" : "24px", background: isOpen ? "#E8F5E9" : "#F9F9FB" }}>
        {title}
        <span>{isOpen ? <BiMinus color="rgba(54, 225, 218, 1)" /> : <BiPlus color="rgba(54, 225, 218, 1)" />}</span>
      </div>
      {isOpen && <div className="faqAccordionContent">{isOpen && <p className={"regularText"}>{content}</p>}</div>}
    </div>
  );
};

export default FaqAccordion;
