import React, { useEffect, useRef, useState, useCallback } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer, signOut } from '../redux/actions/AuthActions';
import OrdersCard from '../components/customers/OrdersCard';
import { getCart, getOrders } from '../redux/actions/CustomerActions';
import { showWarning } from '../utils/Toast';
import { shortenXterLength } from '../utils/Helpers';
import { ArrowDown2, EmptyWallet, HambergerMenu, Location, Logout, SearchNormal1, ShoppingCart, User } from 'iconsax-react';
import { useMediaQuery } from 'react-responsive';
import CustomButton from '../utils/CustomButton';
import '../assets/styles/widgetStyles.css';
import '../assets/styles/generalStyles.css';
import '../assets/styles/textStyles.css';

const HomeNavbar = ({ bgColor, openAuthModal, showSearch, onClick, placeHolder, onChange, value }) => {
    const [mobileNav, setMobileNav] = useState(false);
    const [cart, setCart] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loggedIn, userData } = useSelector(state => state.auth);
    const { addressDetails } = useSelector(state => state.search);
    const isTabletOrMobile = useMediaQuery({ query: '(min-width: 768px)' });

    const firstName = userData?.customer?.firstName?.charAt(0).toUpperCase();
    const lastName = userData?.customer?.lastName?.charAt(0).toUpperCase();

    const user = useRef(null);

    useEffect(() => {
        user.current = localStorage.getItem('accessToken');
        if (loggedIn) {
            dispatch(getCustomer());
        }
    }, [dispatch, loggedIn]);

    const toggleMobileNav = useCallback(() => setMobileNav(prevState => !prevState), []);

    const goToWallet = useCallback(() => navigate('/wallet'), [navigate]);

    const toggleCart = useCallback(() => {
        if (!loggedIn) {
            return showWarning('Login to view orders history');
        }
        dispatch(getOrders());
    }, [dispatch, loggedIn]);

    const openCart = () => {
        setCart(true)
        toggleCart()
    };
    const closeCart = () => {
        setCart(false);
    };

    const logout = useCallback(() => {
        dispatch(signOut());
        dispatch(getCart());
        setMobileNav(false);
    }, [dispatch]);

    const toggleSignIn = useCallback(() => {
        openAuthModal();
        setMobileNav(false);
    }, [openAuthModal]);

    const returnAddress = useCallback(() => (
        addressDetails ? shortenXterLength(addressDetails?.address, 25) : 'Enter Address'
    ), [addressDetails]);

    const renderHomeBtn = () => (
        <Link to={location.pathname === '/home' ? '/' : '/home'}>
            <img src='https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402377848' alt='logo' className='logoBtn' />
        </Link>
    );

    return (
        <nav className='homeNavContainer' style={{ backgroundColor: '#fff' }}>
            <section className='homeNavContent'>
                <section className='leftNavContainer'>
                    <div className='flexRow alignCenter' style={{ gap: '20px' }}>
                        {renderHomeBtn()}
                        <div className='flexRow alignCenter homeNavLocationContainer' onClick={onClick} style={{ cursor: 'pointer' }}>
                            <Location size="14" color="#FFF" />
                            <span className='addressBtn regularText capitalize'>{returnAddress()}</span>
                            <ArrowDown2 size="14" color="#FFF" />
                        </div>
                    </div>
                    <div>
                        <div className='homeNavMobileBtn' onClick={toggleMobileNav}>
                            {loggedIn ? (
                                <div className='userAvatar mobileAvatar'>
                                    {firstName && lastName ? (
                                        <p className='boldText'>{firstName}{lastName}</p>
                                    ) : (
                                        <AiOutlineUser color='#FFA024' />
                                    )}
                                </div>
                            ) : (
                                <HambergerMenu size="30" color="#04d9d1" variant='Bold' />
                            )}
                        </div>
                        {mobileNav && (
                            <div className='avatarDropDown'>
                                {loggedIn && (
                                    <>
                                        <div className={'flexRow alignCenter'} style={{ gap: '10px' }}>
                                            <User size="20" color="#000" />
                                            <div className='dropDownName flexRow alignCenter' style={{ gap: '10px' }}>
                                                <p className='boldText capitalize'>
                                                    {userData?.customer?.firstName} -
                                                </p>
                                                <p className='regularText phoneNumber'>{userData?.customer?.phone}</p>
                                            </div>
                                        </div>


                                    </>
                                )}
                                {!isTabletOrMobile && (
                                    <>
                                        {showSearch && (
                                            <div className='navSearch'>
                                                <div className='searchBarContainer'>
                                                    <SearchNormal1 size="16" color="#000" />
                                                    <input
                                                        placeholder={placeHolder}
                                                        style={{ width: '90%', outline: 'none' }}
                                                        onChange={onChange}
                                                        name='query'
                                                        value={value}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className='cartIcon' >
                                            <div className={'flexRow alignCenter '} style={{ gap: '10px' }} onClick={openCart}>
                                                <ShoppingCart size='20' color="#000" />
                                                <p className={'regularText f14'}>Track Orders</p>
                                            </div>
                                            {cart && <OrdersCard closeCart={closeCart} />}
                                        </div>
                                        <div className={'flexRow alignCenter '} style={{ gap: '10px' }} onClick={goToWallet} >
                                            <EmptyWallet size='20' color="#000" />
                                            <p className={'regularText f14'}>Wallet</p>
                                        </div>
                                    </>
                                )}
                                {loggedIn ? (
                                    <>
                                        <div className={'logout flexRow alignCenter '} style={{ gap: '10px' }} onClick={logout}>
                                            <Logout size="20" color="#000" />
                                            <p className='regularText f14'>Logout</p>
                                        </div>
                                    </>
                                ) : (
                                    <CustomButton title='Sign in' onClick={toggleSignIn} customStyle={'homeNavSignInBtn'} />
                                )}
                            </div>
                        )}
                    </div>
                </section>
                {isTabletOrMobile && (
                    <section className='rightNavContainer'>
                        {showSearch && (
                            <div className='navSearch'>
                                <div className='searchBarContainer'>
                                    <SearchNormal1 size="16" color="#000" />
                                    <input
                                        placeholder={placeHolder}
                                        style={{ width: '90%', outline: 'none' }}
                                        onChange={onChange}
                                        name='query'
                                        value={value}
                                    />
                                </div>
                            </div>
                        )}
                        <div className='cartIcon' >
                            <div className={'homeNavIconContainer'} onClick={openCart}>
                                <ShoppingCart size='28' color="#04d9d1" />
                            </div>
                            {cart && <OrdersCard closeCart={closeCart} />}
                        </div>
                        <div className={'homeNavIconContainer'}>
                            <EmptyWallet size='28' color="#04d9d1" onClick={goToWallet} />
                        </div>
                    </section>
                )}
            </section>
        </nav>
    );
};

export default HomeNavbar;
