// // utils/mixpanel.js
// import mixpanel from 'mixpanel-browser';

// const initializeMixpanel = () => {
//   const environment = process.env.REACT_APP_ENV;
//   console.log(environment, 'env')
//   if (environment === 'prod') {
//     mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
//       debug: false,
//       test: true,
//       secure_cookie: true
//     });
//   }else {
//     console.log('I am the issue')
//   }
// };

// export const trackEvent = (eventName, eventData) => {
//   const environment = process.env.REACT_APP_ENV;
//   if (environment === 'prod') {
//     mixpanel.track(eventName, eventData);
//   }
//   return null
// };

// export default initializeMixpanel;

// utils/mixpanel.js
import mixpanel from 'mixpanel-browser';

let isMixpanelInitialized = false;
const environment = process.env.REACT_APP_ENV;

const initializeMixpanel = () => {
  console.log(environment, 'env');
  if (environment === 'prod') {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
      debug: false,
      test: true,
      secure_cookie: true
    });
    isMixpanelInitialized = true;
  } else {
    console.log('Mixpanel not initialized: Not in production environment');
  }
};

export const trackEvent = (eventName, eventData) => {
  if (isMixpanelInitialized && environment !== 'dev') {
    mixpanel.track(eventName, eventData);
  } else {
    console.warn(`Mixpanel event "${eventName}" not tracked: Mixpanel not initialized`);
  }
};

export default initializeMixpanel;
