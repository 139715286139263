import React, { useState } from "react";
import "../../assets/styles/modalStyles.css";
import { AiOutlineClose } from "react-icons/ai";
import { DeliveryBottomImage } from "../../assets/images";
import { useDispatch } from "react-redux";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { getAllRestaurants, getCategories, getTrendingRestaurants } from "../../redux/actions/CustomerActions";
import CustomButton from "../../utils/CustomButton";
import { UPDATE_ADDRESS } from "../../redux/Types";
import http from "../../utils/Utils";

const AddressModal = (props) => {
    const dispatch = useDispatch();
    const { onDecline } = props;


    const [userAddress, setAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const disabled = !userAddress

    const getAddressDetails = async (val) => {
        setLoading(true);
        const address = val?.label;
        // console.log(address)
        const obj = { address };
        try {
            const res = await http.post(`get_address_details`, obj);
            const address = res?.data?.location?.givenAddress;
            const formatted_address = res?.data?.location?.formattedAddress;
            const lat = res?.data?.location?.latitude;
            const lng = res?.data?.location?.longitude;
            const city = res?.data?.location?.city;
            const country = res?.data?.location?.country;
            const state = res?.data?.location?.state;
            const addressData = { address, formatted_address, lat, lng, city, country, state };
            dispatch({ type: UPDATE_ADDRESS, payload: addressData });
            setLoading(false);
            dispatch(getAllRestaurants(1, 30, lat, lng));
            dispatch(getCategories());
            dispatch(getTrendingRestaurants(1, 30, lat, lng));
            localStorage.setItem("location", address);
            // console.log(addressData, res)
            onDecline();
        } catch (error) {
            // console.log(error)
            setLoading(false);
        }
    };

    const proceed = () => {
        getAddressDetails(userAddress);
    };

    return (
        <>
            {props.visible && (
                <section className={"modalContainer"}>
                    <div className={"modalContentContainer animate__animated animate__slideInUp"}>
                        <div className={"modalCloseBtn"} onClick={props.onDecline}>
                            <AiOutlineClose size={20} />
                        </div>
                        <div>
                            <h4 className={"boldText textCenter"}>Change Delivery address</h4>
                            <p className={"regularText textCenter"}>Enter address to view vendors around you</p>
                        </div>
                        <div>
                            <p className={"regularText "} style={{ fontSize: "16px", marginBottom: "8px" }}>
                                Address
                            </p>
                            <GooglePlacesAutocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                selectProps={{
                                    userAddress,
                                    onChange: setAddress,
                                    styles: {
                                        control: (provided) => ({
                                            ...provided,
                                            border: "1px solid #EBEBEB",
                                            borderRadius: "8px",
                                            outline: "none",
                                            hover: "none",
                                            padding: "8px 12px",
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            color: " #999CA0",
                                            fontFamily: "Nunito",
                                            fontSize: "14px",
                                        }),
                                    },
                                    placeholder: "Enter delivery address",
                                }}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ["ng"],
                                    },
                                }}
                            />
                        </div>
                        <div className={"locationBtn"}>
                            <CustomButton title={"Continue"} onClick={proceed} loading={loading} disabled={disabled} />
                        </div>
                        <div className={"modalBottomImage"}>
                            <img src={DeliveryBottomImage} alt="borderImage" />
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default AddressModal;
