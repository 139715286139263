import React from "react";
import { useMediaQuery } from "react-responsive";
import HeroCarousel from "./HeroCarousel";
import ImageWithPreloader from "../../utils/CustomImage";
import mixpanel from "mixpanel-browser";
import { trackEvent } from "../../utils/Mixpanel";


const HeroSection = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const returnImageUrl = () => {
    if (isDesktopOrLaptop) {
      return 'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402290336'
    }
    return 'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402238828'
  }

  const customerIosStore = () => {
    window.open('https://apps.apple.com/us/app/dado-food/id6444361467', '_blank', 'noreferrer');
    trackEvent('clicks_download_app', {
      appType:'customer-app',
      version:'ios',
      location: 'hero-section'
    })
  }
  const customerPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.dado.ng', '_blank', 'noreferrer');
    trackEvent('clicks_download_app', {
      appType:'customer-app',
      version:'android',
      location: 'hero-section'
    })
  }
  return (
    <section className={"heroSection"}>
      <section className={"content-container"}>
      <header>
        <h2 className={`boldText`} >
          <div className={"heroVector"}>
            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710423994045'} alt="hero" />
          </div>
          Delicious meals delivered to you <span style={{ color: "#F4743B" }}>fast</span> and
          <span style={{ color: "#F4743B" }}> safely.</span>
        </h2>
        <p className={`regularText ${isDesktopOrLaptop ? 'f20' : 'f16'}`}>Order from vendors in your city, and get record time delivery</p>
      </header>
      <div className="flexRow alignCenter justifyCenter" style={{ gap: isDesktopOrLaptop ? '30px' : '0' }}>
        <aside>
          <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285333940'} alt='app_store' style={{ width: isDesktopOrLaptop ? '100%' : '90%', height: "100%", objectFit: "contain", cursor: "pointer" }} onClick={customerIosStore} />
        </aside>
        <aside>
          <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285376319'} alt='play_store' style={{ width: isDesktopOrLaptop ? '100%' : '90%', height: "100%", objectFit: "contain", cursor: "pointer" }} onClick={customerPlayStore} />
        </aside>
      </div>

      {!isDesktopOrLaptop &&
        <div className={"heroSlideContainer"} style={{ zIndex: '5', padding:'20px 0' , height:'600px'}}>
          <HeroCarousel />
        </div>}
      <div className="heroMockContainer" >
        <ImageWithPreloader src={returnImageUrl()} objectFit={'contain'} />
      </div>
      </section>
    </section>
  );
};

export default HeroSection;

