import React from 'react';
import './utils.css';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'iconsax-react';

const BackComponent = (props) => {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }
    return (
        <div onClick={goBack} className={'backComponentContainer'} style={{ cursor: 'pointer' }}>
            <ArrowLeft size="20" color="#000" />
        </div>
    )
}

export default BackComponent