import React from 'react'
import Skeleton from 'react-loading-skeleton'

const MiniFoodCards = (props) => {

    return (

        <>
            {props.loading ? <div className={'miniFoodCardContainer'} key={props.index}>
                <div>
                    <Skeleton height={'80px'} />
                </div>
                <div className={'miniFoodContent'}>
                    <p className={'boldText'}>
                        <Skeleton />
                    </p>
                    {/* <p className={'mediumText'}>
                        <Skeleton />
                    </p> */}
                </div>
            </div> :
                <div className={'miniFoodCardContainer'} onClick={props.onClick} key={props.index}>
                    <div>
                        <img src={props.img} alt={'poster'} style={{ borderRadius: '8px' }}
                            loading={'lazy'}
                        />
                    </div>
                    <div className={'miniFoodContent'}>
                        <p className={'semiBoldText'}>{props.title}</p>
                        {/* <p className={'mediumText'}>{props.subTitle}</p> */}
                    </div>
                </div>}
        </>

    )
}

export default MiniFoodCards