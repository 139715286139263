import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatAmount } from '../../utils/Helpers';
import { Files } from '../../assets/images';
import { useMediaQuery } from 'react-responsive';

const WalletTable = () => {
    const types = ['All', 'Refunds', 'Expenses'];
    const { gettingWallet, wallet, walletErrors } = useSelector(state => state.customer);
    const [activeTab, setActiveTab] = useState('All');
    // console.log(wallet, 'wallet')

    const isDesktop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const renderTabs = () => {
        return types.map((item, index) => (
            <li
            key={index}
            className={`regularText ${isDesktop ? 'f14' : 'f10'} ${item === activeTab ? 'tagItem' : ''}`}
            onClick={() => handleTabChange(item)}
            style={{ cursor: 'pointer' }}
          >
            {item}
          </li>
        ));
    };

    const renderTransactions = (activeTab) => {
        const filteredTransactions = wallet?.wallet?.transactionHistory?.transactions?.filter(
            item => activeTab === 'All' ||
                (activeTab === 'Refunds' && item.transactionType === 'CREDIT') ||
                (activeTab === 'Expenses' && item.transactionType === 'DEBIT')
        );

        if (!filteredTransactions || filteredTransactions.length === 0) {
            return (
                <div style={{ flexDirection: 'column', gap: '20px',alignItems:'center' }} className='flexRow'>
                    <img src={Files} alt='files' style={{height:'150px', width:'150px', objectFit:'contain'}}/>
                    <p style={{ textAlign: 'center', padding: '0 0 40px 0' }} className={'f18'}>
                        No Transactions
                    </p>
                </div>

            );
        }

        return filteredTransactions.map((item, index) => (
            <li key={index} className={'walletTableItem f14 regularText'}>
                <span id={'hidden'}>{item?.id}</span>
                <span>{item?.vendorName}</span>
                <span >{item?.transactionType === 'CREDIT' ? 'Refund' : 'Expense'}</span>
                <span>₦{formatAmount(item?.amount)}</span>
                <span id={'hidden'}>{item?.createdAt}</span>
            </li>
        ));
    };

    return (
        <section className='walletTable'>
            <header>
                <h3>Transaction history</h3>
                <ul style={{ gap: '12px' }}>{renderTabs()}</ul>
            </header>
            <ul className={'subHeader'}>
                <li className={` ${isDesktop ? 'f14' : 'f10'}`} id={'hidden'}>ID</li>
                <li className={` ${isDesktop ? 'f14' : 'f10 textCenter'}`}>VENDOR NAME</li>
                <li className={` ${isDesktop ? 'f14' : 'f10 textCenter'}`}>TRANSACTION TYPE</li>
                <li className={` ${isDesktop ? 'f14' : 'f10 textCenter'}`}>AMOUNT</li>
                <li className={` ${isDesktop ? 'f14' : 'f10'}`} id={'hidden'}>DATE & TIME</li>

            </ul>

            <ul>{renderTransactions(activeTab)}</ul>
        </section>
    );
};

export default WalletTable;
