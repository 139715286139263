import React, { useState } from "react";
import "../../../assets/styles/homeStyles.css";
import AuthCard from "../../../components/auth/AuthCard";
import CustomInput from "../../../utils/CustomInput";
import CustomButton from "../../../utils/CustomButton";
// import { Amico } from "../../../assets/images";
import { ridersAuth } from "../../../redux/actions/AuthActions";
import { connect } from "react-redux";
import { showError, showSuccess } from "../../../utils/Toast";

const CourierAuth = (props) => {
    const [state, setState] = useState({
        page: 1,
        rider_first_name: "",
        rider_last_name: "",
        rider_phone_number: "",
        rider_email_address: "",
        loading: false,
    });

    const togglePage = (page) => {
        setState((prevState) => ({ ...prevState, page }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const joinWaitList = async () => {
        const { rider_email_address, rider_first_name, rider_last_name, rider_phone_number } = state;
        const obj = {
            rider_email_address,
            rider_first_name,
            rider_last_name,
            rider_phone_number,
        };

        setState((prevState) => ({ ...prevState, loading: true }));

        try {
            const res = await props.ridersAuth(obj);
            showSuccess("Successfully joined the wait list");
            setState((prevState) => ({ ...prevState, loading: false, message: res.message }));
            togglePage(2);
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false }));
            if (error[1][0]?.param === "rider_email_address") {
                showError("Please enter a valid email");
            } else if (error[1][0]?.param === "rider_phone_number") {
                showError("Please enter a valid phone number");
            } else {
                const errorMessage = error[1]?.message || "An error occurred";
                showError(errorMessage);
            }
        }
    };

    const renderPageOne = () => (
        <AuthCard onDecline={props.onDecline}>
            <section className={"vendorAuthContainer"}>
                <div>
                    <h2 className={"boldText textCenter"}>Create Account</h2>
                    <p className={"regularText textCenter"}>Quickly set up your profile, provide us with the correct details below.</p>
                </div>
                <form className={"vendorFormContainer"}>
                    <div>
                        <CustomInput label={"First name*"} onChange={handleChange} name={"rider_first_name"} value={state.rider_first_name} />
                    </div>
                    <div>
                        <CustomInput label={"Last name*"} onChange={handleChange} name={"rider_last_name"} value={state.rider_last_name} />
                    </div>
                    <div>
                        <CustomInput label={"Mobile number*"} onChange={handleChange} name={"rider_phone_number"} value={state.rider_phone_number} />
                    </div>
                    <div>
                        <CustomInput label={"Email address*"} onChange={handleChange} name={"rider_email_address"} value={state.rider_email_address} />
                    </div>
                </form>
                <div className={"vendorBtnContainer"}>
                    <CustomButton title={"Submit"} onClick={joinWaitList} loading={state.loading} />
                </div>
            </section>
        </AuthCard>
    );

    const renderPageTwo = () => (
        <AuthCard onDecline={props.onDecline}>
            <section className={"vendorAuthContainer"}>
                <div>
                    <h2 className={"boldText textCenter"}>Congratulations</h2>
                    <p className={"regularText textCenter"}>{state.message}</p>
                </div>
                <div className={"vendorAuthCompletedImageContainer"}>
                    {/* <img src={Amico} alt="amico" /> */}
                </div>
                <div className={"vendorBtnContainer"}>
                    <p className={"regularText textCenter"} style={{ fontSize: "18px", cursor: "pointer" }} onClick={props.onDecline}>
                        Return home
                    </p>
                </div>
            </section>
        </AuthCard>
    );

    return (
        <section className={"vendorAuthModal"}>
            <section style={{ width: "100%" }}>
                {state.page === 1 && renderPageOne()}
                {state.page === 2 && renderPageTwo()}
            </section>
        </section>
    );
};

export default connect(null, { ridersAuth })(CourierAuth);
