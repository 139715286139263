import React from 'react';
import './utils.css';
import '../assets/styles/textStyles.css';
import '../assets/styles/generalStyles.css';
const CustomInput = (props) => {

    const { label, type, name, value, onChange, placeholder, disabled, maxLength, customLabel, inputStyle, customInputContainer, multiple, ref, key } = props

    return (
        <>
            <div className={`inputContainer ${customInputContainer}`}>
                <label className={`f14 grey semiBoldText flex alignCenter ${customLabel}`} >
                    {label}
                </label>
                <input type={type} name={name} value={value} onChange={onChange}
                    placeholder={placeholder} className={`input ${inputStyle}`}
                    disabled={disabled} maxLength={maxLength} multiple={multiple} ref={ref}
                    key={key}
                />
            </div>
        </>
    )
}

export default CustomInput