import moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import '../../assets/styles/customersStyles.css';

const LargeImageCard = (props) => {

    const now = moment()
    const start = moment().hour(9).minute(0); // set start time to 5pm
    const end = moment().hour(18).minute(0);

    const renderOverlay = () => {
        if (!now.isBetween(start, end)) {
            return (
                <div className={'largeImageCardOverlayClosed'}>
                    <p style={{ color: '#F9F9FB' }} className={"f20 regularText"}>Store closed, Opens by 9am</p>
                </div>
            )
        } else {
            return (
                <div className={'largeImageCardOverlay'} />
            )
        }
    }
    return (
        <>

            {props.loading ?
                <div className={'largeImageCardContainer'}>
                    <Skeleton style={{ width: '100%', height: '320px', borderRadius: '16px' }} />
                </div> :
                <div className={'largeImageCardContainer'}>
                    <img src={props?.img} alt={'foodImage'} style={{ width: '100%', }} />
                    {renderOverlay()}
                </div>}
        </>
    )
}

export default LargeImageCard