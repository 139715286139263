import axios from "axios";
import config from "../config";

// Function to get the token based on the API version
const getUserToken = (version) => {
    try {
        const tokenKey = version === 'v2' ? 'v2AccessToken' : 'v1AccessToken';
        let accessToken = localStorage.getItem(tokenKey);
        // console.log(accessToken,'weus')
        return accessToken;
    } catch (error) {
        return error;
    }
};

// Function to set Authorization headers if the token exists
const setTokenIfExists = (options, version) => {
    const accessToken = getUserToken(version);

    if (accessToken === null) {
        return;
    }

    if (!options.headers) {
        options.headers = {};
    }

    options.headers.Authorization = `Bearer ${accessToken}`;
};

// HTTP utility object to handle requests
const http = {
    async request(url, method, data, options = {}, apiVersion = "v1") {
        const apiRootUrl = apiVersion === "v1" ? config.apiUrlV1 : config.apiUrlV2;

        return new Promise(async (resolve, reject) => {
            setTokenIfExists(options, apiVersion); // Pass apiVersion to setTokenIfExists
            try {
                const response = await axios({
                    method,
                    url,
                    baseURL: apiRootUrl,
                    data,
                    ...options,
                });
                resolve(response.data);
            } catch (error) {
                let message = "";
                let errors = [];
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            errors = error.response.data;
                            break;
                        case 401:
                            message = "You are not authorized to access this resource.";
                            errors = error.response.data;
                            break;
                        case 403:
                            message = "You are not authorized to access this resource.";
                            errors = error.response?.data;
                            break;
                        case 404:
                            message = "This resource could not be found. Please check the request and try again.";
                            errors = error.response?.data;
                            break;
                        case 409:
                            message = "This resource could not be found. Please check the request and try again.";
                            errors = error.response?.data;
                            break;
                        case 500:
                            message = `An error occurred on the server. Please try again later or contact support if error persists.`;
                            errors = error.response?.data;
                            break;
                        case 503:
                            message = "Server down, please contact support";
                            errors = error.response?.data;
                            break;
                        case 504:
                            message = "Server down, please contact support";
                            errors = error.response?.data;
                            break;
                        default:
                            message = "An unexpected error has occurred. Please, try again.";
                            break;
                    }
                } else if (error.request) {
                    message = "No response from server. Please try again later or contact support if error persists.";
                } else {
                    message = "An unexpected error occurred. Please, check your internet and try again.";
                }
                const arr = [message, errors];
                reject(arr);
            }
        });
    },

    // Simplified request methods for each HTTP verb
    get(url, params = {}, apiVersion = "v1") {
        const options = { params };
        return this.request(url, "GET", null, options, apiVersion);
    },
    post(url, data, isJson = true, options = {}, apiVersion = "v1") {
        if (!isJson) {
            options.headers = { "Content-Type": "multipart/form-data" };
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => formData.append(key, value));
            data = formData;
        }
        return this.request(url, "POST", data, options, apiVersion);
    },
    put(url, data, isJson = true, options = {}, apiVersion = "v1") {
        if (!isJson) {
            options.headers = { "Content-Type": "multipart/form-data" };
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => formData.append(key, value));
            data = formData;
        }
        return this.request(url, "PUT", data, options, apiVersion);
    },
    uploadFile(url, file, options = {}, apiVersion = "v1") {
        return this.post(url, { file, ...options }, false, {}, apiVersion);
    },
    delete(url, data, apiVersion = "v1") {
        return this.request(url, "DELETE", data, {}, apiVersion);
    },
};

export default http;
