import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, } from "swiper/modules";
import { BoostRevenue, OrderWithEase, WorkAndEarn } from "../../assets/images";

const HeroCarousel = () => {

  return (
    <>
      <Swiper
        autoplay={{
          delay: 1200,
          disableOnInteraction: false,
        }}
        navigation={false}
        loop={true}
        modules={[Autoplay]}
        className="heroSwiper"
        direction="vertical"

      // mousewheel={true}
      >
        <SwiperSlide>
          <img src={OrderWithEase} alt="order with ease"
            style={{ width: '100%', objectFit: 'contain', margin: 'auto' }}
          />
        </SwiperSlide>
        <SwiperSlide >
          <img src={BoostRevenue} alt="order with ease"
            style={{ width: '100%', objectFit: 'contain', margin: 'auto' }}
          />
        </SwiperSlide>
        <SwiperSlide >
          <img src={WorkAndEarn} alt="order with ease"
            style={{ width: '100%', objectFit: 'contain', margin: 'auto' }}
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HeroCarousel;
