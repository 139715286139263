import React, { useEffect, useState } from 'react';
import { DeleteOrder } from '../../assets/images';
import CustomButton from '../../utils/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import http from '../../utils/Utils';
import { showSuccess, showWarning } from '../../utils/Toast';
import { getCart } from '../../redux/actions/CustomerActions';
import { useNavigate } from 'react-router-dom';
import { formatAmount } from '../../utils/Helpers';
import moment from 'moment';
import CounterLoader from '../../utils/CounterLoader';
import mixpanel from 'mixpanel-browser';
import { trackEvent } from '../../utils/Mixpanel';

const PreOrderCard = (props) => {
    const { singleProduct } = props;
    const [state, setState] = useState({ loading: false, cartId: '', authURL: '', trsRef: '', counterLoader: false });
    const [timeoutId, setTimeoutId] = useState(null);
    const { loading, cart } = useSelector(state => state.customer);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCart());
        resetTimeout();
        return () => clearTimeout(timeoutId); // Cleanup on unmount
    },[]);

    useEffect(() => {
        resetTimeout();
    },[]);

    const resetTimeout = () => {
        if (timeoutId) clearTimeout(timeoutId);
        const id = setTimeout(() => {
            trackAbandonedCart();
        }, 20 * 60 * 1000); // 20 minutes
        setTimeoutId(id);
    };

    const trackAbandonedCart = () => {
        trackEvent('abandoned-cart', { cart: cart });
    };

    const removeFromCart = async (val) => {
        resetTimeout();
        const product_id = singleProduct?.id;
        const product_variation_id = val?.productVariationId;
        const cart_id = val?.cartId;
        const obj = { product_id, cart_id, product_variation_id };
        if (val?.quantity <= 1) {
            return null;
        } else {
            setState({ ...state, counterLoader: true });
            try {
                const res = await http.post(`/customer/cart/remove`, obj);
                showSuccess(res.message);
                setState({ ...state, counterLoader: false });
                if (res) {
                    dispatch(getCart());
                }
            } catch (error) {
                setState({ ...state, counterLoader: false });
            }
        }
    };

    const increaseFromCart = async (val) => {
        resetTimeout();
        setState({ ...state, counterLoader: true, cartId: val?.id });
        const product_id = singleProduct?.id;
        const product_variation_id = val?.productVariationId;
        const cart_id = val?.cartId;
        const quantity = 1;
        const obj = { product_id, cart_id, product_variation_id, quantity };
        try {
            const res = await http.post(`/customer/cart/increase`, obj);
            showSuccess(res.message);
            setState({ ...state, counterLoader: false });
            if (res) {
                dispatch(getCart());
            }
        } catch (error) {
            setState({ ...state, counterLoader: false });
        }
    };

    const deleteFromCart = async (val) => {
        resetTimeout();
        setState({ ...state, loading: true });
        const cart_item_id = val?.id;
        const cart_id = val?.cartId;
        const obj = { cart_item_id, cart_id };
        try {
            const res = await http.post(`/customer/cart/delete`, obj);
            showSuccess(res.message);
            if (res) {
                dispatch(getCart());
            }
        } catch (error) {
            // handle error
        }
    };

    const navigate = useNavigate();

    const now = moment();
    const start = moment().hour(18).minute(0); // set start time to 6pm
    const end = moment().hour(9).minute(0);

    const placeOrder = () => {
        resetTimeout();
        if (now.isBetween(start, end)) {
            return showWarning('We are currently closed');
        } else {
            navigate('/placeOrder');
        }
    };

    const clearCart = async () => {
        setState({ ...state, loading: true });
        try {
            const res = await http.delete(`/customer/cart`);
            showSuccess(res.message);
            setState({ ...state, loading: false });
            dispatch(getCart());
        } catch (error) {
            setState({ ...state, loading: false });
        }
    };

    const renderCartItems = () => {
        if (cart && cart?.data?.cart?.items?.length !== 0) {
            return cart?.data?.cart?.items?.map((item, i) => {
                return (
                    <div style={{ marginTop: '16px' }} key={i}>
                        <div className={'flexRow alignCenter justifyBetween cartCardItem'} 
                             style={{ borderBottom: i !== cart?.data?.cart?.items?.length - 1 ? '0.5px solid #e6e7e9' : '' }}>
                            <div>
                                <p className={'boldText f14'} style={{ color: '#696A6B' }}>{item?.productVariation?.name}</p>
                                <p className={'semiBoldText f14'} style={{ color: ' #919294;' }}>₦{formatAmount(item?.price)}</p>
                            </div>
                            <div>
                                <div className={'flexRow alignCenter'} style={{ justifyContent: 'flex-end', marginBottom: '10px' }}>
                                    <img src={DeleteOrder} alt={'delete Icon'} onClick={() => deleteFromCart(item)} />
                                </div>
                                <div className={'counterContainer'} style={{ backgroundColor: '#F9F9FB' }}>
                                    <span onClick={() => removeFromCart(item)} style={{ cursor: item?.quantity <= 1 ? 'not-allowed' : 'pointer' }}>
                                        <AiOutlineMinusCircle color={item?.quantity <= 1 ? '#999CA0' : '#696A6B'} />
                                    </span>
                                    {state.counterLoader ? <CounterLoader /> :
                                        <span className={'boldText'} style={{ color: '#696A6B' }}>{item?.quantity}</span>}
                                    <span onClick={() => increaseFromCart(item)} style={{ cursor: 'pointer' }}>
                                        <AiOutlinePlusCircle color={'#696A6B'} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });
        }
    };

    return (
        <div className={'cartCardContainer '}>
            <h4 className={'boldText f18'} style={{ color: '#38383A', marginBottom: '24px' }}>Your Order</h4>
            <div className={'cartOrderListContainer'}>
                <div>
                    <p className={'semiBoldText f14'} style={{ color: ' #565758' }}>Pack </p>
                </div>
                {renderCartItems()}
            </div>
            <div className={'cartCardOrderInfo'}>
                <div>
                    <div className={'placeOrderBtn'}>
                        <CustomButton title={'Place order'} onClick={placeOrder} loading={loading} />
                    </div>
                    <div className={'clearCartBtn'} onClick={clearCart}>
                        {state.loading ?
                            <span className={'loader'}></span> :
                            <p className={'semiBoldText'}>Clear cart</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreOrderCard;
