import React, { useEffect, useState } from 'react';
import CustomInput from '../../utils/CustomInput';
import CustomButton from '../../utils/CustomButton';
import http from '../../utils/Utils';
import BankDropdown from '../../utils/BanksDropDown';
import { showSuccess } from '../../utils/Toast';
import { useDispatch } from 'react-redux';
import { getRiderAgentPaymentInfo } from '../../redux/actions/RiderAgentActions';
import useKeyboardAwareModal from '../../utils/useKeyboardAwareHook';

const PaymentMethodModal = ({ isOpen, onClose }) => {
    useKeyboardAwareModal()
    const [state, setState] = useState({
        bank_name: '',
        bank_code: '',
        bank_account_name: '',
        bank_account_number: '',
        loading: false,
    });
    const dispatch = useDispatch()

    const onChangeBankCode = (value) => {
        setState((prevState) => ({ ...prevState, bank_code: value }));
        console.log(value, 'code');
    };

    const onChangeBankName = (value) => {
        setState((prevState) => ({ ...prevState, bank_name: value }));
        console.log(value, 'code');
    };


    const onChangeBankAccName = (value) => {
        setState((prevState) => ({ ...prevState, bank_account_name: value }));
    };

    const onChangeBankAccNumber = (e) => {
        setState((prevState) => ({ ...prevState, bank_account_number: e.target.value }));
    };

    useEffect(() => {
        const { bank_account_number, bank_code, bank_name } = state;

        // Debugging to check values
        // console.log('Effect Triggered:', { bank_account_number, bank_code, bank_name });

        if (bank_account_number && bank_code && bank_name) {
            // console.log('Fetching Account Name...');
            fetchAccountName();
        }
    }, [state.bank_account_number, state.bank_code, state.bank_name]);

    const fetchAccountName = async () => {
        setState((prevState) => ({ ...prevState, loading: true }));
        try {
            const res = await http.get(`/details/account_number/${state.bank_account_number}/bank_code/${state.bank_code}`, {}, 'v2');
            const accountName = res?.data?.accountName || '';
            // console.log(res)
            onChangeBankAccName(accountName);

        } catch (error) {
            console.error("Error fetching account name", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const disabled = () => {
        const { bank_account_name, bank_account_number, bank_code, bank_name } = state;
        return !bank_account_name || !bank_account_number || !bank_code || !bank_name;
    };

    const addPaymentMethod = async () => {
        const { bank_account_name, bank_account_number, bank_code, bank_name } = state;
        setState((prevState) => ({ ...prevState, loading: true }));
        try {
            const obj = { bank_account_name, bank_account_number, bank_code, bank_name };
            const res = await http.post("rider_agency/payment/info/add", obj, true, {}, "v2");
            showSuccess(res?.message)
            dispatch(getRiderAgentPaymentInfo())
            onClose()
            // console.log('Payment method added successfully:', res);

        } catch (error) {
            // console.error('Failed to add payment method:', error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    return (
        <div className={`riderAgentModal ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="riderAgentModalContent" onClick={(e) => e.stopPropagation()}>
                <div>
                    <BankDropdown onChangeBankName={onChangeBankName} onChangeBankCode={onChangeBankCode} />
                </div>
                <div>
                    <CustomInput label={'Bank code'} value={state.bank_code} type={'tel'} />
                </div>
                <div>
                    <CustomInput label={'Bank account number'} value={state.bank_account_number} onChange={onChangeBankAccNumber} type={'tel'} />
                </div>
                <div>
                    <CustomInput label={'Bank account name'} value={state.bank_account_name} />
                </div>
                <div>
                    <CustomButton title={'Add Payment Method'} loading={state.loading} onClick={addPaymentMethod} disabled={disabled()} />
                </div>
            </div>
        </div>
    );
};

export default PaymentMethodModal;
