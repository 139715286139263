/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import http from '../../utils/Utils';
import { useMediaQuery } from 'react-responsive';
import { UPDATE_ADDRESS } from '../../redux/Types';


const OrderNowSection = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userAddress, setAddress] = useState(null);
    const disable = () => {
        if (userAddress === "") {
            return true;
        }
        return false;
    };

    const getAddressDetails = async () => {
        setLoading(true);
        const address = userAddress?.label;
        const obj = { address };
        try {
            const res = await http.post(`get_address_details`, obj);
            if (res) {
                const address = res?.data?.location?.givenAddress;
                const formatted_address = res?.data?.location?.formattedAddress;
                const lat = res?.data?.location?.latitude;
                const lng = res?.data?.location?.longitude;
                const city = res?.data?.location?.city;
                const country = res?.data?.location?.country;
                const state = res?.data?.location?.state;
                const addressData = { address, formatted_address, lat, lng, city, country, state };
                dispatch({ type: UPDATE_ADDRESS, payload: addressData });
                setLoading(false);
                localStorage.setItem("location", address);
                navigate("/home", {
                    state: {
                        data: { lng, lat },
                    },
                });
            }
        } catch (error) {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if (userAddress) {
            getAddressDetails();
        }
    }, [getAddressDetails, userAddress]);
    
    const returnPlaceHolder = () => {
        return (
            <p className={"regularText f14 flexRow alignCenter"} style={{ gap: "8px", justifyContent: 'center', }}>
                {/* <CiMap size={22} /> */}
                Enter your city & view vendors
            </p>
        )
    }


    return (
        <section className={'orderNowSection'}>
            <header>
                <h3 className={`boldText textCenter ${isDesktopOrLaptop ? 'f38' : 'f20'}`} style={{ color: '#F4743B' }}>We’ve got you covered!</h3>
                <p className={`regularText textCenter ${isDesktopOrLaptop ? 'f20' : 'f14'}`} style={{ color: '#fff' }}>
                    Feeling famished? Too exhausted to cook? Whether you're hosting friends or just need a pick-me-up,
                    get Dado on your device or place an online order, and let us bring joy to your door within minutes.
                </p>
            </header>
            <div className={"flexRow alignCenter"} style={{
                flexWrap: isDesktopOrLaptop ? 'nowrap' : 'wrap',
                justifyContent: "center",
                width: isDesktopOrLaptop ? '536px' : '100%',
                margin: isDesktopOrLaptop ? ' 20px auto' : '20px 0', gap: '20px 10px'
            }}>
                <div style={{ width: isDesktopOrLaptop ? '385px' : '100%' }}>
                    <GooglePlacesAutocomplete
                        
                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        selectProps={{
                            userAddress,
                            onChange: setAddress,
                            styles: {
                                control: (provided) => ({
                                    ...provided,
                                    padding: isDesktopOrLaptop ? '8px 16px' : '5px 16px',
                                    background: ' #fff',
                                    borderRadius: '8px',
                                    border: ' 0.5px #919294 solid',
                                    width: '100%',
                                    textAlign: 'center'
                                    // zIndex: '30'
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: " #999CA0",
                                    fontFamily: "Nunito",
                                    fontSize: "16px",
                                    width: "90%"

                                }),
                            },
                            placeholder: returnPlaceHolder(),
                        }}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ["ng"],
                            },
                        }}
                    />
                </div>
                {/* <div style={{ width: isDesktopOrLaptop ? '143px' : '100%' }}>
                    <CustomButton title={"Open store"} onClick={getAddressDetails} loading={loading} disabled={disable()} />
                </div> */}
            </div>
        </section>
    )
}

export default OrderNowSection