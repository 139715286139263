import React, { useCallback, useEffect, useState } from "react";
import Footer from "../../widgets/Footer";
import Navbar from "../../widgets/Navbar";
import { Star } from "../../assets/images";
import { useLocation } from "react-router-dom";
import VendorAuth from "../auth/vendor/VendorAuth";
import Faq from "../../components/landingPage/Faq";
import { vendorsFaq } from "../../utils/Helpers";
import About from "../../components/landingPage/about/About";
import { useMediaQuery } from "react-responsive";
import AnimatePage from "../../utils/AnimatePage";
import Splash from "../../utils/Splash";
import { trackEvent } from "../../utils/Mixpanel";
import { showWarning } from "../../utils/Toast";



const points = [
    {
        title: 'Increased revenue',
        description: 'Increase in orders and sales over a short period',
    },
    {
        title: 'Insights & Analytics ',
        description: 'Optimize performance with insightful data from your sales.',
    },
    {
        title: 'Quality customer base ',
        description: 'Access to loyal customers and optimized order management',
    },
    {
        title: 'Operational flexibility',
        description: 'Manage your orders seamlessly-Offering pickup, delivery, or both.',
    },
]



const VendorLandingPage = (props) => {
    const params = useLocation();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const [state, setState] = useState({
        showAuth: false, splashScreen: true,
    });

    const hideScroll = () => {
        document.body.style.overflow = "hidden";
    };
    const unhideScroll = () => {
        document.body.style.overflow = "unset";
    };

    const openAuthModal = useCallback(() => {
        setState((prevState) => ({ ...prevState, showAuth: true }));
        hideScroll();
    }, []);

    const closeAuthModal = () => {
        setState((prevState) => ({ ...prevState, showAuth: false }));
        unhideScroll();
    };
    useEffect(() => {
        if (params?.state?.data) {
            openAuthModal();
        }

            // Check if the splash screen has been shown before
            const hasSeenSplash = localStorage.getItem('hasSeenSplash');

            if (hasSeenSplash) {
                setState((prevState) => ({ ...prevState, splashScreen: false }));
            } else {
                // Set the flag in localStorage to indicate that the splash screen has been shown
                localStorage.setItem('hasSeenSplash', 'true');
            }

        trackEvent('visits_vendor_landing_page')
    }, [params, openAuthModal]);


 

    const goToPlayStore = () => {
        window.open("https://play.google.com/store/apps/details?id=vendor.dado.ng", "_blank", "noreferrer");
        trackEvent('clicks_download_app', {
            appType:'vendor-app',
            version:'android',
            location: 'vendor_landing_page'
        })
    };
    const goToAppStore = () => {
        showWarning('Coming soon...')
        trackEvent('clicks_download_app', {
            appType:'vendor-app',
            version:'ios',
            location: 'vendor_landing_page'
        })
    };

    const closeSplashScreen = () => {
        setState((prevState) => ({ ...prevState, splashScreen: false }))
    }


    const renderPoints = () => {
        if (points.length !== 0) {
            return points?.map((item, index) => {
                return (
                    <div className={'campusPointCard'} key={index} style={{}}>
                        <div style={{ marginBottom: '16px' }}>
                            <img src={Star} alt='star' />
                        </div>
                        <div>
                            <h4 className={`boldText ${isDesktopOrLaptop ? 'f20' : 'f16'}`}>{item.title}</h4>
                            <p className={`regularText ${isDesktopOrLaptop ? 'f16' : 'f14'}`}>{item.description}</p>
                        </div>
                    </div>
                )
            })
        }
    }



    return (
        <>
         
                    <Navbar bgColor={"#ffffff"} others={true} mobileBtnColor={"#000000"} />
                    <main className={"vendorContainer"}>
                        <section className={"vendorHeroSection"}>
                            <header>
                                <div>
                                    <h2>Become a   {!isDesktopOrLaptop && <br />} <span style={{ color: '#F4743B' }}>Dado Partner,</span></h2>
                                    <h2> & Grow your revenue.</h2>
                                    <p className={"mediumText"}>Reach new Heights with us, Get more orders, boost sales, gain visibility, and enjoy flexibility.</p>
                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710266842426'} alt="hero_icon" className="vendorHeroIcon" />
                                </div>
                            </header>
                            <div className={"flexRow alignCenter"} style={{ gap: '24px' }}>
                                <div>
                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285376319'} alt="play_store" onClick={goToPlayStore}/>
                                </div>
                                <div>
                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285333940'} alt="app_store" onClick={goToAppStore}/>
                                </div>
                            </div>
                        </section>
                        <section className={"vendorVideoSection"}>
                            <video
                                className={`vendorVideoStyle`}
                                src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710266283835'}
                                loop
                                autoPlay
                                muted
                            />
                            <div className={"vendorVideoOverlay"}>
                                <div>
                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710266770227'} alt="vendor_flow" className={"vendorFlow"} />
                                </div>
                                <div className={"vendorVideoCardsContainer"}>
                                    <header>
                                        <h4>WHY BECOME A DADO PARTNER</h4>
                                        <p>Experience more success with our powerful tools and support:</p>
                                    </header>
                                    <div className="vendorVideoCardsContainerGrid">
                                        {renderPoints()}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className={"vendorSecondSection"}>
                            <div className={"vendorHeroGifContainer"}>
                                <span>
                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710268003990'} alt="rider-gif" />
                                </span>
                            </div>
                            <header>
                                <h4 style={{ color: 'rgba(244, 116, 59, 1)' }}>STEP UP YOUR BUSINESS </h4>
                                <h3 style={{ margin: '10px 0' }}>Ignite Your Growth & Amplify Your Success</h3>
                                <p >
                                    Dado is a local commerce platform that connects eager customers with great restaurants and relentless couriers.
                                    We grow your business by helping thousands of people find your restaurant and by making it super easy for your regulars to order more.
                                </p>
                            </header>
                            <div className="vendorSecondSectionContent">
                                <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710266615737'} alt="rider_flow" className={"riderFlow"} />
                                <div className={"vendorSecondSectionContentFooter"} style={{ background: '', }}>
                                    <header style={{ textAlign: "center" }}>
                                        <h4 style={{ color: 'rgba(230, 251, 250, 1)' }}>STARTING WITH US IS EASY</h4>
                                        <p style={{ color: 'rgba(206, 220, 223, 1)' }}>Follow these simple steps to get started</p>
                                    </header>
                                    <div>
                                        <div className={'flexRow alignCenter justifyCenter'} style={{ gap: '16px', marginBottom: '16px', flexWrap: isDesktopOrLaptop ? 'nowrap' : 'wrap' }}>
                                            <h2 className={'vendorText'} style={{ textAlign: isDesktopOrLaptop ? 'center' : 'left', width: isDesktopOrLaptop ? 'auto' : '100%' }}>Set up account</h2>
                                            <div style={{ position: 'relative' }} className="yellowCardOne">
                                                <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340914857'} alt='mass' style={{ objectFit: 'cover' }} />
                                                <div style={{ position: 'absolute', top: "0", right: '0', left: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',padding:'0 16px' }}>
                                                    <p className="vendorYellowBgText">Sign up with ease on Dado & Connect with eager customers.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'flexRow alignCenter justifyCenter'} style={{ gap: '16px', marginBottom: '16px', flexWrap: isDesktopOrLaptop ? 'nowrap' : 'wrap', }}>

                                            {isDesktopOrLaptop &&
                                                <div style={{ position: 'relative' }} >
                                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340914857'} alt='mass' style={{ objectFit: 'cover' }} />
                                                    <div style={{ position: 'absolute', top: "0", right: '0', left: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',padding:'0 16px' }}>
                                                        <p className="vendorYellowBgText">Quickly get verified, and delight customers with delicious food.</p>
                                                    </div>
                                                </div>}

                                            <h2 className={'vendorText mobileRight'} style={{ textAlign: isDesktopOrLaptop ? 'center' : 'right', width: isDesktopOrLaptop ? 'auto' : '100%' }}> Verify & Go live</h2>

                                            {!isDesktopOrLaptop &&
                                                <div style={{ position: 'relative' }} >
                                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340914857'} alt='mass' style={{ objectFit: 'cover' }} />
                                                    <div style={{ position: 'absolute', top: "0", right: '0', left: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                        <p className="vendorYellowBgText">Dado foods is a platform connecting customers, local food vendors, businesses, and couriers  </p>
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className={'flexRow alignCenter justifyCenter'} style={{ gap: '16px', flexWrap: isDesktopOrLaptop ? 'nowrap' : 'wrap', }}>
                                            <h2 className={'vendorText'} style={{ color: 'rgba(244, 116, 59, 1)', textAlign: isDesktopOrLaptop ? 'center' : 'left', width: isDesktopOrLaptop ? 'auto' : '100%' }}>Receive orders!</h2>
                                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710266686254'} alt='mass' />
                                        </div>
                                        <div className={"flexRow alignCenter justifyCenter"} style={{ gap: '24px', marginTop: '64px' }}>
                                            <div>
                                                <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340787099'} alt="app_store_icon" onClick={goToAppStore}/>
                                            </div>
                                            <div>
                                                <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340665770'} alt="play_store_icon" onClick={goToPlayStore}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className={"vendorAboutSection"}>
                            <About showCards={false} showIntro={false} showVideo={false} />
                        </section>
                        <section>
                            <Faq faqs={vendorsFaq} />
                        </section>
                        <Footer />
                        <section style={{ padding: '40px 100px', display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340702880'} alt="footer-logo" style={{ objectFit: 'contain' }} />
                        </section>
                    </main>
                    {state.showAuth && <VendorAuth onDecline={closeAuthModal} />}
              
           
        </>
    );
};

export default VendorLandingPage;
