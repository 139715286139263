import { BANKS_FAIL, BANKS_SUCCESSFUL, GETTING_BANKS, GETTING_RIDER_AGENT_ORDERS, GETTING_RIDER_AGENT_OTP, GETTING_RIDER_AGENT_OTP_FAIL, GETTING_RIDER_AGENT_OTP_SUCCESSFUL, GETTING_RIDER_AGENT_PAYMENT_INFO, GETTING_RIDER_AGENT_PROFILE, GETTING_RIDER_AGENT_RIDERS, GETTING_RIDER_AGENT_SINGLE_ORDER, GETTING_RIDER_AGENT_WALLET, LOADING_RIDER_AGENT_USER, RIDER_AGENT_LOGIN_FAIL, RIDER_AGENT_LOGIN_SUCCESS, RIDER_AGENT_ORDERS_FAIL, RIDER_AGENT_ORDERS_SUCCESSFUL, RIDER_AGENT_PAYMENT_INFO_FAIL, RIDER_AGENT_PAYMENT_INFO_SUCCESSFUL, RIDER_AGENT_PROFILE_FAIL, RIDER_AGENT_PROFILE_SUCCESSFUL, RIDER_AGENT_RIDERS_FAIL, RIDER_AGENT_RIDERS_SUCCESSFUL, RIDER_AGENT_SINGLE_ORDER_FAIL, RIDER_AGENT_SINGLE_ORDER_SUCCESSFUL, RIDER_AGENT_WALLET_FAIL, RIDER_AGENT_WALLET_SUCCESSFUL, SIGNOUT_RIDER_AGENT, SIGNOUT_RIDER_AGENT_FAIL, SIGNOUT_RIDER_AGENT_SUCCESSFUL } from '../Types';

const INITIAL_STATE = {
    gettingRiderAgentOrders: false,
    riderAgentOrders: [],
    riderAgentOrdersError: [],


    gettingRiderAgentSingleOrder: false,
    riderAgentSingleOrder: {},
    riderAgentSingleOrderError: [],

    gettingRiderAgentRiders: false,
    riderAgentRiders: [],
    riderAgentRidersError: [],

    loadingRiderAgent: false,
    error: [],
    riderAgentData: {},
    isAuthenticated: false,


    gettingRiderAgentProfile: false,
    riderAgentProfile: {},
    riderAgentProfileError: [],


    gettingRiderAgentPaymentInfo: false,
    riderAgentPaymentInfo: null,
    riderAgentPaymentInfoError: [],


    gettingRiderAgentWallet: false,
    riderAgentWallet: null,
    riderAgentWalletError: [],


    gettingBanks: false,
    banks: [],
    banksError: [],
};

const RiderAgentReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case GETTING_RIDER_AGENT_ORDERS:
            return { ...state, gettingRiderAgentOrders: true };
        case RIDER_AGENT_ORDERS_SUCCESSFUL:
            return { ...state, gettingRiderAgentOrders: false, riderAgentOrders: payload };
        case RIDER_AGENT_ORDERS_FAIL:
            return { ...state, gettingRiderAgentOrders: false, riderAgentOrdersError: payload };


            case GETTING_RIDER_AGENT_SINGLE_ORDER:
                return { ...state, gettingRiderAgentSingleOrder: true };
            case RIDER_AGENT_SINGLE_ORDER_SUCCESSFUL:
                return { ...state, gettingRiderAgentSingleOrder: false, riderAgentSingleOrder: payload };
            case RIDER_AGENT_SINGLE_ORDER_FAIL:
                return { ...state, gettingRiderAgentSingleOrder: false, riderAgentSingleOrderError: payload };    

        case GETTING_RIDER_AGENT_PROFILE:
            return { ...state, gettingRiderAgentProfile: true };
        case RIDER_AGENT_PROFILE_SUCCESSFUL:
            return { ...state, gettingRiderAgentProfile: false, riderAgentProfile: payload };
        case RIDER_AGENT_PROFILE_FAIL:
            return { ...state, gettingRiderAgentOrders: false, riderAgentProfileError: payload };

        case GETTING_RIDER_AGENT_PAYMENT_INFO:
            return { ...state, gettingRiderAgentPaymentInfo: true };
        case RIDER_AGENT_PAYMENT_INFO_SUCCESSFUL:
            return { ...state, gettingRiderAgentPaymentInfo: false, riderAgentPaymentInfo: payload };
        case RIDER_AGENT_PAYMENT_INFO_FAIL:
            return { ...state, gettingRiderAgentPaymentInfo: false, riderAgentPaymentInfoError: payload };

        case GETTING_RIDER_AGENT_WALLET:
            return { ...state, gettingRiderAgentWallet: true };
        case RIDER_AGENT_WALLET_SUCCESSFUL:
            return { ...state, gettingRiderAgentWallet: false, riderAgentWallet: payload };
        case RIDER_AGENT_WALLET_FAIL:
            return { ...state, gettingRiderAgentWallet: false, riderAgentWalletError: payload };

        case GETTING_BANKS:
            return { ...state, gettingBanks: true };
        case BANKS_SUCCESSFUL:
            return { ...state, gettingBanks: false, banks: payload };
        case BANKS_FAIL:
            return { ...state, gettingBanks: false, banksError: payload };

        case GETTING_RIDER_AGENT_RIDERS:
            return { ...state, gettingRiderAgentRiders: true };
        case RIDER_AGENT_RIDERS_SUCCESSFUL:
            return { ...state, gettingRiderAgentRiders: false, riderAgentRiders: payload };
        case RIDER_AGENT_RIDERS_FAIL:
            return { ...state, gettingRiderAgentRiders: false, riderAgentRidersError: payload };
        case GETTING_RIDER_AGENT_OTP:
            return { ...state, loadingRiderAgent: true };
        case GETTING_RIDER_AGENT_OTP_SUCCESSFUL:
            return { ...state, loadingRiderAgent: false }
        case GETTING_RIDER_AGENT_OTP_FAIL:
            return { ...state, loadingRiderAgent: false, error: payload }
        case LOADING_RIDER_AGENT_USER:
            return { ...state, loadingRiderAgent: true, error: [] };
        case RIDER_AGENT_LOGIN_SUCCESS:
            return { ...state, riderAgentData: payload, loadingRiderAgent: false, isAuthenticated: true };
        case RIDER_AGENT_LOGIN_FAIL:
            return { ...state, error: payload, riderAgentData: {}, loadingRiderAgent: false, isAuthenticated: false };
        case SIGNOUT_RIDER_AGENT:
            return { ...state, loading: true }
        case SIGNOUT_RIDER_AGENT_SUCCESSFUL:
            return { ...state, loadingRiderAgent: false, isAuthenticated: false, riderAgentData: {} }
        case SIGNOUT_RIDER_AGENT_FAIL:
            return { ...state, error: payload };


        default:
            return state;
    }
};

export default RiderAgentReducer;

