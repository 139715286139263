import React from 'react';
import { BlackStar, Cooking, LocationGray } from '../../assets/images';
import Skeleton from 'react-loading-skeleton';



const RestaurantInfoCard = (props) => {

    const { data, loading } = props

    const prepTime = data?.prepTime
    const shortenedPrepTime = prepTime?.replace('minutes', 'mins')

    const returnOpeningHours = () => {
        const dayOfWeekName = new Date().toLocaleString(
            'default', { weekday: 'long' }
        );

        if (data?.businessHours?.length !== 0) {
            return data?.businessHours?.map((item, index) => {
                if (item?.day === dayOfWeekName) {
                    return (
                        <p className={'semiBoldText'} style={{ margin: '0 8px 0 8px' }} key={index}>{item?.startTime}</p>
                    );
                } else {
                    return null;
                }
            });
        }

        return null;
    };


    const returnClosingHours = () => {
        const dayOfWeekName = new Date().toLocaleString(
            'default', { weekday: 'long' }
        );

        if (data?.businessHours?.length !== 0) {
            return data?.businessHours?.map((item, index) => {
                if (item?.day === dayOfWeekName) {
                    return (
                        <p className={'semiBoldText'} style={{ margin: '0 8px 0 8px' }} key={index}>{item?.endTime}</p>
                    )
                } else {
                    return null;
                }
            })
        }
        return null;
    }


    return (
        <>
            <div className={'restaurantInfoContainer'}>

                {loading ? (<Skeleton style={{ width: '100%', height: '100px', borderRadius: '16px' }} />) : (
                    <>
                        <div className={'infoTopContainer'}>
                            <div>
                                <div className={'flexRow alignCenter'} style={{ marginBottom: '5px' }}>
                                    <h3 className={'boldText'}>{data?.businessName}</h3>
                                    <span className={'flexRow alignCenter semiBoldText'}
                                        style={{ marginLeft: '17px' }}
                                    ><img src={BlackStar} alt={'starIcon'} style={{ marginRight: '5px' }} /> {data?.businessAverageRating} </span>
                                </div>
                                <div>
                                    <p className={'semiBoldText'}>{data?.businessHeadLine}</p>
                                </div>
                            </div>
                        </div>
                        <div className={'infoBottomContainer'}>
                            <div className='flexRow alignCenter'>
                                <p className={'semiBoldText'}>Opening hours:</p>
                                {returnOpeningHours()}
                                <span>-</span>{returnClosingHours()}
                            </div>
                            <div className={'flexRow alignCenter'}>
                                <div className={'flexRow alignCenter'} style={{ marginRight: '20px' }}>
                                    <img src={LocationGray} alt={'icon'} />
                                    <p className={'boldText'}>{data?.distanceFromRestaurant}</p>
                                </div>
                                <div className={'flexRow alignCenter'}>
                                    <img src={Cooking} alt={'icon'} />
                                    <p className={'boldText'}>{shortenedPrepTime}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default RestaurantInfoCard;