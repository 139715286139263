import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Star } from '../../assets/images'
import ImageWithPreloader from '../../utils/CustomImage'
import mixpanel from 'mixpanel-browser'
import { trackEvent } from '../../utils/Mixpanel'


const points = [
    {
        title: 'Flexible Schedules',
        description: 'Choose delivery times that fit around your class schedules, allowing you to earn money without compromising your studies.',
    },
    {
        title: 'Community Engagement',
        description: 'We connect students with local businesses and residents, creating a network of support and collaboration.',
    },
    {
        title: 'Incentives and Rewards',
        description: 'Enjoy exclusive incentives, bonuses, and rewards for your dedication and outstanding performance, making your delivery experience even more rewarding.',
    },
]

const CampusSection = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })




    const riderIosStore = () => {
        window.open('https://apps.apple.com/ng/app/dado-rider/id6448981515', '_blank', 'noreferrer');
        trackEvent('clicks_download_app', {
            appType:'rider-app',
            version:'ios',
            location: 'campus-section'
          })
    }
    const riderPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=rider.dado.ng', '_blank', 'noreferrer');
        trackEvent('clicks_download_app', {
            appType:'rider-app',
            version:'android',
            location: 'campus-section'
          })
    }

    const renderPoints = () => {
        if (points.length !== 0) {
            return points?.map((item, index) => {
                return (
                    <div className={'campusPointCard'} key={index} style={{}}>
                        <div className={'campusPointCardIcon'}>
                            <img src={Star} alt='star' style={{width:'100%', height:'100%', objectFit:'contain'}} />
                        </div>
                        <div>
                            <h4 className={`boldText ${isDesktopOrLaptop ? 'f20' : 'f16'}`}>{item.title}</h4>
                            <p className={`regularText ${isDesktopOrLaptop ? 'f16' : 'f14'}`}>{item.description}</p>
                        </div>
                    </div>
                )
            })
        }
    }



    return (
        <section className={'campusSection'}>
            <header>
                <h4 className={`mediumText ${isDesktopOrLaptop ? 'f20' : 'f16'}`} style={{ color: '#FFA024', marginBottom: '24px', fontWeight: '600' }}>CAMPUS PARTNERS</h4>
                <h2 className={` ${isDesktopOrLaptop ? 'f38' : 'f20'}`} style={{ fontWeight: '700' }}>Join the Dado Movement!</h2>
                <p className={`regularText ${isDesktopOrLaptop ? 'f20' : 'f16'}`} style={{ fontWeight: '300' }}>
                    Our Campus Partners Program offers students in tertiary institutions a unique opportunity to earn commissions by connecting fellow students with delicious meals from local vendors.
                </p>
            </header>

            <div className={'campusContent'}>
                <div className={'campusImageContainer'}>
                    <ImageWithPreloader src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285576198'} alt='campus' />
                    <div className={'campusContentOverlay'}>
                        <div className={'campusTab'}>
                            <p className={`boldText ${isDesktopOrLaptop ? 'f16' : 'f14'}`} style={{ color: "rgba(4, 217, 209, 1)" }}>Partner riders</p>
                        </div>
                        <div>
                            <p className={`regularText ${isDesktopOrLaptop ? 'f20' : 'f14'}`} style={{ color: "#fff", marginBottom: '24px' }}>
                                As a Dado Campus Partner, you not only enhance the food & culture experience on campus but also gain valuable entrepreneurial skills.
                            </p>
                            <div className={'flexRow alignCenter campusDownloadLinks animate__animated animate__slideInUp'} style={{ width: isDesktopOrLaptop ? '342px' : '100%', height: '64px', gap: '24px' }}>
                                <aside>
                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340617282'} alt='app_store' style={{ width: '100%', height: "100%", objectFit: "contain", cursor: "pointer" }} onClick={riderIosStore} />
                                </aside>
                                <aside>
                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340589868'} alt='play_store' style={{ width: '100%', height: "100%", objectFit: "contain", cursor: "pointer" }} onClick={riderPlayStore} />
                                </aside>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={'campusPointsGrid'}>
                    {renderPoints()}
                </div>
            </div>
        </section>
    )
}

export default CampusSection