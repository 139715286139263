import React, { useEffect } from 'react';
import Footer from '../../widgets/Footer';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';


const PrivacyPage = () => {
    const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' });
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <button className="callButton" style={{ width: isDesktop ? '70px' : '50px', height: isDesktop ? '70px' : '50px', position: 'absolute', left: isDesktop ? "80px" : "20px", top: isDesktop ? '40px' : "20px" }}>
                <NavLink to="/">
                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402377848'} alt="logo" style={{ objectFit: 'cover', width: '100%', height: "100%" }} />
                </NavLink>
            </button>
            <main className={'privacyTerms'}>
                <section className={'privacyHero'}>
                    <div>
                        <h2 className={'boldText textCenter'} style={{ color: '#fff' }}>Privacy Policy</h2>
                        <p className={'regularText textCenter'} style={{ color: '#fff' }}>This Privacy Policy describes the rules by which Dado foods processes Customers' personal information</p>
                    </div>
                </section>
                <section>


                    <div className='privacyHeader'>
                        <h2 className={'boldText'} style={{ color: '#fff' }}>Privacy Policy</h2>
                    </div>

                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'} >
                            1. Introduction
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            This policy explains how we, Dadochops Global Nigeria Limited. (hereinafter referred to as “Dado”), use the personal information which you provide to us when using our service, including but not limited to our website and mobile applications (jointly referred to as “Website”). Please read this Privacy Policy and understand it before using our services. By visiting and/or ordering services on this Website, you agree and, where required, consent to the collection, use and transfer of your information as set out in this policy. This Privacy Policy applies to our information collection, use, and disclosure practices for website visitors ages 18 and over.

                        </p>
                    </article>
                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'} >
                            2. What information do we collect about you?
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            We collect personal information from you when you order goods or services from us or use our Website. We also collect information when you complete any customer survey. Website usage information may also be collected using cookies (as defined below). Dado will collect information that personally identifies you and/or your location, where required (this may include your name, email address, home address, telephone number, geolocation, etc.), but only when you voluntarily give it to us. We collect this information exclusively to carry out the functions offered on the Website and to provide you with offers and information about Dado and other services we think you may be interested in. We might collect this personal information through online food ordering; entry into competitions; subscribing to our newsletter; creating a user account; sending ‘contact us’ messages or other correspondence through the Website; or through advertising, research and direct marketing. We do not collect sensitive information about you.
                        </p>
                    </article>
                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'} >
                            3. Cookies
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            Some of the information collected will not personally identify you but will instead track your use of the Website so that we can better understand how the Website is used by customers and in turn enhance and improve your experience in ordering food. We may obtain this information by use of cookies. Cookies are small data file transferred to your device that recognizes and identifies your device and allows your device to ‘remember’ information from the Website for future use. Cookies do not contain any information that personally identifies you and we do not use cookies in order to obtain such information – your personal information can only be obtained by dado if you actively provide it to us. We may collect technical information from your mobile device or your use of our services through a mobile device, for example, location data and certain characteristics of, and performance data about your device, carrier/operating system including device and connection type, IP address, mobile payment methods, interaction with other retail technology such as the use of NFC Tags, QR Codes or use of mobile vouchers. Your device and/or the web browser should allow you to refuse cookies if you wish by changing the settings. To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit www.allaboutcookies.org.

                        </p>
                    </article>
                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'} >
                            4. Storage and security of your personal information
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            Dado will use all reasonable endeavors to maintain the security of your personal information and to protect your personal information from misuse, interference and loss and against unauthorized access, modification or disclosure. Dado will destroy any personal information it holds about you that it no longer requires under the terms of this Privacy Policy. Where you have chosen a password to access certain services of the Website, you are responsible for keeping your password confidential. We recommend not sharing your password with anyone. Due to the nature of the internet, Dado does not provide any guarantee or warranty regarding the security of your personal information during transmission to us or storage by us and you acknowledge that you disclose your personal information to us at your own risk. Please contact us immediately if you become aware or have reason to believe there has been any unauthorized use of your personal information in connection with the Website.
                        </p>
                    </article>
                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'}>
                            5. How will we use the information we collect from you?
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            You consent to Dado collecting and using your personal information for processing and delivering the order/s placed on the Website and any other service provided through the Website. In particular you expressly consent that Dado may disclose your personal information, including your name, email address, physical address and telephone number to riders delivering your order, either employed by Dado or by third parties providing delivery services to Dado. You consent to Dado using your personal information for advertising and direct marketing purposes in order to inform you about the Website and the goods and services it provides, or other matters that it believes will be of interest to you. Dado may disclose your personal information, and you consent to the disclosure of your personal information, to:
                            other entities within the Dado group of companies; and
                            third parties engaged by Dado or other members of the Dado group to perform functions or provide products and services on our behalf such as processing payments, mailouts, debt collection, research, statistical information, marketing and direct or indirect advertising.
                            If you would like to unsubscribe from receiving direct marketing communications from Dado or do not want us to share your personal information with other parties, please email us at account@dado.ng or follow the steps to unsubscribe which are presented in every communication you receive from us, requesting your personal information be removed from our mailing list. We will use reasonable endeavors to comply with your request within a reasonable period of receipt of your request. Please note that the withdrawal of any authorisations for processing of your personal information by third parties may result in us not being able to provide you with any services. Dado reserves the right to disclose your personal information if it is required or authorized to do so by law, or if it is reasonably necessary in its opinion to protect the rights or property of Dado or any third party or to avoid injury to any person. If the Dado business is sold or merges with another entity then some or all of your personal information may be passed to a third party.

                        </p>
                    </article>
                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'}>
                            6. Access to your information
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            Dado will use all reasonable endeavors to keep personal information it holds accurate, complete, up-to-date, relevant and not misleading. Please contact us if you would like to access the personal information Dado holds about you. We will use reasonable endeavors to provide a complete list of your personal information within a reasonable period of receipt of your request. Please email us if you would like to receive a copy of this information –account@dado.ng. Dado reserves the right to charge a nominal fee for the processing of this request in accordance with local legislation. You may contact us to correct any of your personal information that is inaccurate, incomplete or out-of-date, or to request that your personal information be deleted. We will use reasonable endeavors to correct or delete your personal information as requested within a reasonable period of receipt of your request. Deletion of your account with Dado will not automatically delete the personal information held about you. If you would like Dado to delete all personal information together with the deletion of your account, please follow the steps mentioned above. Please note that the deletion of your personal information from our database will result in us not being able to provide you with any services. If applicable, any legal requirement on us to maintain certain records of your personal information shall prevail over any of your requests. We may require you to prove your identity before providing you with copies of your personal information.

                        </p>
                    </article>
                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'}>
                            7. Account Deletion
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            If you choose to delete your account, please note that the deletion process will take approximately 30 days to complete. During this period, your account will be deactivated, and we will begin the process of permanently removing your personal data from our systems.
                        </p>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            However, certain data related to your activity within our ecosystem, such as transaction history and business information, will be retained even after account deletion. This data is necessary for operational, legal, and record-keeping purposes and will be stored in accordance with applicable laws and our internal policies.
                        </p>

                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            Please be aware that once your account is deleted, this action cannot be reversed, and you will no longer have access to your account or any associated services.
                        </p>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            If you have any questions or need assistance with account deletion, please contact our support team.
                        </p>
                    </article>
                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'}>
                            8. Changes to our Privacy Policy
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            Dado reserves the right to alter all or any part of this Privacy Policy. Any changes thereto will be notified via the Website and, where appropriate, through e-mail notification.

                        </p>
                    </article>
                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'} >
                            9. Other Websites
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            Our Website may have links to other websites. This privacy policy only applies to the Website. You should therefore read the privacy policies of the other websites when you are using those sites.
                        </p>
                    </article>
                    <article className={'privacyArticle'}>
                        <h3 className={'boldText f18'} >
                            10. Contact
                        </h3>
                        <p className={'regularText f16'} style={{ lineHeight: '42px' }}>
                            All comments, queries and requests relating to our use of your information are welcomed and should be addressed to: account@dado.ng
                        </p>
                    </article>
                </section>
                <Footer />

            </main>
        </>
    )
}

export default PrivacyPage;