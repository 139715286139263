import React, { useEffect, useRef, useState } from "react";
import "../../../assets/styles/modalStyles.css";
import { AiOutlineClose } from "react-icons/ai";
import { DeliveryBottomImage } from "../../../assets/images";
import OtpInput from "../../../utils/OtpInput";
import CustomButton from "../../../utils/CustomButton";

const PhoneVerification = (props) => {
    const [pin, setPin] = useState("");
    const onChange = (value) => {
        setPin(value);
    };
    const { verifyCode, loading, token, onDecline, requestOTP, phone, country_code } = props;
    // console.log(token)

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    // The state for our timer
    const [timer, setTimer] = useState("00:00");

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);

        return {
            total,
            minutes,
            seconds,
        };
    };

    const startTimer = (e) => {
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            setTimer((minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds));
        }
    };

    const clearTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        setTimer("01:30");

        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 90);
        return deadline;
    };

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible

    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime());
    },[]);

    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        clearTimer(getDeadTime());
        requestOTP(country_code, phone);
        // console.log(phone, country_code)
    };

    return (
        <>
            <section className={"modalContainer"}>
                <div className={"modalContentContainer"}>
                    <div className={"modalCloseBtn"} onClick={onDecline}>
                        <AiOutlineClose size={20} />
                    </div>
                    <div>
                        <h4 className={"boldText textCenter"}>Verify mobile number</h4>
                        <p className={"regularText textCenter"}>Please input the code sent to your number</p>
                    </div>
                    <div>
                        <OtpInput value={pin} valueLength={6} onChange={onChange} />
                    </div>
                    <div>
                        <CustomButton title={"Verify"} onClick={() => verifyCode(pin, token)} loading={loading} />
                    </div>
                    <div>
                        <p className={"textCenter semiBoldText bottomLink"}>
                            Didn’t receive OTP?
                            {timer !== "00:00" ? (
                                <span className={timer !== "00:00" ? "timerActive " : "timerInactive"}> Resend OTP in {timer}</span>
                            ) : (
                                <span className={"timerInactive"} onClick={onClickReset}>
                                    {" "}
                                    Resend OTP{" "}
                                </span>
                            )}
                        </p>
                    </div>

                    <div className={"modalBottomImage"}>
                        <img src={DeliveryBottomImage} alt="borderImage" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default PhoneVerification;
