import React, { useEffect } from 'react'
import OrdersTable from '../../components/rider-agent/RiderAgentOrdersTable'
import { useDispatch } from 'react-redux'
import { getRiderAgentOrders } from '../../redux/actions/RiderAgentActions'

const RiderAgentOrders = () => {
    const dispatch = useDispatch()
    useEffect(()=> {
        dispatch(getRiderAgentOrders())
    },[])


  return (
    <main className={'riderAgentContainer'}>
    <header>
        <h2>Orders</h2>
        <p>Manage orders</p>
    </header>

    <section>
        <OrdersTable />
    </section>
   
</main>
  )
}

export default RiderAgentOrders