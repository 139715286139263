import { CLEAR_QUERY, GET_LOCATIONS, GET_LOCATIONS_FAIL, GET_LOCATIONS_SUCCESSFUL, SEARCH_QUERY, STORE_ADDRESS, UPDATE_ADDRESS, UPDATE_COORDINATES, UPDATE_F_ADDRESS, UPDATE_ROUTE, UPLOAD_ADDRESS, UPLOAD_ADDRESS_FAIL, UPLOAD_ADDRESS_SUCCESSFUL } from '../Types';

const INITIAL_STATE = {
    loading: false,
    error: [],
    currentLocation: '',
    coordinates: {},
    searchQuery: '',
    storedAddress: null,
    uploadAddress: null,
    uploadAddressError: [],
    savingAddress: false,
    addressDetail: {},
    formattedAddress: '',
    routeDetails: {},
};

const SearchReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case GET_LOCATIONS_SUCCESSFUL:
            return { ...state, currentLocation: payload };
        case UPDATE_COORDINATES:
            return { ...state, coordinates: payload };
        case UPDATE_ADDRESS:
            return { ...state, addressDetails: payload };
        case UPDATE_F_ADDRESS:
            return { ...state, formattedAddress: payload };
        case SEARCH_QUERY:
            return { ...state, searchQuery: payload };
        case UPDATE_ROUTE:
            return { ...state, routeDetails: payload };
        case CLEAR_QUERY:
            return { ...state, searchQuery: '' }
        case STORE_ADDRESS:
            return { ...state, storedAddress: payload };
        case UPLOAD_ADDRESS:
            return { ...state, savingAddress: true }
        case UPLOAD_ADDRESS_SUCCESSFUL:
            return { ...state, savingAddress: false, uploadAddress: payload };
        case UPLOAD_ADDRESS_FAIL:
            return { ...state, savingAddress: false, uploadAddressError: payload }
        default:
            return state;
    }
};

export default SearchReducer;