import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import HomeNavbar from '../../widgets/HomeNavBar';
import NewsLetterComponent from '../../widgets/NewsLetterComponent';
import Footer from '../../widgets/Footer';
import LargeImageCard from '../../components/customers/LargeImageCard';
import BackComponent from '../../utils/BackComponent';
import RestaurantInfoCard from '../../components/customers/RestaurantInfoCard';
import ProductsLargeCard from '../../components/customers/ProductsLargeCard';
import CartCard from '../../components/customers/CartCard';
import CartFloatingButton from '../../components/customers/CartFloatingButton';

import AddressModal from '../../components/home/AddressModal';
import CustomerAuth from '../auth/customer/CustomerAuth';
import ProductOrderModal from '../../components/customers/ProductOrderModal';
import CartModal from '../../components/customers/CartModal';
import AddAddressModal from '../../components/customers/AddAddressModal';
import SavedAddressModal from '../../components/customers/SavedAddressModal';
import WarningModal from '../../components/customers/WarningModal';

import { getCart, getSingleRestaurant } from '../../redux/actions/CustomerActions';
import http from '../../utils/Utils';
import { showError, showSuccess } from '../../utils/Toast';
import { trackEvent } from '../../utils/Mixpanel';
import SmartBanner from '../../utils/SmartBannerComponent';

const RestaurantsHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useLocation();
    const { id } = useParams()
    const isTabletOrMobile = useMediaQuery({ query: '(min-width: 1224px)' });

    const { products, loading, cart, gettingRestaurants, gettingProducts, restaurant, gettingRestaurant } = useSelector(state => state.customer);
    const { loggedIn } = useSelector(state => state.auth);

    const [state, setState] = useState({
        showAddressModal: false,
        signInModal: false,
        signUpModal: false,
        phoneVerificationModal: false,
        orderModal: false,
        emptyState: true,
        preOrderState: false,
        orderState: false,
        cartModal: false,
        addressModal: false,
        savedAddressModal: false,
        singleProduct: null,
        selectedProduct: null,
        loading: false,
        warningModal: false,
        variationsId: '',
        searchValue: '',
        searchedProducts: [],
    });

    const [foodOption, setFoodOption] = useState({});
    const [counter, setCounter] = useState(1);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        if (loggedIn) {
            dispatch(getCart());
        }
        dispatch(getSingleRestaurant(id))
    }, [dispatch]);

    const handleScroll = (hide) => {
        document.body.style.overflow = hide ? 'hidden' : 'unset';
    };

    const toggleModal = (modalName, value) => {
        setState(prevState => ({ ...prevState, [modalName]: value }));
        setFoodOption({});
        handleScroll(value);
    };

    const now = moment();
    const start = moment().hour(9).minute(0);
    const end = moment().hour(18).minute(0);

    const handleOrderModal = () => {
        if (!now.isBetween(start, end)) {
            showError(`🛒️ Stores Closed, you cannot add meals to your cart till when our stores open by 9am`);
        } else {
            toggleModal('orderModal', true);
        }
    };

    const handleCartUpdate = async (val, data) => {
        setState(prevState => ({ ...prevState, loading: true }));
        const business_id = id;
        const product_id = state.singleProduct?.id;
        const product_variation_id = state.variationsId;
        const quantity = counter;
        const obj = { business_id, product_id, quantity, product_variation_id };

        try {
            const res = await http.post(`customer/cart/add`, obj);
            showSuccess(res.message);
            if (res) {
                toggleModal('orderModal', false);
                dispatch(getCart());
                setFoodOption({});
                setCounter(1);
                trackEvent('add-to-cart', { business_id, product_id, item: state.singleProduct });
            }
        } catch (error) {
            setFoodOption({});
            setCounter(1);
        }
    };
    const handleOptionChange = (val, i) => {
        setFoodOption({
            [i]: true  // Set the selected item to true
        });
        setState(prevState => ({
            ...prevState,
            variationsId: val?.id  // Update the selected variation ID
        }));
    };
    // const handleOptionChange = (val, i) => {
    //     setFoodOption((prevOptions) => ({
    //         ...prevOptions,
    //         [i]: !prevOptions[i]  // Toggle the selected item
    //     }));

    //     setState(prevState => ({
    //         ...prevState,
    //         variationsId: prevState.variationsId === val?.id ? null : val?.id  // Toggle the selected variation ID
    //     }));
    // };


    const handleQuantityChange = (increment) => {
        setCounter(prevCounter => Math.max(0, prevCounter + increment));
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const searchedProducts = products?.data?.products.filter(product =>
            product?.name.toLowerCase().includes(searchTerm)
        );
        setState(prevState => ({
            ...prevState,
            searchValue: searchTerm,
            searchedProducts
        }));
    };

    const goToPlaceOrder = () => {
        navigate('/placeOrder', {
            state: {
                singleProduct: state.singleProduct,
            }
        });
    };

    const renderMobileCart = () => {
        if (cart?.data?.cart?.items?.length && cart?.data?.cart && loggedIn && !state.orderModal) {
            const totalPrice = cart?.data?.cart?.items.reduce((acc, item) => acc + Number(item?.price), 0);
            return <CartFloatingButton onClick={goToPlaceOrder} amount={cart?.data?.cart?.items?.length} price={totalPrice} />;
        }
        return null;
    };



    return (
        <>
            <SmartBanner />
            <HomeNavbar
                bgColor='#ffffff'
                onClick={() => toggleModal('showAddressModal', true)}
                openAuthModal={() => toggleModal('signInModal', true)}
                showSearch={false}
                placeHolder='Search for products'
                value={state.searchValue}
                onChange={handleSearch}
            />
            <main className='customerLandingPage' style={{ height: '100%', paddingBottom: isTabletOrMobile ? '200px' : '0', paddingTop: isTabletOrMobile ? '120px' : '0' }}>
                <section className='onGoBack'>
                    <BackComponent />
                </section>
                <section className='restaurantHomeContainer'>
                    <section>
                        <LargeImageCard img={restaurant?.businessImage} loading={gettingRestaurant} />
                        <RestaurantInfoCard data={restaurant} loading={gettingRestaurant} />
                        <ProductsLargeCard
                            openOrderModal={handleOrderModal}
                            loading={gettingProducts}
                            products={products}
                            getSingleProduct={item => setState(prevState => ({ ...prevState, singleProduct: item }))}
                            searchValue={state.searchValue}
                            searchedProducts={state.searchedProducts}
                            business_id={id}
                        />
                    </section>
                    <section id='cartHolder'>
                        <CartCard
                            emptyState={state?.emptyState}
                            preOrderState={state?.preOrderState}
                            orderState={state?.orderState}
                            showOrders={() => setState(prevState => ({ ...prevState, preOrderState: false, emptyState: false, orderState: true }))}
                            showEmptyState={() => setState(prevState => ({ ...prevState, preOrderState: false, emptyState: true, orderState: false }))}
                            openAddAddressModal={() => toggleModal('addressModal', true)}
                            openSavedAddressModal={() => toggleModal('savedAddressModal', true)}
                            selectedProduct={state?.selectedProduct}
                            singleProduct={state?.singleProduct}
                        />
                    </section>
                </section>

                <AddressModal visible={state?.showAddressModal} onDecline={() => toggleModal('showAddressModal', false)} />
                <CustomerAuth
                    signIn={state?.signInModal}
                    signUp={state?.signUpModal}
                    phoneVerification={state?.phoneVerificationModal}
                    closePhoneVerification={() => toggleModal('phoneVerificationModal', false)}
                    closeSignIn={() => toggleModal('signInModal', false)}
                    closeSignUp={() => toggleModal('signUpModal', false)}
                    openPhoneVerificationModal={() => toggleModal('phoneVerificationModal', true)}
                    openSignInModal={() => toggleModal('signInModal', true)}
                    openSignUpModal={() => toggleModal('signUpModal', true)}
                    addToCart={handleCartUpdate}
                />
                <ProductOrderModal
                    visible={state?.orderModal}
                    onDecline={() => toggleModal('orderModal', false)}
                    showPreOrder={() => setState(prevState => ({ ...prevState, preOrderState: true, emptyState: false, orderState: false }))}
                    openCartModal={() => toggleModal('cartModal', true)}
                    singleProduct={state?.singleProduct}
                    AddToCart={handleCartUpdate}
                    openAuthModal={() => toggleModal('signInModal', true)}
                    openWarningModal={() => toggleModal('warningModal', true)}
                    foodOption={foodOption}
                    getOption={handleOptionChange}
                    setFoodOption={setFoodOption}
                    counter={counter}
                    increase={() => handleQuantityChange(1)}
                    decrease={() => handleQuantityChange(-1)}
                    businessTagId={restaurant?.businessTagId}
                    business_id={id}
                    product_id={state?.singleProduct?.id}
                    product_variation_id={state?.variationsId}
                    quantity={counter}
                />
                <CartModal visible={state.cartModal} />
                {renderMobileCart()}
                <AddAddressModal visible={state.addressModal} onDecline={() => toggleModal('addressModal', false)} openAddAddressModal={() => toggleModal('addressModal', true)} />
                <SavedAddressModal visible={state.savedAddressModal} onDecline={() => toggleModal('savedAddressModal', false)} />
                <WarningModal visible={state.warningModal} onDecline={() => toggleModal('warningModal', false)} updateCart={handleCartUpdate} foodOption={foodOption} />
            </main>
            <Footer />
        </>
    );
};

export default RestaurantsHome;
