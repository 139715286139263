import { 
    LOGIN_SUCCESS, LOGIN_FAIL, LOADING_USER, SIGNOUT_USER_FAIL, GETTING_OTP, GETTING_OTP_SUCCESSFUL, GETTING_OTP_FAIL, SIGNOUT_USER, SIGNOUT_USER_SUCCESSFUL, LOADING_VENDORS, VENDORS_SUCCESSFUL, VENDORS_FAIL, GETTING_ADDRESSES, GETTING_ADDRESSES_SUCCESSFUL, GETTING_ADDRESSES_FAIL 
} from '../Types';

const INITIAL_STATE = {
    loading: false,
    error: [],
    userData: {},
    alertMessage: null,
    loggedIn: false,
    waitList: {},
    waitListError: [],
    addresses: [],
    addressesError: [],
};

const AuthReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case GETTING_OTP:
            return { ...state, loading: true };
        case GETTING_OTP_SUCCESSFUL:
            return { ...state, loading: false }
        case GETTING_OTP_FAIL:
            return { ...state, loading: false, error: payload }
        case LOADING_USER:
            return { ...state, loading: true, error: [] };
        case LOGIN_SUCCESS:
            return { ...state, userData: payload, loading: false, loggedIn: true };
        case LOGIN_FAIL:
            return { ...state, error: payload, userData: {}, loading: false };
        case LOADING_VENDORS:
            return { ...state, loading: true };
        case VENDORS_SUCCESSFUL:
            return { ...state, loading: false, waitList: payload }
        case VENDORS_FAIL:
            return { ...state, loading: false, waitListError: payload }
        case GETTING_ADDRESSES:
            return { ...state, loading: true };
        case GETTING_ADDRESSES_SUCCESSFUL:
            return { ...state, loading: false, addresses: payload };
        case GETTING_ADDRESSES_FAIL:
            return { ...state, loading: false, addressesError: payload }
        case SIGNOUT_USER:
            return { ...state, loading: true }
        case SIGNOUT_USER_SUCCESSFUL:
            return { ...state, loading: false, loggedIn: false, userData: {} }
        case SIGNOUT_USER_FAIL:
            return { ...state, error: payload };

        default:
            return state;
    }
};

export default AuthReducer;
