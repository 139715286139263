import React, { useEffect, useState } from 'react'
import AnimatePage from './AnimatePage'
import VideoWithPreloader from './CustomVideo'
import { useMediaQuery } from 'react-responsive'

const Splash = ({ closeSplashScreen }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [seconds, setSeconds] = useState(8);

    useEffect(() => {
        const countdown = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);

        // Clear the interval when component unmounts
        return () => clearInterval(countdown);
    }, []);

    useEffect(() => {
        if (seconds === 0) {
            // Navigate to a new page after countdown finishes
            closeSplashScreen()
        }
    }, [seconds, closeSplashScreen]);
    return (
        <>
            <AnimatePage>
                <main style={{ width: "100%", height: '100vh', backgroundColor: '#084F61', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710402428249'} alt={'alt'} style={{ height: '200px' }} className='animate__animated animate__pulse animate__infinite' />
                </main>
            </AnimatePage>
        </>
    )
}

export default Splash

// require('../assets/images/splash.mp4')
// 