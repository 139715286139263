import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { formatAmount, shortenXterLength } from '../../utils/Helpers'

const ProductsCard = (props) => {
    const { data } = props
    const productImage = data?.images[data?.images?.length - 1];
    const productRecentPrice = data?.variations[data?.variations?.length - 1]?.price;
    return (
        <>
            {props.loading ?
                <div className={'productsCardContainer'} onClick={props.onClick} >
                    <div className={'productsCardContent'}>
                        <h5 className={'boldText'}>
                            <Skeleton style={{ width: '120px', marginBottom: '10px' }} />
                        </h5>
                        <p className={'regularText'}>
                            <Skeleton style={{ width: '120px', marginBottom: '10px' }} />
                        </p>
                        <span className={'semiBoldText'}>
                            <Skeleton style={{ width: '120px' }} />
                        </span>
                    </div>
                    <div className={'productsCardImage'}>
                        {/* <img src={props.img} alt={'food'} /> */}
                        <Skeleton style={{ height: '96px', width: '100px' }} />
                    </div>
                </div> :
                <div className={'productsCardContainer'} onClick={props.onClick} >
                    <div className={'productsCardContent'}>
                        <h5 className={'boldText'}>{shortenXterLength(data?.name, 18)}</h5>
                        <p className={'regularText'}>{shortenXterLength(data?.description
                            , 20)}</p>
                        <span className={'semiBoldText'}>₦{formatAmount(productRecentPrice)}</span>
                    </div>
                    <div className={'productsCardImage'}>
                        <img src={productImage} alt={'food'} style={{ borderRadius: '8px' }} />
                    </div>
                </div>}
        </>

    )
}

export default ProductsCard