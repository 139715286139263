import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import RiderAgentNav from '../../components/rider-agent/RiderAgentNav'
import { useDispatch } from 'react-redux'
import { getRiderAgentOrders, getRiderAgentPaymentInfo, getRiderAgentProfile, getRiderAgentRiders, getRiderAgentWallet } from '../../redux/actions/RiderAgentActions'

const RiderAgent = () => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getRiderAgentOrders());
    dispatch(getRiderAgentRiders());
    dispatch(getRiderAgentProfile());
    dispatch(getRiderAgentPaymentInfo());
    dispatch(getRiderAgentWallet());
  }, [])

  return (
    <div className={'riderAgentResponsiveContainer'}>
      <RiderAgentNav />
      <Outlet />
    </div>
  )
}

export default RiderAgent