import { setToken } from "../../utils/Helpers";
import http from "../../utils/Utils"
import { GETTING_RIDER_AGENT_ORDERS, GETTING_RIDER_AGENT_OTP, GETTING_RIDER_AGENT_OTP_FAIL, GETTING_RIDER_AGENT_OTP_SUCCESSFUL, GETTING_RIDER_AGENT_PROFILE, GETTING_RIDER_AGENT_RIDERS, LOADING_RIDER_AGENT_USER, RIDER_AGENT_LOGIN_FAIL, RIDER_AGENT_LOGIN_SUCCESS, RIDER_AGENT_ORDERS_FAIL, RIDER_AGENT_ORDERS_SUCCESSFUL, RIDER_AGENT_RIDERS_FAIL, RIDER_AGENT_RIDERS_SUCCESSFUL, RIDER_AGENT_PROFILE_SUCCESSFUL, RIDER_AGENT_PROFILE_FAIL, GETTING_RIDER_AGENT_WALLET, RIDER_AGENT_WALLET_SUCCESSFUL, RIDER_AGENT_WALLET_FAIL, GETTING_RIDER_AGENT_PAYMENT_INFO, RIDER_AGENT_PAYMENT_INFO_SUCCESSFUL, RIDER_AGENT_PAYMENT_INFO_FAIL, GETTING_BANKS, BANKS_SUCCESSFUL, BANKS_FAIL, GETTING_RIDER_AGENT_SINGLE_ORDER, RIDER_AGENT_SINGLE_ORDER_SUCCESSFUL, RIDER_AGENT_SINGLE_ORDER_FAIL } from "../Types"



// export const getRiderAgentOrders = (page, limit, lng, lat) => {
//     return dispatch => {
//         dispatch({ type: GETTING_RIDER_AGENT_ORDERS });
//         return new Promise(async (resolve, reject) => {
//             try {
//                 const res = await http.get(`rider_agency/orders?page=${page}&limit=${limit}`, { params: {lat, lng} },  "v2");
//                 const data = res;
//                 dispatch({ type: RIDER_AGENT_ORDERS_SUCCESSFUL, payload: data });
//                 resolve(data);
//             } catch (error) {
//                 dispatch({ type: RIDER_AGENT_ORDERS_FAIL, payload: error });
//                 reject(error);
//             }
//         });
//     };
// };

export const getRiderAgentOrders = (page, limit, lng, lat, orderStatus) => {
    return dispatch => {
        dispatch({ type: GETTING_RIDER_AGENT_ORDERS });
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`rider_agency/orders?order_status=${orderStatus}&page=${page}&limit=${limit}`, {
                    params: { lat, lng },
                }, "v2");
                const data = res;
                dispatch({ type: RIDER_AGENT_ORDERS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: RIDER_AGENT_ORDERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



export const getRiderAgentSingleOrder = (id, lat, lng) => {
    return dispatch => {
        dispatch({ type: GETTING_RIDER_AGENT_SINGLE_ORDER });
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`rider_agency/orders/${id}`, { params: {lat,lng}}, "v2");
                const data = res;
                dispatch({ type: RIDER_AGENT_SINGLE_ORDER_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: RIDER_AGENT_SINGLE_ORDER_FAIL, payload: error });
                reject(error);
            }
        });
    };
};




export const getRiderAgentRiders = (page, limit, lat, lng) => {
    return dispatch => {
        dispatch({ type: GETTING_RIDER_AGENT_RIDERS });
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`rider_agency/riders`, { params: {lat, lng} },  "v2");
                const data = res?.data;
                // console.log(data,'issue1')
                // console.log(res,'issue2')
                dispatch({ type: RIDER_AGENT_RIDERS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: RIDER_AGENT_RIDERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


export const getRiderAgentProfile = (page, limit, lat, lng) => {
    return dispatch => {
        dispatch({ type: GETTING_RIDER_AGENT_PROFILE });
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`rider_agency/profile`, { params: {lat, lng} },  "v2");
                const data = res?.data;
                dispatch({ type: RIDER_AGENT_PROFILE_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: RIDER_AGENT_PROFILE_FAIL, payload: error });
                reject(error);
            }
        });
    };
};




export const getRiderAgentPaymentInfo = (page, limit, lat, lng) => {
    return dispatch => {
        dispatch({ type: GETTING_RIDER_AGENT_PAYMENT_INFO });
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`rider_agency/payment/info`, { params: {lat, lng} },  "v2");
                const data = res?.data;
                // console.log(data, 'payment info')
                dispatch({ type: RIDER_AGENT_PAYMENT_INFO_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: RIDER_AGENT_PAYMENT_INFO_FAIL, payload: error });
                reject(error);
            }
        });
    };
};




export const getRiderAgentWallet = (page, limit, lat, lng) => {
    return dispatch => {
        dispatch({ type: GETTING_RIDER_AGENT_WALLET });
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`rider_agency/wallet?page=${page}&limit=${limit}`, { params: {lat, lng} },  "v2");
                const data = res?.data;
                // console.log(data, 'wallet')
                dispatch({ type: RIDER_AGENT_WALLET_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: RIDER_AGENT_WALLET_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



export const getBanks = () => {
    return dispatch => {
        dispatch({ type: GETTING_BANKS });
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`list_of_nigeria_banks`, { params: {} },  "v2");
                const data = res?.data;
                console.log(data, 'BANKS')
                dispatch({ type: BANKS_SUCCESSFUL, payload: data });
                resolve(data);
            } catch (error) {
                dispatch({ type: BANKS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



// /rider_agency/riders


export const getRiderAgentPhoneOtp = (obj) => {
    return dispatch => {
        dispatch({ type: GETTING_RIDER_AGENT_OTP });
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("rider_agency/auth/sign-in/send-phone-otp",  obj , true, {}, "v2");
                const data = res;
                dispatch({ type: GETTING_RIDER_AGENT_OTP_SUCCESSFUL });
                resolve(data);
            } catch (error) {
                dispatch({ type: GETTING_RIDER_AGENT_OTP_FAIL, payload: error });
                reject(error);
            }
        });
    };
};

export const getRiderAgentEmailOtp = (obj) => {
    return dispatch => {
        dispatch({ type: GETTING_RIDER_AGENT_OTP });
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("rider_agency/auth/sign-in/send-email-otp",  obj, true, {}, "v2");
               
                const data = res;
                dispatch({ type: GETTING_RIDER_AGENT_OTP_SUCCESSFUL });
                resolve(data);
            } catch (error) {
                dispatch({ type: GETTING_RIDER_AGENT_OTP_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


// auth / customer / profile

export const verifyRiderAgentOtp = (obj) => {
    return dispatch => {
        dispatch({ type: LOADING_RIDER_AGENT_USER })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.post("rider_agency/auth/sign-in/verify-otp",obj, true, {}, "v2")
                const data = res.data
                setToken(data, 'v2')
                // console.log('verify OTP', res)
                dispatch({ type: RIDER_AGENT_LOGIN_SUCCESS, payload: data });
                resolve(data);
            } catch (error) {
                // console.log(error)
                dispatch({ type: RIDER_AGENT_LOGIN_FAIL, payload: error });
                reject(error);
            }
        });
    };

};


// export const signOutRiderAgent = () => {
//     return dispatch => {
       
       
//         return new Promise(async (resolve, reject) => {
//             try {
//             } catch (error) {
//                 // console.log(error)
//                 dispatch({ type: SIGNOUT_RIDER_AGENT_FAIL, payload: error });
//                 reject(error);
//             }
//         });
//     };

// };
