import React, { useCallback, useEffect, useState } from "react";
import Footer from "../../widgets/Footer";
import Navbar from "../../widgets/Navbar";
import { useLocation } from "react-router-dom";
import CouriersAuth from "../auth/couriers/CouriersAuth";
import Faq from "../../components/landingPage/Faq";
import { ridersFaq } from "../../utils/Helpers";
import About from "../../components/landingPage/about/About";
import { useMediaQuery } from "react-responsive";
import Splash from "../../utils/Splash";
import AnimatePage from "../../utils/AnimatePage";
import { trackEvent } from "../../utils/Mixpanel";

const RidersLandingPage = () => {
    const params = useLocation();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const [state, setState] = useState({
        showAuth: false, splashScreen: true,
    });

    const hideScroll = () => {
        document.body.style.overflow = "hidden";
    };
    const unhideScroll = () => {
        document.body.style.overflow = "unset";
    };

    const openAuthModal = useCallback(() => {
        setState((prevState) => ({ ...prevState, showAuth: true }));
        hideScroll();
    }, []);

    const closeAuthModal = () => {
        setState((prevState) => ({ ...prevState, showAuth: false }));
        unhideScroll();
    };
    useEffect(() => {
        if (params?.state?.data) {
            openAuthModal();
        }
        trackEvent('visits_riders_landing_page')
    }, [params, openAuthModal]);

    const goToPlayStore = () => {
        window.open("https://play.google.com/store/apps/details?id=rider.dado.ng", "_blank", "noreferrer");
        trackEvent('clicks_download_app', {
            appType:'rider-app',
            version:'android',
            location: 'rider_landing_page'
        })
    };
    const goToAppStore = () => {
        window.open("https://apps.apple.com/ng/app/dado-rider/id6448981515", "_blank", "noreferrer");
        trackEvent('clicks_download_app', {
            appType:'rider-app',
            version:'ios',
            location: 'rider_landing_page'
        })
    };
    const closeSplashScreen = () => {
        setState((prevState) => ({ ...prevState, splashScreen: false }))
    }


    return (
        <>

                    <Navbar bgColor={"#ffffff"} others={true} />
                    <main>
                        <section className={"couriersHeroSection"}>
                            <div className={"couriersHeroContent"}>
                                <div>
                                    <div>
                                        <h1 className={"boldText textCenter"}>
                                            Elevate your game &
                                        </h1>
                                        <h1 className={"boldText textCenter"} style={{ color: 'rgba(243, 223, 77, 1)' }}>
                                            Become a Hero
                                        </h1>
                                    </div>

                                    <p className={"mediumText textCenter"}>
                                        Make money delivering meals around you
                                    </p>
                                </div>
                                <div className={"flexRow alignCenter justifyCenter"} style={{ gap: '24px' }}>
                                    <div>
                                        <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340589868'} alt="playStore" onClick={goToPlayStore} />
                                    </div>
                                    <div>
                                        <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340617282'} alt="appStore" onClick={goToAppStore} />
                                    </div>
                                </div>
                                <div className={"courierHeroBtmImageContainer"}>
                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710341046973'} alt="heroFlow" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </section>
                        <section className={"couriersBackgroundLayer"}>
                            <section className={"couriersSecondSection"}>
                                <div className={"courierHeroGifContainer"}>
                                    <span>
                                        <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710284942883'} alt="rider-gif" />
                                    </span>
                                </div>
                                <header>
                                    <h4 style={{ color: 'rgba(244, 116, 59, 1)' }}>BEGIN YOUR JOURNEY</h4>
                                    <h3>Seize the opportunity & Reach new Heights</h3>
                                    <p >
                                        Dado foods is a platform connecting customers, local food vendors, businesses, and couriers looking for an opportunity to earn money in a flexible way. As a Dado courier, you earn money by delivering orders from local businesses to customers - at your convenience
                                        Own your hours, be your own boss, ride your vehicle, take loans, call shots, grow and learn as you go.
                                    </p>
                                </header>
                                <div className="courierSecondSectionContent">
                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285133399'} alt="rider_flow" className={"riderFlow"} />
                                    <div className={"courierSecondSectionContentFooter"}>
                                        <header style={{ textAlign: "center" }}>
                                            <h4 style={{ color: 'rgba(2, 87, 84, 1)' }}>SEIZE THE OPPORTUNITY & JOIN US!</h4>
                                            <p style={{ color: 'rgba(5, 47, 58, 1)' }}>Choose Dado courier and Reach New Heights</p>
                                        </header>
                                        <div>
                                            <div className={'flexRow alignCenter justifyCenter'} style={{ gap: '16px', marginBottom: '16px', flexWrap: isDesktopOrLaptop ? 'nowrap' : 'wrap' }}>
                                                <h2 className={'riderText'} style={{ textAlign: isDesktopOrLaptop ? 'center' : 'left', width: isDesktopOrLaptop ? 'auto' : '100%' }}>Sign up</h2>
                                                <div style={{ position: 'relative' }} className="yellowCardOne">
                                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340914857'} alt='mass' style={{ objectFit: 'cover' }} />
                                                    <div style={{ position: 'absolute', top: "0", right: '0', left: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',padding:'0 16px' }}>
                                                        <p className="yellowBgText">Be your boss and earn when you sign up as a Dado rider.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flexRow alignCenter justifyCenter'} style={{ gap: '16px', marginBottom: '16px', flexWrap: isDesktopOrLaptop ? 'nowrap' : 'wrap', }}>

                                                {isDesktopOrLaptop &&
                                                    <div style={{ position: 'relative' }} >
                                                        <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340914857'} alt='mass' style={{ objectFit: 'cover' }} />
                                                        <div style={{ position: 'absolute', top: "0", right: '0', left: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' , padding:'0 16px'}}>
                                                            <p className="yellowBgText">Enjoy a smooth rider verification process and start delivering to customers.</p>
                                                        </div>
                                                    </div>}

                                                <h2 className={'riderText mobileRight'} style={{ textAlign: isDesktopOrLaptop ? 'center' : 'right', width: isDesktopOrLaptop ? 'auto' : '100%' }}> Get verified</h2>

                                                {!isDesktopOrLaptop &&
                                                    <div style={{ position: 'relative' }} >
                                                        <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340914857'} alt='mass' style={{ objectFit: 'cover' }} />
                                                        <div style={{ position: 'absolute', top: "0", right: '0', left: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                            <p className="yellowBgText">Dado foods is a platform connecting customers, local food vendors, businesses, and couriers.</p>
                                                        </div>
                                                    </div>}
                                            </div>
                                            <div className={'flexRow alignCenter justifyCenter'} style={{ gap: '16px', flexWrap: isDesktopOrLaptop ? 'nowrap' : 'wrap', }}>
                                                <h2 className={'riderText'} style={{ color: 'rgba(244, 116, 59, 1)', textAlign: isDesktopOrLaptop ? 'center' : 'left', width: isDesktopOrLaptop ? 'auto' : '100%' }}>Start earning</h2>
                                                <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710286020085'} alt='mass' />
                                            </div>
                                            <div className={"flexRow alignCenter justifyCenter"} style={{ gap: '24px', marginTop: '64px' }}>
                                                <div>
                                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285910315'} alt="app_store_icon" onClick={goToAppStore} />
                                                </div>
                                                <div>
                                                    <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285952016'} alt="play_store_icon" onClick={goToPlayStore} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>

                        <section className={"riderAboutSection"}>
                            <About showCards={false} showIntro={false} showVideo={false} />
                        </section>
                        <section>
                            <Faq faqs={ridersFaq} />
                        </section>
                        <Footer />

                        <section style={{ padding: '40px 100px', display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                            <img src={'https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340702880'} alt="footer-logo" style={{ objectFit: 'contain' }} />
                        </section>

                    </main>
                    {state.showAuth && <CouriersAuth onDecline={closeAuthModal} />}
                
        </>
    );
};

export default RidersLandingPage;
