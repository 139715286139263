import React, { useEffect } from 'react'
import HomeNavbar from '../../widgets/HomeNavBar'
import WalletCard from '../../components/customers/WalletCard'
import { useDispatch, useSelector } from 'react-redux'
import { getWallet } from '../../redux/actions/CustomerActions'
import WalletTable from '../../components/customers/WalletTable'
import { useLocation, useNavigate } from 'react-router-dom'

const CustomerWallet = () => {
    const dispatch = useDispatch()
    const { wallet } = useSelector(state => state.customer)
    const { userData } = useSelector(state => state.auth)
    const navigate = useNavigate()

    useEffect(() => {
        if (userData) {
            fetchWallet();
        }
    }, []);

    const fetchWallet = async () => {
        try {
            await dispatch(getWallet());
        } catch (error) {
            console.error('Error fetching wallet:', JSON.stringify(error));
        }
    };

    return (
        <>
            <main>
                <HomeNavbar />
                <section className='walletResponsive'>
                    <section className={'walletGrid'}>
                        <WalletCard title={'Current Balance'} amount={wallet?.wallet?.walletBalance || 0} bg={'#000'} iconBg={'rgba(255, 144, 0, 1)'} titleColor={'rgba(250, 250, 250, 1)'} amountColor={'rgba(255, 255, 255, 1)'} />
                        <WalletCard title={'Refunds'} amount={wallet?.wallet?.totalCredit || 0} bg={'#fff'} iconBg={'#000'} titleColor={'rgba(122, 122, 157, 1)'} amountColor={'rgba(56, 56, 58, 1)'} />
                        <WalletCard title={'Expenses'} amount={wallet?.wallet?.totalDebit || 0} bg={'#fff'} iconBg={'#000'} titleColor={'rgba(122, 122, 157, 1)'} amountColor={'rgba(56, 56, 58, 1)'} />
                    </section>
                    <section className={'walletTableSection'}>
                        <WalletTable />
                    </section>
                </section>
            </main>
        </>
    )
}

export default CustomerWallet